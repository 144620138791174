import React from 'react'
import { Link } from 'react-router-dom'
import NavbarUser from '../../components/NavbarUser'

const PaymentDetail = () => {
    return (
        <>
            <NavbarUser />
            <div className='w-full bg-slate-600 pb-[120px] pt-[75px]'>
                <div className='rounded pt-10'>
                    <p className='mt-2 font-bold text-2xl pb-5 text-white text-center'>Rincian Belanja</p>
                </div>
                <div className='relative h-[505px] lg:h-[675px] overflow-x-auto mx-2 md:mx-6 lg:mx-auto bg-white text-center max-w-[1240px] items-center shadow-lg rounded'>
                    <div className='bg-white text-slate-900 pt-4 pl-1 lg:p-4 pb-10 lg:m-25 rounded-xl shadow-2xl'>
                        <div>
                            <div>
                                <div className='flex justify-between p-5 border-b-4 mx-2 border-black '>
                                    <div>
                                        <p className='text-base md:text-xl font-semibold ml-4'>Total Pembelian</p>
                                    </div>
                                    <div>
                                        <p className='text-2xl md:text-5xl font-semibold mr-4 text-red-500'>Rp. 102.500</p>
                                    </div>
                                </div>

                                <div className='flex justify-between mx-5 mt-1 mb-5'>
                                    <div>
                                        <p className='font-semibold text-base text-gray-700'>Order ID</p>
                                    </div>
                                    <div>
                                        <p className='font-semibold text-base text-gray-400'>INV123456</p>
                                    </div>
                                </div>

                                <div>
                                    <p className='text-center font-bold text-xl'>Pesanan</p>
                                </div>

                                <div className='flex justify-between mx-12 mt-2 mb-5'>
                                    <p className='text-base font-semibold'>Produk</p>
                                    <p className='text-base font-semibold'>Jumlah</p>
                                </div>

                                <div>
                                    <div className='flex justify-between mx-12 mt-2 mb-5'>
                                        <p className='text-base'>Paket Bimbel Gold</p>
                                        <p className='text-base'>200.000</p>
                                    </div>
                                    <div className='flex justify-between mx-12 mt-2 mb-5'>
                                        <p className='text-base'>Voucher Diskon</p>
                                        <p className='text-base'>-100.000</p>
                                    </div>
                                    <div className='flex justify-between mx-12 mt-2 mb-5'>
                                        <p className='text-base'>Biaya Payment</p>
                                        <p className='text-base'>2.500</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-gray-200 max-w-[1240px] rounded mx-auto px-6 py-3 inset-x-0 bottom-0 block fixed '>
                <div className='flex justify-between'>
                    <div>
                        <p className='text-sm font-semibold'>Total Pembelian</p>
                        <p className='text-red-500 font-bold'>Rp. 102.500</p>
                    </div>
                    <div>
                        <Link to="/paymentInvoice">
                            <button className="py-2 px-8 rounded-xl hover:bg-blue-500 hover:text-white">Lanjut</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentDetail