import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import axios from "axios"
import { handleResponse200 } from '../utils/HttpUtils'
import { notifyError } from "../utils/Notification"
import { RiLoader5Fill } from 'react-icons/ri'
import { login, checkIsAuthenticated } from '../repository/User'
import { useDispatch, useSelector } from 'react-redux'
import { setMember } from '../app/GlobalSlice'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { isDesktop, isPhone } from '../utils/PlatformHelper'

const Login = () => {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { member, screenSize } = useSelector(state => state.global)

    const [form, setForm] = useState({
        email: '',
        password: '',
        passwordShown: ''
    })

    const [errors, setErrors] = useState([])

    const [isAuthenticating, setIsAuthenticating] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)

        if (member.id) {
            navigate('/member')
        }

    }, [member])

    function onLogin(
        email,
        password
    ) {
        
        const payload = {
            username: email,
            password: password
        }

        setIsAuthenticating(true)

        login(payload)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        const member = {
                            id: payload.idOrangtua,
                            nama: payload.nama,
                            email: payload.email,
                            hp: payload.hp
                        }
            
                        dispatch(setMember(member))
                        navigate('/member')
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        notifyError('Forbidden. Please Login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .then(() => {
                checkIsAuthenticated({
                    onStartChecking: () => {},
                    onFinishChecking: () => {},
                    onAuthenticated: payload => {
                      const member = {
                        id: payload.idOrangtua,
                        nama: payload.nama,
                        email: payload.email,
                        hp: payload.hp,
                        provinsi: payload.provinsi,
                        kabupaten: payload.kabupaten,
                        kecamatan: payload.kecamatan,
                        fullAddress: payload.fullAddress
                      }
            
                      dispatch(setMember(member))
                    },
                    onUnAuthenticated: () => {}
                })
            })
            .catch(error => {

            })
            .finally(() => { setIsAuthenticating(false) })

    }

    return (
        <>
        <div
            className={
                'w-full bg-second-color'+
                (isPhone(screenSize.width) ? " pb-10 " : "  h-screen flex justify-center ")
            }
        >
            <Navbar />
            <div
                className={
                    ''+
                    (isPhone(screenSize.width) ? " px-5 pt-32 " : " h-full flex items-center ")
                }
                style={{
                    width: isDesktop(screenSize.width) ? '450px' : '100%'
                }}
            >
                <div
                    className='w-full space-y-2'
                >
                    {errors.length > 0 && (
                        <div
                            className='bg-red-100 px-3 rounded-md'
                        >
                            <ul
                                style={{ fontSize: '13px' }}
                            >
                            { errors.map(err => (
                                <li className='p-0'>err</li>
                            )) }
                            </ul>
                        </div>
                    )}
                    <div
                        className='w-full bg-white rounded-lg space-y-8 px-8 py-10'
                    >
                        <div
                            className='text-center font-bold'
                            style={{ 
                                fontFamily: "Nunito",
                                fontSize: "20px"
                            }}
                        >
                            Masuk Akun Bimbel ALC
                        </div>
                        <div className='space-y-4'>
                            <div className='space-y-2'>
                                <div style={{ fontSize: '13px' }}>Email</div>
                                <div>
                                    <input 
                                        className={
                                            'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold ' +
                                            (form.email !== null && form.email !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                        }
                                        style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                        placeholder='Masukan Email di sini'
                                        value={form.email}
                                        onChange={e => setForm({ ...form, email: e.target.value })}
                                        disabled={isAuthenticating}
                                    />
                                </div>
                            </div>
                            <div className='space-y-2'>
                                <div style={{ fontSize: '13px' }}>Password</div>
                                <div
                                    className={
                                        'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold flex ' +
                                        (form.password !== null && form.password !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                    }
                                >
                                    <input 
                                        type={form.passwordShown ? 'text' : 'password'}
                                        className={
                                            'w-full bg-blue-100 rounded-md outline-none font-bold ' +
                                            (form.password !== null && form.password !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                        }
                                        style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                        placeholder='Masukan Password di sini'
                                        value={form.password}
                                        onChange={e => setForm({ ...form, password: e.target.value })}
                                        disabled={isAuthenticating}
                                    />
                                    <button
                                        onClick={() => setForm({ ...form, passwordShown: !form.passwordShown })}
                                    >
                                        { form.passwordShown ?
                                            <AiFillEye className='h-6 w-6 text-blue-700'/>
                                            :
                                            <AiFillEyeInvisible className='h-6 w-6 text-blue-700'/>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='space-y-3'>
                            <div>
                                <button
                                    className='py-2 w-full rounded-full bg-blue-500 text-white font-bold hover:bg-blue-700 hover:shadow-lg flex justify-center'
                                    style={{ fontFamily: 'Nunito' }}
                                    onClick={() => {
                                        onLogin(form.email, form.password)
                                    }}
                                    disabled={isAuthenticating}
                                >
                                    {
                                        isAuthenticating ?
                                            <RiLoader5Fill className={"h-6 w-6 animate-spin"} />
                                            :
                                            'Masuk sekarang'
                                    }
                                </button>
                            </div>
                            <div
                                className='flex justify-center'
                                style={{ fontSize: '13px' }}
                            >
                                <h3>Belum punya akun? Ayo <Link to="/register"><span className='text-blue-700 font-bold underline'>Buat Akun Disini</span></Link></h3>
                            </div>
                            <div
                                className='flex justify-center pt-3 py-2 border-t'
                                style={{ fontSize: '13px' }}
                            >
                                <h3>Lupa password? Klik <Link to="/forget-password"><span className='text-blue-700 font-bold underline'>Reset Password</span></Link></h3>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <Footer />
        </>
    )
}

export default Login