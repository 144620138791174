import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { isDesktop, isPhone } from "../utils/PlatformHelper"

const LeftSectionBanner = () => {
    const { screenSize } = useSelector(state => state.global)

    return (
        <div
            className={
                "h-full flex items-center px-10 space-x-16"
            }
        >
            <div
                className="space-y-4"
            >
                <div>
                    <div
                        className="font-bold text-white"
                        style={{
                            fontSize: "28px",
                            fontFamily: "Nunito",
                            lineHeight: "28px"
                        }}
                    >
                        Bimbingan dan Konsultasi Belajar
                    </div>
                    <div
                        className="font-bold text-yellow-500"
                        style={{
                            fontSize: "28px",
                            fontFamily: "Nunito"
                        }}
                    >
                        As-Syifa Learning Center
                    </div>
                </div>
                <div
                    className="text-white pb-24"
                    style={{
                        fontSize: "18px",
                        fontFamily: "Nunito"
                    }}
                >
                    Sahabat Para Juara
                </div>
                <div
                    className={
                        (isDesktop(screenSize.width) ? "  " : " ") +
                        (isPhone(screenSize.width) ? " flex justify-center " : "")
                    }
                >
                    <Link to={'/paket-bimbel'}>
                        <button
                            className="px-14 py-2 font-bold rounded-full"
                            style={{
                                fontSize: '20px',
                                fontFamily: 'Signika',
                                backgroundColor: '#BBF424',
                                color: '#0079C4'
                            }}
                        >
                            Lihat Paket Belajar
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export const HomeTopBanner = () => {
    const { screenSize } = useSelector(state => state.global)

    return (
        <div
            className="w-full"
        >
            <div
                className="bg-second-color w-full min-h-[500px] flex justify-center"
            >
                <div
                    className="w-[1240px]"
                >
                    <div
                        className={
                            "w-full pt-[50px]" +
                            (isDesktop(screenSize.width) ? " flex " : "")
                        }
                    >
                        <div className="py-[100px]">
                            <LeftSectionBanner />
                        </div>
                        <div 
                            className={
                                (isDesktop(screenSize.width) ? "" : "") +
                                (isPhone(screenSize.width) ? " flex justify-center " : "")
                            }
                        >
                            <img src="/images/model-1.png" width={ isPhone(screenSize.width) ? '60%' : '100%'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}