import React from 'react'

import { 
    BsFacebook, 
    BsFillTelephoneFill, 
    BsInstagram, 
    BsMailbox2, 
    BsTwitter, 
    BsWhatsapp 
} from "react-icons/bs"
import { AiFillCopyrightCircle } from "react-icons/ai"

import {
    FaFacebook,
    FaGithub,
    FaInstagram,
    FaTwitter,
    FaTwitch
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isDesktop, isPhone } from '../utils/PlatformHelper'

const Footer = () => {
    const { systemProfile, screenSize} = useSelector(state => state.global)

    return (
        <>
        <div className='w-full bg-second-color flex justify-center pb-16'>
            <div 
                className={
                    'w-[1200px] mx-auto grid  text-white border-t py-16' +
                    (isDesktop(screenSize.width) ? " grid-cols-4 gap-x-5 " : "") +
                    (isPhone(screenSize.width) ? " grid-cols-1 gap-y-16 px-10" : "")
                }
            >
                <div className='space-y-5'>
                    <div>
                        <img src='/images/mini-logo-white.png' width={'200px'}/>
                    </div>
                    <div style={{ fontSize: '14px' }}>
                        <p>
                            Jl. Subang- Bandung KM. 12
                        </p>
                        <p>
                            Tambakmekar Jalancagak Subang
                        </p>
                        <p>
                            Jawa Barat Kode Pos 41281
                        </p>
                    </div>
                </div>
                <div className='space-y-5'>
                    <div
                        className='font-bold'
                        style={{
                            fontFamily: 'Nunito',
                            fontSize: '18px'
                        }}
                    >
                        Assyifa Learning Center
                    </div>
                    <div 
                        className='space-y-2'
                        style={{
                            fontSize: '14px'
                        }}
                    >
                        <div>
                            <Link to={"/about-us"}>Tentang Kami</Link>
                        </div>
                        <div>
                            <Link to={"/contact-us"}>Kontak Kami</Link>
                        </div>
                    </div>
                </div>
                <div className='space-y-5'>
                    <div
                        className='font-bold'
                        style={{
                            fontFamily: 'Nunito',
                            fontSize: '18px'
                        }}
                    >
                        Ikuti Kami
                    </div>
                    <div
                        className='flex flex-col space-y-3'
                        style={{
                            fontSize: '14px'
                        }}
                    >
                        <a href={systemProfile.socialMedia.facebook} target="_blank">
                            <div className='flex items-center space-x-3'>
                                <BsFacebook className='text-white h-5 w-5'/>
                                <label className='cursor-pointer'>Facebook</label>
                            </div>
                        </a>
                        <a href={systemProfile.socialMedia.twitter} target="_blank">
                            <div className='flex items-center space-x-3'>
                                <BsTwitter className='text-white h-5 w-5'/>
                                <label className='cursor-pointer'>Twitter</label>
                            </div>
                        </a>
                        <a href={systemProfile.socialMedia.instagram} target="_blank">
                            <div className='flex items-center space-x-3'>
                                <BsInstagram className='text-white h-5 w-5'/>
                                <label className='cursor-pointer'>Instagram</label>
                            </div>
                        </a>
                    </div>
                </div>
                <div className='space-y-5'>
                    <div
                        className='font-bold'
                        style={{
                            fontFamily: 'Nunito',
                            fontSize: '18px'
                        }}
                    >
                        Kontak
                    </div>
                    <div 
                        className='flex flex-col space-y-3'
                        style={{
                            fontSize: '14px'
                        }}
                    >
                        <div className='flex items-center space-x-3'>
                            <BsWhatsapp className='text-white h-5 w-5'/>
                            <label>{systemProfile.contact.whatsapp}</label>
                        </div>
                        <div className='flex items-center space-x-3'>
                            <BsFillTelephoneFill className='text-white h-5 w-5'/>
                            <label>{systemProfile.contact.phone}</label>
                        </div>
                        <div className='flex items-center space-x-3'>
                            <BsMailbox2 className='text-white h-5 w-5'/>
                            <label>{systemProfile.contact.email}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='w-full py-5 bg-blue-900 flex items-center justify-center text-white space-x-3' style={{ fontSize: '14px' }}>
            <AiFillCopyrightCircle className='h-5 w-5'/>
            <label>2022 Assyifa Learning Center. All right reserved.</label>
        </div>
        </>
    )
}

export default Footer