import { useState } from "react"
import { useEffect } from "react"
import { BsChevronLeft } from "react-icons/bs"
import { RiLoader5Fill } from "react-icons/ri"
import { useNavigate, useParams } from "react-router"
import { getAnak, getAnakEvaluasi, getPresensiDates, getPresensiDetail, getPresensiSummary } from "../../repository/User"
import { handleResponse200 } from "../../utils/HttpUtils"
import { notifyError } from "../../utils/Notification"
import { format } from 'date-fns'

import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import './Calendar.css'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display: false
        },
        title: {
            display: true,
            text: 'Persentase Presensi Siswa',
        },
    },
    scales: {
        y: {
        // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
            suggestedMin: 0,

        // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
            suggestedMax: 100,
            ticks: {
                stepSize : 10
            }
        }
    }
};

const UserAnakPresensiDetail = () => {

    const navigate = useNavigate()

    const { idanak } = useParams()

    const [isLoadDate, setIsLoadDate] = useState(false)
    const [isLoadDetail, setIsLoadDetail] = useState()
    const [isLoadSummary, setIsLoadSummary] = useState(false)

    const [presensiDates, setPresensiDates] = useState([])
    const [details, setDetails] = useState([])
    const [presensiSummary, setPresensiSummary] = useState(null)

    const labelsStat = ['Hadir', 'Sakit', 'Izin', 'Terlambat', 'Alfa'];
    const [presumStat, setPresumStat] = useState({
        labels: labelsStat,
        datasets: null
    })

    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

    const [anak, setAnak] = useState({ idAnak: 'MBR2110270001', nama: 'Anak Test' })

    useEffect(() => {
        setIsLoadSummary(true)
        setIsLoadDate(true)

        getPresensiSummary(idanak)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setPresensiSummary(payload)
                        setPresumStat({
                            ...presumStat,
                            datasets: [
                                {
                                    label: '',
                                    data: [ payload.hadir, 
                                            payload.sakit, 
                                            payload.izin, 
                                            payload.terlambat, 
                                            payload.tanpaKeterangan
                                        ],
                                    backgroundColor: 'blue'
                                }
                            ]
                        })
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(() => {})
            .finally(() => {
                setIsLoadSummary(false)
            })

        getPresensiDates(idanak)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setPresensiDates(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(() => {})
            .finally(() => {
                setIsLoadDate(false)
            })

        getAnak()
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        // setAnak(payload.find(p => p.idAnak === idanak))
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(() => {})
            .finally(() => {
            })

    }, [])

    useEffect(() => {
        if (!selectedDate) return;
        
        setIsLoadDetail(true)

        getPresensiDetail(idanak, format(selectedDate, "yyyy-MM-dd"))
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setDetails(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(() => {})
            .finally(() => {
                setIsLoadDetail(false)
            })

    }, [selectedDate])

    const LoadBox = () => {
        return (
            <>
                <div
                    className="fixed z-90 top-0 left-0 w-screen h-screen bg-black opacity-50"
                    style={{
                        zIndex: 90
                    }}
                >
                </div>
                <div className="absolute top-0 left-0 h-full w-full">
                    <div className="flex justify-center items-center w-full h-full">
                        <div 
                            className="bg-white p-10 z-90 rounded-lg space-y-5"
                            style={{
                                zIndex: 90
                            }}
                        >
                            <div className="flex justify-center py-5">
                                <RiLoader5Fill className={"h-10 w-10 text-blue-500 animate-spin"} />
                            </div>
                            <div
                                className="font-bold"
                                style={{
                                    fontFamily: 'Nunito'
                                }}
                            >
                                Sedang memproses. Mohon tunggu.
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div
            className='w-full h-screen bg-second-color flex justify-center'
        >
            <div
                className='w-[450px] h-full'
            >
                {isLoadDate && <LoadBox />}
                <div
                    className='w-full h-full bg-white rounded-lg overflow-y-auto'
                >
                    <div 
                        className="flex justify-between px-6 font-bold bg-blue-800 text-white rounded-tl-lg rounded-tr-lg rounded-bl-2xl rounded-br-2xl pt-12 pb-10"
                        style={{  
                            fontFamily: 'Nunito',
                            height: "200px"
                        }}
                    >
                        <div className="">
                            <button
                                onClick={() => navigate('/member/anak-presensi')}
                            >
                                <BsChevronLeft className="h-6 w-6"/>
                            </button>
                        </div>
                        <div
                            style={{
                                fontSize: '20px',
                            }}
                        >
                            Detail Presensi Anak
                        </div>
                        <div/>
                    </div>
                    <div 
                        className="p-5 space-y-3"
                    >
                        {presumStat.datasets && (
                            <>
                            <div>
                                <Bar options={options} data={presumStat} />
                            </div>
                            {/* <div className="py-2 px-5">
                                <div className="p-3 border rounded-md" style={{ fontSize: '14px' }}>
                                    <div className="flex space-x-2">
                                        <div className="w-16">
                                            Hadir
                                        </div>
                                        <div>
                                            :
                                        </div>
                                        <div>
                                            {presensiSummary.hadir} %
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div className="w-16">
                                            Sakit
                                        </div>
                                        <div>
                                            :
                                        </div>
                                        <div>
                                        {presensiSummary.sakit} %
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div className="w-16">
                                            Izin
                                        </div>
                                        <div>
                                            :
                                        </div>
                                        <div>
                                            {presensiSummary.izin} %
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div className="w-16">
                                            Terlambat
                                        </div>
                                        <div>
                                            :
                                        </div>
                                        <div>
                                        {presensiSummary.terlambat} %
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div className="w-16">
                                            Alfa
                                        </div>
                                        <div>
                                            :
                                        </div>
                                        <div>
                                        {presensiSummary.tanpaKeterangan} %
                                        </div>
                                    </div>

                                </div>
                            </div> */}
                            </>
                        )}
                        <div className="text-center">
                            Pilih salah satu tanggal
                        </div>
                        <div className="flex justify-center">
                            <Calendar
                                className="shadow-lg border-gray-200 rounded-sm p-2"
                                onChange={setSelectedDate}
                                // value={presensiDates}
                                onClickYear={d => setSelectedYear(d.getFullYear())}
                                locale={"id-ID"}
                                tileClassName={({ date, view }) => {
                                return presensiDates.find(d => d == format(date, "yyyy-MM-dd")) ?
                                    ' marked '
                                    :
                                    ' '
                                }}
                            />
                        </div>
                        { anak &&  (
                            <div className="p-3">
                                <div className="p-2 border border-gray-300 rounded-md" style={{ fontSize: '14px' }}>
                                    <div className="flex space-x-2">
                                        <div className="w-20">
                                            ID
                                        </div>
                                        <div>:</div>
                                        <div>
                                            {anak.idAnak}
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div className="w-20">
                                            Nama
                                        </div>
                                        <div>:</div>
                                        <div>
                                            {anak.nama}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="px-3">
                        {
                            isLoadDetail ?
                                (
                                <div className="flex justify-center">
                                    <RiLoader5Fill className={"h-6 w-6 text-blue-500 animate-spin"} />
                                </div>
                                )
                                :
                                details.length == 0 ?
                                    (<div className="text-center">Tidak ada data</div>)
                                    :
                                    (<div className="space-y-3">
                                        <div className="text-center">
                                            Detail Presensi Siswa
                                        </div>
                                        <div>
                                            {details.map(d => (
                                                <div className="p-2 border border-gray-300 rounded-md" style={{ fontSize: '14px' }}>
                                                    <div className="flex space-x-2">
                                                        <div className="w-20">
                                                            Tanggal
                                                        </div>
                                                        <div>:</div>
                                                        <div>
                                                            {format(selectedDate, "dd/MM/yyyy")}
                                                        </div>
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <div className="w-20">
                                                            Sesi
                                                        </div>
                                                        <div>:</div>
                                                        <div>
                                                            {d.sesi}
                                                        </div>
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <div className="w-20">
                                                            Jam Mulai
                                                        </div>
                                                        <div>:</div>
                                                        <div>
                                                            {d.jamMulai}
                                                        </div>
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <div className="w-20">
                                                            Jam Selesai
                                                        </div>
                                                        <div>:</div>
                                                        <div>
                                                            {d.jamSelesai}
                                                        </div>
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <div className="w-20">
                                                            Mapel
                                                        </div>
                                                        <div>:</div>
                                                        <div>
                                                            {d.mapel}
                                                        </div>
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <div className="w-20">
                                                            status
                                                        </div>
                                                        <div>:</div>
                                                        <div>
                                                            { d.status === 'tanpaketerangan' ? 'Alfa' : d.status}
                                                        </div>
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <div className="w-20">
                                                            Keterangan
                                                        </div>
                                                        <div>:</div>
                                                        <div>
                                                            { d.keterangan }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>)
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default UserAnakPresensiDetail;