export const handleResponse200 = ({
  httpResponse,
  onSuccess,
  onRecovFailure,
  onUnAuth,
  onTechnicalError
}) => {

  if (httpResponse.success) {
    onSuccess(httpResponse.payload)
  }
  else {
    if (httpResponse.statusCode === 200) {
      onRecovFailure(httpResponse.errors)
    }
    else if (httpResponse.statusCode === 401) {
      onUnAuth(['Unauthorized'])
    }
    else {
      onTechnicalError(['Unexpected Error. Please contact developer.'])
    }
  }

}