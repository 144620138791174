import React from 'react'
import AdsBanner from '../components/AdsBanner'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Services from '../components/Services'
import PromotionSlide from '../components/Slides/PromotionSlide'



const ProgramBelajar = () => {

    return (
        <>
            <Navbar />
            <AdsBanner />
            <div className='bg-slate-200 pt-12'>
                <PromotionSlide />
            </div>
            <Services />
            <Footer />
        </>
    )
}

export default ProgramBelajar