import { useEffect, useState } from "react"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { BsChevronLeft } from "react-icons/bs"
import { RiLoader5Fill } from "react-icons/ri"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { changePassword } from "../../repository/User"
import { handleResponse200 } from "../../utils/HttpUtils"
import { notifyError, notifySuccess } from "../../utils/Notification"

const UserPassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [form, setForm] = useState({
        oldPassword: '',
        newPassword: '',
        oldPasswordShown: false,
        newPasswordShowm: false
    })

    const [isSaving, setIsSaving] = useState(false)

    function onSave(
        oldPassword,
        newPassword
    ) {
        if (oldPassword === '' || newPassword === '') {
            return notifyError('Form isian belum lengkap')
        }

        setIsSaving(true)

        const payload = {
            oldPassword: oldPassword,
            newPassword: newPassword
        }

        changePassword(payload)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        notifySuccess('Berhasil update password')
                        setForm({
                            ...form,
                            oldPassword: '',
                            newPassword: ''
                        })
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {})
            .finally(() => { setIsSaving(false) })

    }

    return (
        <div
            className='w-full h-screen bg-second-color flex justify-center'
        >
            <div
                className='w-[450px] h-full'
            >
                <div
                    className='w-full h-full bg-white rounded-lg'
                >
                    <div 
                        className="flex justify-between px-6 font-bold bg-blue-800 text-white rounded-tl-lg rounded-tr-lg rounded-bl-2xl rounded-br-2xl pt-12 pb-10"
                        style={{  
                            fontFamily: 'Nunito',
                            height: "200px"
                        }}
                    >
                        <div className="">
                            <button
                                onClick={() => navigate('/member')}
                                disabled={isSaving}
                            >
                                <BsChevronLeft className="h-6 w-6"/>
                            </button>
                        </div>
                        <div
                            style={{
                                fontSize: '20px',
                            }}
                        >
                            Atur Ulang Password
                        </div>
                        <div/>
                    </div>
                    <div 
                        className="px-6 "
                    >
                        <div
                            className="p-5 bg-white rounded-lg shadow-lg z-10"
                            style={{
                                marginTop: '-50px'
                            }}
                        >

                            <div
                                className="space-y-5"
                            >
                                <div
                                    className="space-y-5"
                                >

                                    <div className='space-y-2'>
                                        <div style={{ fontSize: '13px' }}>Password Lama</div>
                                        <div
                                            className={
                                                'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold flex ' +
                                                (form.oldPassword !== null && form.oldPassword !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                            }
                                        >
                                            <input 
                                                type={form.oldPasswordShown ? 'text' : 'password'}
                                                className={
                                                    'w-full bg-blue-100 rounded-md outline-none font-bold ' +
                                                    (form.oldPassword !== null && form.oldPassword !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                                }
                                                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                                placeholder='Masukan Password lama di sini'
                                                value={form.oldPassword}
                                                onChange={e => setForm({ ...form, oldPassword: e.target.value })}
                                                disabled={isSaving}
                                            />
                                            <button
                                                onClick={() => setForm({ ...form, oldPasswordShown: !form.oldPasswordShown })}
                                            >
                                                { form.oldPasswordShown ?
                                                    <AiFillEye className='h-6 w-6 text-blue-700'/>
                                                    :
                                                    <AiFillEyeInvisible className='h-6 w-6 text-blue-700'/>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    <div className='space-y-2'>
                                        <div style={{ fontSize: '13px' }}>Password Baru</div>
                                        <div
                                            className={
                                                'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold flex ' +
                                                (form.newPassword !== null && form.newPassword !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                            }
                                        >
                                            <input 
                                                type={form.newPasswordShowm ? 'text' : 'password'}
                                                className={
                                                    'w-full bg-blue-100 rounded-md outline-none font-bold ' +
                                                    (form.newPassword !== null && form.newPassword !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                                }
                                                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                                placeholder='Masukan Password Baru di sini'
                                                value={form.newPassword}
                                                onChange={e => setForm({ ...form, newPassword: e.target.value })}
                                                disabled={isSaving}
                                            />
                                            <button
                                                onClick={() => setForm({ ...form, newPasswordShowm: !form.newPasswordShowm })}
                                            >
                                                { form.newPasswordShowm ?
                                                    <AiFillEye className='h-6 w-6 text-blue-700'/>
                                                    :
                                                    <AiFillEyeInvisible className='h-6 w-6 text-blue-700'/>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className="flex justify-center"
                                    >
                                        <button
                                            className="bg-blue-500 text-white px-8 py-1 rounded-md"
                                            style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                                            disabled={isSaving}
                                            onClick={() => {
                                                onSave(form.oldPassword, form.newPassword)
                                            }}
                                        >
                                            {
                                                isSaving ?
                                                    <RiLoader5Fill className={"h-6 w-6 text-white animate-spin"} />
                                                    :
                                                    'Simpan'
                                            }
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UserPassword