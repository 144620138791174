import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { isPhone } from "../utils/PlatformHelper"

const ProgramBelajarBox = () => {

    const { screenSize } = useSelector(state => state.global)

    const { programs } = useSelector(state => state.global)


    const Box = ({
        program
    }) => {
        return (
            <div
                className="p-10 space-y-8 relative"
            >
                <div className="flex justify-center">
                    <img src={program.iconBase64} width="150px"/>
                </div>
                <div className="space-y-3 pb-10">
                    <div 
                        className="text-center font-bold"
                        style={{
                            fontFamily: 'Nunito',
                            fontSize: '20px'
                        }}
                    >
                        {program.namaProgram}
                    </div>
                    <div>
                        <p className="text-center text-gray-600" dangerouslySetInnerHTML={{ __html: program.description }} />
                    </div>
                </div>
                <Link 
                    to={'/paket-bimbel/'+program.idProgram}
                >
                    <buttom 
                        className="absolute left-1/2 bottom-2 cursor-pointer bg-red-600 text-white py-2 font-bold flex justify-center rounded-full shadow-sm hover:shadow-lg"
                        style={{
                            fontFamily: 'Nunito',
                            width: '150px',
                            marginLeft: '-75px'
                        }}
                    >
                        Lihat paket
                    </buttom>
                </Link>
            </div>
        )
    }

    return (
        <div 
            className={
                "flex justify-center bg-second-color "+
                (isPhone(screenSize.width) ? " px-5 " : "")
            }
        >
            <div 
                className={
                    "rounded-xl bg-white p-10 shadow-lg space-y-10 "
                }
                style={{
                    width: isPhone(screenSize.width) ? "100%" : "1240px"
                }}
            >
                
                <div
                    className="text-center font-bold text-blue-700"
                    style={{
                        fontSize: '24px',
                        fontFamily: 'Nunito'
                    }}
                >
                    Program Belajar di Bimbel ALC
                </div>
                <div
                    className={
                        "grid " +
                        (isPhone(screenSize.width) ? " grid-cols-1 divide-y divide-gray-300 " : " grid-cols-3 divide-x divide-gray-300 ")
                    }
                >
                    { programs.map(p => (
                        <Box
                            program={p}
                        />
                    )) }
                </div>
            </div>
        </div>
    )
}

export default ProgramBelajarBox