import React, { useState } from 'react'
import { FaPaperPlane, FaWhatsapp, FaStar } from 'react-icons/fa'
import { CheckIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

const PaketProgramTab = ({ dataPaket }) => {

    const color = "main-color"

    const [openTab, setOpenTab] = useState(0);

    return (
        <>
            <div className='flex flex-wrap'>
                <div className='w-full bg-slate-600'>
                    <div className='mt-2 rounded p-2'>
                        <p className='text-xl text-white font-bold text-center'>Pilih Opsi Paket</p>
                    </div>
                    <ul
                        className='flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row'
                        role="tablist"
                    >
                        {dataPaket.map((program) => (
                            <li key={program.idPaketCategory} className='-mb-px mr-2 last:mr-0 flex-auto text-center'>
                                <a
                                    className={
                                        "text-xs font-bold uppercase px-5 py-3 shadow-xl rounded block leading-normal " +
                                        (openTab === program.idPaketCategory
                                            ? "text-white bg-" + color
                                            : "text-" + color + " bg-white"
                                        )
                                    }
                                    onClick={e => {
                                        e.preventDefault()
                                        setOpenTab(program.idPaketCategory);
                                    }}
                                    data-toggle="tab"
                                    href={"#link" + program.idPaketCategory}
                                    role="tablist"
                                >
                                    {program.paketCategoryName}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <div className='relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded'>
                        <div className='px-4 py-5 flex-auto'>
                            <div className='tab-content tab-space'>
                                {dataPaket.map((program) => (
                                    <div key={program.idPaketCategory} className={openTab === program.idPaketCategory ? "block" : "hidden"} id={"link" + program.idPaketCategory}>
                                        {program.paketProgramList.map((paket) => (
                                            <div key={paket.idPaketProgram} className='bg-white text-slate-900 mb-4 m-2 pt-4 pl-1 lg:p-4 lg:m-25 rounded-xl shadow-2xl'>
                                                {paket.isFavourite && (
                                                    <div className='relative md:top-10 lg:top-8 lg:left-6 md:left-2 rounded md:w-[250px] text-center float-right'>
                                                        <div className='py-4 px-2 rounded bg-yellow-400'>
                                                            <p className='text-white font-bold'><FaStar className='w-4 inline mb-1 mr-0' />Paling Favorit.</p>
                                                        </div>
                                                    </div>
                                                )}
                                                <span className='uppercase px-3 py-1 bg-slate-500  text-white rounded-2xl text-sm'>{paket.namaPaketProgram}</span>
                                                <div>
                                                    <p className='text-xl font-bold mt-4'>Harga Paket :</p>
                                                    <s><p className='text-xl ml-2 font-semibold flex pt-2'>{paket.hargaAwalPaketProgram} <span className='text-xl flex flex-col justify-end'>/mo</span></p></s>
                                                    <p className='text-4xl text-red-600 font-bold pb-4 ml-4 flex'>{paket.hargaFinalPaketProgram} <span className='text-xl text-red-600 flex flex-col justify-end'>/mo</span></p>
                                                </div>
                                                <Link to="/paymentMethod">
                                                    <button className='py-2 px-4 my-2 ml-4'><FaPaperPlane className='w-[25px] h-[25px] inline mb-1' /> Pilih Paket</button>
                                                </Link>
                                                <Link to="/goToWhatsapp">
                                                    <button className='py-2 px-4 my-2 ml-4 bg-slate-500 hover:bg-green-500 hover:text-white'> <FaWhatsapp className='w-[25px] h-[25px] inline mb-1' /> Tanya Admin</button>
                                                </Link>
                                                <p className='text-base py-2 ml-2 text-slate-500'>{paket.keteranganPaketProgram}</p>
                                                <div className='text-sm py-2 mr-1'>
                                                    {paket.benefitPaketProgram.map((benefit, index) => (
                                                        <div className='flex' key={paket.idPaketProgram + index}>
                                                            <span><CheckIcon className='w-7 mr-4 ml-2 mb-1 text-green-600' /></span>
                                                            <p>{benefit}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaketProgramTab