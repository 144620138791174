import React, { useEffect, useState } from 'react'
import { AiFillCloseCircle, AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { RiLoader5Fill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import axios from "axios"
import { handleResponse200 } from '../utils/HttpUtils'
import { notifyError } from "../utils/Notification"
import { register } from "../repository/User"
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useSelector } from 'react-redux'
import { isDesktop, isPhone } from '../utils/PlatformHelper'

const RegistrationSuccess = () => {
    return (
        <div
            className='w-full bg-white rounded-lg space-y-8 px-8 py-10'
        >
            <div
                className='text-center font-bold'
                style={{ 
                    fontFamily: "Nunito",
                    fontSize: "20px"
                }}
            >
                Registrasi Sukses
            </div>
            <div
                className='text-center flex flex-col'
            >
                <label>Proses pembuatan akun Bimbel ALC berhasil!</label>
                <label>Silahkan Login kembali</label>
            </div>
            <div
                className='flex justify-center'
            >
                <Link to="/login">
                    <button
                        className='py-2 w-[150px] w-full rounded-full bg-blue-500 text-white font-bold hover:bg-blue-700 hover:shadow-lg flex justify-center'
                        style={{ fontFamily: 'Nunito' }}
                    >
                        Login
                    </button>
                </Link>
            </div>

        </div>
    )
}

const Register = () => {

    const { member, screenSize } = useSelector(state => state.global)

    const [form, setForm] = useState({
        name: '',
        email: '',
        password: '',
        passwordShown: false,
        noHp: ''
    })

    const [isSaving, setIsSaving] = useState(false)

    const [errors, setErrors] = useState([])

    const [successRegister, setSuccessRegister] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function onRegister(
        name,
        email,
        password,
        noHp
    ) {
        
        const errors = []

        if (name === '') {
            errors.push('Nama tidak boleh kosong')
        }
        if (email === '') {
            errors.push('Email tidak boleh kosong')
        }
        if (password === '') {
            errors.push('Password tidak boleh kosong')
        }
        if (noHp === '') {
            errors.push('No HP tidak boleh kosong')
        }
        else {
            // console.log(noHp.startWith('8'))
            if (noHp.startsWith('0')) {
                errors.push('No HP harus tidak boleh diawali kode negara')
            }
        }

        if (errors.length > 0) {
            setErrors(errors)
            return
        }

        const payload = {
            namaOrangtua: name,
            email: email,
            password: password,
            hp: noHp
        }

        setIsSaving(true)

        register(payload)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setSuccessRegister(true)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        notifyError('Forbidden. Please Login')
                        // history.push("/login")
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {})
            .finally(() => {})
    }

    return (
        <>
        <div
            className={
                'w-full bg-second-color'+
                (isPhone(screenSize.width) ? " pb-10 " : "  h-screen flex justify-center ")
            }
        >
            <Navbar />
            <div
                className={
                    ''+
                    (isPhone(screenSize.width) ? " px-5 pt-32 " : " h-full flex items-center ")
                }
                style={{
                    width: isDesktop(screenSize.width) ? '450px' : '100%'
                }}
            >
                <div
                    className='w-full space-y-2'
                >
                    {errors.length > 0 && (
                        <div
                            className='bg-red-100 p-3 rounded-md relative'
                        >
                            <ul
                                className='space-y-2'
                                style={{ fontSize: '13px' }}
                            >
                            { errors.map(err => (
                                <li className='p-0 font-bold'>{err}</li>
                            )) }
                            </ul>
                            <div className='absolute top-2 right-2'>
                                <button
                                    onClick={() => setErrors([])}
                                >
                                    <AiFillCloseCircle className='text-red-500 h-5 w-5'/>
                                </button>
                            </div>
                        </div>
                    )}

                    {
                        successRegister ?
                            (<RegistrationSuccess />)
                            :
                            (<div
                                className='w-full bg-white rounded-lg space-y-8 px-8 py-10'
                            >
                                <div
                                    className='text-center font-bold'
                                    style={{ 
                                        fontFamily: "Nunito",
                                        fontSize: "20px"
                                    }}
                                >
                                    Buat akun baru Bimbel ALC
                                </div>
                                <div className='space-y-4'>
                                    <div className='space-y-2'>
                                        <div style={{ fontSize: '13px' }}>Nama Lengkap orangtua</div>
                                        <div>
                                            <input 
                                                className={
                                                    'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold ' +
                                                    (form.name !== null && form.name !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                                }
                                                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                                placeholder='Masukan Nama Lengkap di sini'
                                                value={form.name}
                                                onChange={e => setForm({ ...form, name: e.target.value })}
                                                disabled={isSaving}
                                            />
                                        </div>
                                    </div>
                                    <div className='space-y-2'>
                                        <div style={{ fontSize: '13px' }}>Email</div>
                                        <div>
                                            <input 
                                                className={
                                                    'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold ' +
                                                    (form.email !== null && form.email !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                                }
                                                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                                placeholder='Masukan Email di sini'
                                                value={form.email}
                                                onChange={e => setForm({ ...form, email: e.target.value })}
                                                disabled={isSaving}
                                            />
                                        </div>
                                    </div>
                                    <div className='space-y-2'>
                                        <div style={{ fontSize: '13px' }}>Password</div>
                                        <div
                                            className={
                                                'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold flex ' +
                                                (form.password !== null && form.password !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                            }
                                        >
                                            <input 
                                                type={form.passwordShown ? 'text' : 'password'}
                                                className={
                                                    'w-full bg-blue-100 rounded-md outline-none font-bold ' +
                                                    (form.password !== null && form.password !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                                }
                                                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                                placeholder='Masukan Password di sini'
                                                value={form.password}
                                                onChange={e => setForm({ ...form, password: e.target.value })}
                                                disabled={isSaving}
                                            />
                                            <button
                                                onClick={() => setForm({ ...form, passwordShown: !form.passwordShown })}
                                            >
                                                { form.passwordShown ?
                                                    <AiFillEye className='h-6 w-6 text-blue-700'/>
                                                    :
                                                    <AiFillEyeInvisible className='h-6 w-6 text-blue-700'/>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    <div className='space-y-2'>
                                        <div style={{ fontSize: '13px' }}>No. WA</div>
                                        <div
                                            className={
                                                'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold flex items-center space-x-2 ' +
                                                (form.noHp !== null && form.noHp !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                            }
                                        >
                                            <div>+62</div>
                                            <input
                                                className={
                                                    'w-full h-full bg-blue-100 rounded-md outline-none font-bold flex items-center ' +
                                                    (form.noHp !== null && form.noHp !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                                }
                                                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                                placeholder='8XXXX'
                                                value={form.noHp}
                                                onChange={e => setForm({ ...form, noHp: e.target.value })}
                                                disabled={isSaving}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='space-y-3'>
                                    <div>
                                        <button
                                            className='py-2 w-full rounded-full bg-blue-500 text-white font-bold hover:bg-blue-700 hover:shadow-lg flex justify-center'
                                            style={{ fontFamily: 'Nunito' }}
                                            disabled={isSaving}
                                            onClick={() => {
                                                onRegister(form.name, form.email, form.password, form.noHp)
                                            }}
                                        >
                                            {
                                                isSaving ?
                                                <RiLoader5Fill className={"h-6 w-6 animate-spin"} />
                                                :
                                                'Daftar sekarang'
                                            }
                                        </button>
                                    </div>
                                    <div
                                        className='flex justify-center'
                                        style={{ fontSize: '13px' }}
                                    >
                                        <h3>Sudah punya akun? <Link to="/login"><span className='text-blue-700 font-bold underline'>Masuk disini</span></Link></h3>
                                    </div>
                                </div>
        
                            </div>)
                    }
                </div>

            </div>
        </div>
        <Footer />
        </>
    )
}

export default Register