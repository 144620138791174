import { CheckIcon } from '@heroicons/react/outline'
import { FaRegCircle, FaRegCheckCircle } from 'react-icons/fa'
import React, { useState } from 'react'
import { ShoppingCartIcon, XIcon } from '@heroicons/react/solid'
import NavbarUser from '../../components/NavbarUser'
import { Link } from 'react-router-dom'


const PaymentMethod = () => {

    const dataPaketPilihan = {
        idPaketProgram: "idPaketProgram1",
        namaPaketProgram: "Gold",
        hargaAwalPaketProgram: "10.000.000",
        hargaFinalPaketProgram: "1.500.000",
        keteranganPaketProgram: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet autem exercitationem libero?",
        benefitPaketProgram: [
            "Lorem ipsum dolor sit.",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            "Lorem ipsum dolor sit.",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            "Lorem ipsum dolor sit.",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            "Lorem ipsum dolor sit.",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet  ipsum dolor sit amet consectetur adipisicing elit.",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
            "Lorem ipsum dolor sit.",
        ]

    }

    const dataPaymentMethod = [
        {
            idPaymentMethod: "idpm123",
            namaPaymentMethod: "Pembayaran Lunas",
            alurPembayaran: [
                {
                    namaAlurPembayaran: "Pembayaran Tunai",
                    keteranganAlurPembayaran: "Pembayaran dibayar sekarang",
                    jumlahPembayaran: "1.500.000"
                }
            ]
        },
        {
            idPaymentMethod: "idpm456",
            namaPaymentMethod: "Cicilan 2 Kali",
            alurPembayaran: [
                {
                    namaAlurPembayaran: "Cicilan Petama (1 dari 2)",
                    keteranganAlurPembayaran: "Pembayaran dibayar sekarang",
                    jumlahPembayaran: "750.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Kedua (2 dari 2)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 60 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "750.000"
                }
            ]
        },
        {
            idPaymentMethod: "idpm789",
            namaPaymentMethod: "Cicilan 3 Kali",
            alurPembayaran: [
                {
                    namaAlurPembayaran: "Cicilan Pertama (1 dari 3)",
                    keteranganAlurPembayaran: "Pembayaran dibayar sekarang",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Kedua (2 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 60 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Ketiga (3 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 30 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
            ]
        },
        {
            idPaymentMethod: "idpm789a",
            namaPaymentMethod: "Cicilan 3 Kali",
            alurPembayaran: [
                {
                    namaAlurPembayaran: "Cicilan Pertama (1 dari 3)",
                    keteranganAlurPembayaran: "Pembayaran dibayar sekarang",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Kedua (2 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 60 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Ketiga (3 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 30 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
            ]
        },
        {
            idPaymentMethod: "idpm780b9",
            namaPaymentMethod: "Cicilan 3 Kali",
            alurPembayaran: [
                {
                    namaAlurPembayaran: "Cicilan Pertama (1 dari 3)",
                    keteranganAlurPembayaran: "Pembayaran dibayar sekarang",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Kedua (2 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 60 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Ketiga (3 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 30 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Pertama (1 dari 3)",
                    keteranganAlurPembayaran: "Pembayaran dibayar sekarang",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Kedua (2 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 60 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Ketiga (3 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 30 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Pertama (1 dari 3)",
                    keteranganAlurPembayaran: "Pembayaran dibayar sekarang",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Kedua (2 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 60 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Ketiga (3 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 30 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Pertama (1 dari 3)",
                    keteranganAlurPembayaran: "Pembayaran dibayar sekarang",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Kedua (2 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 60 hari setelah cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
                {
                    namaAlurPembayaran: "Cicilan Ketiga (3 dari 3)",
                    keteranganAlurPembayaran: "Dibayar paling lambat 30 hari setelahxx cicilan sebelumnya",
                    jumlahPembayaran: "500.000"
                },
            ]
        },
    ]

    const [active, setActive] = useState(null);
    const [totalPembelian, setTotalPembelian] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const pickPayment = (id, price) => {
        setActive(id);
        setTotalPembelian(price);
    }

    return (
        <>
            <NavbarUser />
            <div className='w-full bg-slate-600 pb-[120px] pt-[75px]'>
                <div className='rounded p-2'>
                    <p className='text-xl pt-5 text-white font-bold text-center'>Paket yang dipilih:</p>
                </div>
                <div className='relative h-[505px] lg:h-[675px] overflow-x-auto mx-2 md:mx-6 lg:mx-auto bg-white text-center max-w-[1240px] items-center shadow-lg rounded'>
                    <div className='bg-white text-slate-900 pt-4 pl-1 lg:p-4 lg:m-25 rounded-xl shadow-2xl'>
                        <span className='uppercase px-3 py-1 bg-slate-500  text-white rounded-2xl text-sm'>{dataPaketPilihan.namaPaketProgram}</span>
                        <div>
                            <p className='text-xl font-bold mt-4'>Harga Paket :</p>
                            <p className='text-4xl text-red-600 font-bold pb-4 ml-4'>Rp. {dataPaketPilihan.hargaFinalPaketProgram}</p>
                        </div>
                        <p className='text-base py-2 ml-2 text-slate-500'>Benefit yang didapat:</p>
                        <div className='text-xs py-2 mr-1'>
                            <div className='grid md:grid-cols-2 gap-2'>
                                {dataPaketPilihan.benefitPaketProgram.map((benefit, index) => (
                                    <div key={index} className='flex'>
                                        <span><CheckIcon className='w-4 mr-4 md:ml-2 text-green-600' /></span>
                                        <p className='text-left'>{benefit}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <p className='mt-2 font-bold text-xl pb-5'>Pilih Metode Pembayaran :</p>
                            <div className='mx-5 pb-5'>
                                {dataPaymentMethod.map(paymentMethod => (
                                    <div key={paymentMethod.idPaymentMethod} className='rounded border-[1px] border-black mb-4'>
                                        <div
                                            onClick={() => pickPayment(paymentMethod.idPaymentMethod, paymentMethod.alurPembayaran[0].jumlahPembayaran)}
                                            className={active === paymentMethod.idPaymentMethod ? 'p-3 mb-3 rounded border-[2px] bg-main-color text-white' : 'p-3 mb-3 rounded border-[2px] border-black hover:bg-main-color hover:text-white'}
                                        >
                                            <div className='flex justify-between'>
                                                <p>{paymentMethod.namaPaymentMethod}</p>
                                                {active === paymentMethod.idPaymentMethod ? <FaRegCheckCircle className='mt-1' /> : <FaRegCircle className='mt-1' />}
                                            </div>
                                        </div>
                                        {active === paymentMethod.idPaymentMethod && (
                                            <div className='mx-5 my-2'>
                                                {paymentMethod.alurPembayaran.map((alur, index) =>
                                                (
                                                    <div key={index}>
                                                        <div className='flex justify-between flex-wrap'>
                                                            <p className='text-sm md:text-base font-semibold'>{alur.namaAlurPembayaran}</p>
                                                            <p className='text-sm md:text-base text-red-500 font-semibold'>{alur.jumlahPembayaran}</p>
                                                        </div>
                                                        <div>
                                                            <p className='text-sm md:text-base text-gray-400 text-justify my-1'>{alur.keteranganAlurPembayaran}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-gray-200 max-w-[1240px] rounded mx-auto px-6 py-3 inset-x-0 bottom-0 block fixed '>
                <div className='flex justify-between mb-4 bg-gray-300 rounded p-3'>
                    <div>
                        <p className='text-sm font-bold'>Diskon ABC</p>
                        <p className='text-xs font-semibold'>Hemat Rp. 0</p>
                    </div>
                    <div>
                        <p
                            className='text-blue-700 cursor-pointer'
                            onClick={() => setShowModal(true)}
                        >
                            <u>Ganti </u>
                        </p>
                    </div>
                </div>
                <div className='flex justify-between'>
                    <div>
                        <p className='text-sm font-semibold'>Total Pembelian</p>
                        <p className='text-red-500 font-bold'>Rp. {totalPembelian}</p>
                    </div>
                    <div>
                        <Link to="/paymentChannel">
                            <button className="py-2 px-8 rounded-xl hover:bg-blue-500 hover:text-white">Beli</button>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {showModal ? (
                <div className="flex justify-center items-center fixed md:bottom-5 bottom-0 top-4 left-0 right-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-[500px] my-6 mx-auto  ">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col  w-full bg-white outline-none focus:outline-none">
                            <div className="flex justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                                <h3 className="text-xl font-semibold">Gunakan Kode Diskon</h3>
                                <button
                                    className="bg-transparent border-0 text-black float-right"
                                    onClick={() => setShowModal(false)}
                                >
                                    <div className="text-black opacity-7 h-6 w-6 text-xl block  pb-2 rounded-full">
                                        <XIcon />
                                    </div>
                                </button>
                            </div>
                            <div className="relative p-3 flex-auto lg:h-[750px] h-[350px] overflow-y-scroll">
                                <div className='bg-gray-200 mb-5'>
                                    <form className="shadow-md rounded px-3 pt-6 pb-8 w-full">
                                        <label className="block text-black text-sm font-bold mb-1">
                                            Punya kode atau diskon referral?
                                        </label>
                                        <div className='flex p-4'>
                                            <input
                                                className="shadow pr-2 appearance-none border rounded w-full py-2 px-1 text-black text-xs"
                                                placeholder="Masukan Kode / Diskon Referral"
                                            />
                                            <button className='text-xs p-2'>Gunakan</button>
                                        </div>
                                    </form>
                                </div>
                                <div className='bg-gray-200 mb-2 rounded p-2'>
                                    <p className='font-bold text-sm p-2 pb-0'>KUNCIBELAJARAKU</p>
                                    <p className='text-gray-600 p-2 pt-0 text-xs'>Diskon Sampai dengan 65%</p>
                                    <div className='flex justify-between'>
                                        <div className='text-xs flex items-center'>
                                            <ShoppingCartIcon className='w-4 h-4 ml-2 inline' />
                                            <p>Berlaku hingga 12/12/2022</p>
                                        </div>
                                        <div className='text-xs'>
                                            <button className='p-2' onClick={() => setShowModal(false)}>Gunakan</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-200 mb-2 rounded p-2'>
                                    <p className='font-bold text-sm p-2 pb-0'>KUNCIBELAJARAKU</p>
                                    <p className='text-gray-600 p-2 pt-0 text-xs'>Diskon Sampai dengan 65%</p>
                                    <div className='flex justify-between'>
                                        <div className='text-xs flex items-center'>
                                            <ShoppingCartIcon className='w-4 h-4 ml-2 inline' />
                                            <p>Berlaku hingga 12/12/2022</p>
                                        </div>
                                        <div className='text-xs'>
                                            <button className='p-2' onClick={() => setShowModal(false)}>Gunakan</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-200 mb-2 rounded p-2'>
                                    <p className='font-bold text-sm p-2 pb-0'>KUNCIBELAJARAKU</p>
                                    <p className='text-gray-600 p-2 pt-0 text-xs'>Diskon Sampai dengan 65%</p>
                                    <div className='flex justify-between'>
                                        <div className='text-xs flex items-center'>
                                            <ShoppingCartIcon className='w-4 h-4 ml-2 inline' />
                                            <p>Berlaku hingga 12/12/2022</p>
                                        </div>
                                        <div className='text-xs'>
                                            <button className='p-2' onClick={() => setShowModal(false)}>Gunakan</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-200 mb-2 rounded p-2'>
                                    <p className='font-bold text-sm p-2 pb-0'>KUNCIBELAJARAKU</p>
                                    <p className='text-gray-600 p-2 pt-0 text-xs'>Diskon Sampai dengan 65%</p>
                                    <div className='flex justify-between'>
                                        <div className='text-xs flex items-center'>
                                            <ShoppingCartIcon className='w-4 h-4 ml-2 inline' />
                                            <p>Berlaku hingga 12/12/2022</p>
                                        </div>
                                        <div className='text-xs'>
                                            <button className='p-2' onClick={() => setShowModal(false)}>Gunakan</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-200 mb-2 rounded p-2'>
                                    <p className='font-bold text-sm p-2 pb-0'>KUNCIBELAJARAKU</p>
                                    <p className='text-gray-600 p-2 pt-0 text-xs'>Diskon Sampai dengan 65%</p>
                                    <div className='flex justify-between'>
                                        <div className='text-xs flex items-center'>
                                            <ShoppingCartIcon className='w-4 h-4 ml-2 inline' />
                                            <p>Berlaku hingga 12/12/2022</p>
                                        </div>
                                        <div className='text-xs'>
                                            <button className='p-2' onClick={() => setShowModal(false)}>Gunakan</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-200 mb-2 rounded p-2'>
                                    <p className='font-bold text-sm p-2 pb-0'>KUNCIBELAJARAKU</p>
                                    <p className='text-gray-600 p-2 pt-0 text-xs'>Diskon Sampai dengan 65%</p>
                                    <div className='flex justify-between'>
                                        <div className='text-xs flex items-center'>
                                            <ShoppingCartIcon className='w-4 h-4 ml-2 inline' />
                                            <p>Berlaku hingga 12/12/2022</p>
                                        </div>
                                        <div className='text-xs'>
                                            <button className='p-2' onClick={() => setShowModal(false)}>Gunakan</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-200 mb-2 rounded p-2'>
                                    <p className='font-bold text-sm p-2 pb-0'>KUNCIBELAJARAKU</p>
                                    <p className='text-gray-600 p-2 pt-0 text-xs'>Diskon Sampai dengan 65%</p>
                                    <div className='flex justify-between'>
                                        <div className='text-xs flex items-center'>
                                            <ShoppingCartIcon className='w-4 h-4 ml-2 inline' />
                                            <p>Berlaku hingga 12/12/2022</p>
                                        </div>
                                        <div className='text-xs'>
                                            <button className='p-2' onClick={() => setShowModal(false)}>Gunakan</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-200 mb-2 rounded p-2'>
                                    <p className='font-bold text-sm p-2 pb-0'>KUNCIBELAJARAKITA</p>
                                    <p className='text-gray-600 p-2 pt-0 text-xs'>Diskon Sampai dengan 65%</p>
                                    <div className='flex justify-between'>
                                        <div className='text-xs flex items-center'>
                                            <ShoppingCartIcon className='w-4 h-4 ml-2 inline' />
                                            <p>Berlaku hingga 12/12/2022</p>
                                        </div>
                                        <div className='text-xs'>
                                            <button className='p-2' onClick={() => setShowModal(false)}>Gunakan</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">

                            </div>
                        </div>
                    </div>
                </div>
            ) : null
            }

        </>
    )
}

export default PaymentMethod