import { Markup } from 'interweave';
import React, { useState } from 'react'

import { FaAngleUp, FaAngleDown } from 'react-icons/fa'
import NavbarUser from '../../components/NavbarUser';

import useClipboard from 'react-hook-clipboard';
import { Link } from 'react-router-dom';

const PaymentInvoice = () => {

    const [clipboard, copyToClipboard] = useClipboard();

    const dataTataCaraPembayaran = [
        {
            "title": "Internet Banking",
            "steps": [
                "Login ke internet banking Bank BRI Anda",
                "Pilih menu <b>Pembayaran</b> lalu klik menu <b>BRIVA</b>",
                "Pilih rekening sumber dan masukkan Kode Bayar (<b>{{pay_code}}</b>) lalu klik <b>Kirim</b>",
                "Detail transaksi akan ditampilkan, pastikan data sudah sesuai",
                "Masukkan kata sandi ibanking lalu klik <b>Request</b> untuk mengirim m-PIN ke nomor HP Anda",
                "Periksa HP Anda dan masukkan m-PIN yang diterima lalu klik <b>Kirim</b>",
                "Transaksi sukses, simpan bukti transaksi Anda"
            ]
        },
        {
            "title": "Aplikasi BRImo",
            "steps": [
                "Login ke aplikasi BRImo Anda",
                "Pilih menu <b>BRIVA</b>",
                "Pilih sumber dana dan masukkan Nomor Pembayaran (<b>{{pay_code}}</b>) lalu klik <b>Lanjut</b>",
                "Klik <b>Lanjut</b>",
                "Detail transaksi akan ditampilkan, pastikan data sudah sesuai",
                "Klik <b>Konfirmasi</b>",
                "Klik <b>Lanjut</b>",
                "Masukkan kata sandi ibanking Anda",
                "Klik <b>Lanjut</b>",
                "Transaksi sukses, simpan bukti transaksi Anda"
            ]
        },
    ]

    const [active, setActive] = useState(false);

    return (
        <>
            <NavbarUser />
            <div className='w-full bg-slate-600 pb-[120px] pt-[75px]'>
                <div className='rounded pt-10'>
                    <p className='mt-2 text-center font-bold text-2xl pb-5 text-white'>Invoice</p>
                </div>
                <div className='relative h-[505px] lg:h-[675px] overflow-x-auto mx-2 md:mx-6 lg:mx-auto bg-white max-w-[1240px] items-center shadow-lg rounded'>
                    <div className='bg-white text-slate-900 pt-4 pl-1 lg:p-4 lg:m-25 rounded-xl shadow-2xl'>
                        <div>
                            <div className='flex justify-between mx-10'>
                                <div>
                                    <p className='text-xs text-gray-500'>No. Invoice</p>
                                    <p className='text-sm font-bold'>INV-123466</p>
                                </div>
                                <div>
                                    <Link to="/paymentDetail">
                                        <p className='underline font-semibold my-auto text-blue-500'>Lihat Detail</p>
                                    </Link>
                                </div>
                            </div>

                            <div className='justify-left my-5'>
                                <p className='text-base mx-10 text-justify'>Kami telah mengirimkan tagihan pembayaran ke email Anda. Lakukan Pembayaran sebelum <b>Rabu, 20 April 2022 Pukul 11:15</b></p>
                            </div>

                            <div className='flex justify-between mx-10 mb-5'>
                                <div>
                                    <p className='text-xs text-gray-500'>Kode Pembayaran</p>
                                    <p className='text-sm font-bold'>41987418131335029</p>
                                </div>
                                <div>
                                    <p
                                        className={clipboard === '41987418131335029' ? 'font-semibold my-auto mx-auto text-gray-500' : 'underline font-semibold my-auto mx-auto text-blue-500'}
                                        onClick={() => copyToClipboard('41987418131335029')}
                                    >{clipboard === '41987418131335029' ? 'Tersalin' : 'Salin'}</p>
                                </div>
                            </div>

                            <div className='flex justify-between mx-10 mb-5'>
                                <div>
                                    <p className='text-xs text-gray-500'>Jumlah yang harus dibayar</p>
                                    <p className='text-sm font-bold'>Rp. 102.500</p>
                                </div>
                                <div>
                                    <p
                                        className={clipboard === '102.500' ? 'font-semibold my-auto mx-auto text-gray-500' : 'underline font-semibold my-auto mx-auto text-blue-500'}
                                        onClick={() => copyToClipboard('102.500')}
                                    >{clipboard === '102.500' ? 'Tersalin' : 'Salin'}</p>
                                </div>
                            </div>

                            <div className='mb-10'>
                                <h3 className='text-lg text-center font-bold mb-5'>Tata Cara Pembayaran</h3>
                                {dataTataCaraPembayaran.map((tataCaraPembayaran, index) => (
                                    <div
                                        key={tataCaraPembayaran.title + index}
                                        className='border-2 border-black m-2'
                                        onClick={() => setActive(tataCaraPembayaran.title + index)}
                                    >
                                        <div className='flex justify-between border-b-2 border-black'>
                                            <h4 className='text-lg font-semibold ml-2'>{tataCaraPembayaran.title}</h4>
                                            {active === tataCaraPembayaran.title + index ? <FaAngleDown className='w-[25px] h-[25px]' /> : <FaAngleUp className='w-[25px] h-[25px]' />}
                                        </div>
                                        <div className={active === tataCaraPembayaran.title + index ? 'mx-2' : 'hidden'} >
                                            {tataCaraPembayaran.steps.map((step, indexTwo) => (
                                                <ul className="list-disc ml-10" key={tataCaraPembayaran.title + indexTwo}>
                                                    <li>
                                                        <Markup content={step} />
                                                    </li>
                                                </ul>
                                            ))}
                                        </div>
                                    </div>
                                ))}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentInvoice