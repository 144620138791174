import axios from "axios"

export const getPaymentChan = async () => {
    const finalUrl =
        window._HOST + "member/payment-channel"

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const createTransaction = async (payload) => {
    const finalUrl =
        window._HOST + "member/new-transaction"

    const response =
        await axios.post(finalUrl, payload)

    return response.data
}