import { useEffect, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { RiLoader5Fill } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getTransactions, getTransactionsInstructions } from '../../repository/User'
import { handleResponse200 } from '../../utils/HttpUtils'
import { notifyError } from '../../utils/Notification'
import { format } from "date-fns"

const UserTransactionSimplified = ({
    idTransaksi,
    trxCreatedAt,
    expiredAt,
    finalPrice,
    paymentChannel,
    paymentCode,
    namaPaketBimbel,
    namaAnak,
    status,
    onClickDetail,
    onClickInstructions
}) => {

    function makeColor(paymentStatus) {
        switch (paymentStatus) {
            case "PAID":
                return "bg-green-500"
            
            case "FAILED" :
                return "bg-red-500"
            
            case "EXPIRED" :
                return "bg-blue-500"
            
            case "REFUND" :
                return "bg-blue-500"
            
            case "UNPAID" :
                return "bg-blue-500"

            default:
                return "bg-blue-500"
        }
    }

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
          { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
    }
     

    return (
        <div
            className="rounded-md border border-gray-300 relative divide-y divide-gray-300"
        >
            <div
                className="p-2 space-x-3 font-bold uppercase"
                style={{ fontSize: '15px' }}
            >
                {/* <label>{format(new Date(trxCreatedAt), "dd/MM/yyyy HH:mm")}</label> */}
                <label>ID Transaksi : {idTransaksi}</label>
            </div>
            <div className="p-2 space-y-1">
                <div>
                    <label
                        className="font-bold"
                        style={{ fontFamily: 'Nunito', fontSize: '15px' }}
                    >
                        {namaPaketBimbel}
                    </label>
                </div>
                <div
                    className=""
                    style={{ fontSize: '14px' }}
                >
                    {namaAnak}
                </div>
            </div>
            <div
                className='p-2'
                style={{ fontSize: '14px' }}
            >
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>tgl transaksi</div>
                    <div>:</div>
                    <div>{format(new Date(trxCreatedAt), "dd/MM/yyyy HH:mm")}</div>
                </div>
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Deadline</div>
                    <div>:</div>
                    <div className='font-bold'>{format(new Date(expiredAt), "dd/MM/yyyy HH:mm")}</div>
                </div>
            </div>
            <div
                className='p-2'
                style={{ fontSize: '14px' }}
            >
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>total bayar</div>
                    <div>:</div>
                    <div>{formatRupiah(finalPrice)}</div>
                </div>
                {finalPrice > 0 && (
                    <div
                        className='space-x-2 flex'
                    >
                        <div className='text-left w-24'>Bayar via</div>
                        <div>:</div>
                        <div>{paymentChannel}</div>
                    </div>
                )}
                {finalPrice > 0 && (
                    <div
                        className='space-x-2 flex'
                    >
                        <div className='text-left w-24'>Kode Bayar</div>
                        <div>:</div>
                        <div>{paymentCode}</div>
                    </div>
                )}
            </div>
            {finalPrice > 0 && (
                <div className='flex justify-center py-2'>
                    <button
                        className="p-1 px-3 rounded-md font-bold text-white bg-blue-500"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                        onClick={onClickInstructions}
                    >
                        Lihat Cara Bayar
                    </button>
                </div>
            )}
            <div
                className="p-1 px-2 flex items-center justify-between"
            >
                <div
                    className='flex space-x-2'
                >
                    <div 
                        className={
                            "p-1 px-3 rounded-md font-bold text-white "+
                            makeColor(status)
                        }
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                    >
                        {status}
                    </div>

                </div>
                <div
                >
                    {/* <button
                        className="text-blue-700 font-bold hover:underline"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                        onClick={onClickDetail}
                    >
                        Lihat detail
                    </button> */}
                </div>
            </div>
        </div>
    )
}

const UserTransactionInstructions = ({
    instructions,
    onBack
}) => {

    const Box = ({ instruction }) => {
        return (
            <div>
                <div 
                    className='font-bold uppercase p-2 rounded-md shadow-lg border bg-blue-600 text-white' 
                    style={{ fontFamily: 'Nunito' }}
                >
                    Via {instruction.title}
                </div>
                <div className='pl-5 pt-2'>
                    <ul className='list-decimal'>
                        { instruction.steps.map(s => (
                            <li className='py-1' style={{ fontSize: '15px' }} >{s}</li>
                        )) }
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className='space-y-5'>
            <div className='flex'>
                <button 
                    className='py-2 px-5 rounded-lg border flex items-center space-x-2 shadow-lg'
                    style={{
                        fontSize: '14px'
                    }}
                    onClick={onBack}
                >
                    <BsChevronLeft />
                    <label className='cursor-pointer'>Kembali</label>
                </button>
            </div>
            <div className='space-y-5'>
                {instructions.map(i => (
                    <Box instruction={i} />
                ))}
            </div>
        </div>
    )

}

const UserTransactionDetailed = ({
    transaction,
    paketBimbel,
    anak,
    onBack
}) => {
    return (
        <div
            className='space-y-5'
        >
            <div
                className='flex'
            >
                <button
                    className='p-1 px-2 pr-3 border rounded-md'
                    onClick={onBack}
                >
                    <BsChevronLeft className='h-5 w-5'/>
                </button>
            </div>
            <div
                className='space-y-3'
            >
                {/* Transaction Detail Box */}
                <div
                    className='rounded-md border p-2 space-y-2'
                    style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                    <div 
                        className=''
                    >
                        Detail Transaksi
                    </div>
                    <div
                        className='pl-2'
                    >
                        <div className='flex space-x-2'>
                            <div className='w-10 text-left'>id</div>
                            <div>:</div>
                            <div>222222</div>
                        </div>
                        <div className='flex space-x-2'>
                            <div className='w-10 text-left'>tgl</div>
                            <div>:</div>
                            <div>08/01/1994, 08:09</div>
                        </div>
                        <div className='flex space-x-2'>
                            <div className='w-10 text-left'>Status</div>
                            <div>:</div>
                            <div>Belum bayar</div>
                        </div>
                    </div>
                </div>
                <div
                    className='rounded-md border p-2 space-y-2'
                    style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                    <div 
                        className=''
                    >
                        Detail Pembayaran
                    </div>
                    <div
                        className='pl-2'
                    >
                        <div className='flex space-x-2'>
                            <div className='w-10 text-left'>id</div>
                            <div>:</div>
                            <div>222222</div>
                        </div>
                        <div className='flex space-x-2'>
                            <div className='w-10 text-left'>tgl</div>
                            <div>:</div>
                            <div>08/01/1994, 08:09</div>
                        </div>
                    </div>
                </div>
                {/* Paket Bimbel Detail Box */}
                <div
                    className='rounded-md border p-2 space-y-2'
                    style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                    <div 
                        className=''
                    >
                        Detail Paket Bimbel
                    </div>
                    <div
                        className='pl-2'
                    >
                        <div className='flex space-x-2'>
                            <div className='w-6 text-left'>id</div>
                            <div>:</div>
                            <div>222222</div>
                        </div>
                        <div className='flex space-x-2'>
                            <div className='w-6 text-left'>Nama</div>
                            <div>:</div>
                            <div>08/01/1994, 08:09</div>
                        </div>
                    </div>
                </div>
                {/* Anak Detail Box */}
                <div
                    className='rounded-md border p-2 space-y-2'
                    style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                    <div 
                        className=''
                    >
                        Detail Anak
                    </div>
                    <div
                        className='pl-2'
                    >
                        <div className='flex space-x-2'>
                            <div className='w-10 text-left'>id</div>
                            <div>:</div>
                            <div>222222</div>
                        </div>
                        <div className='flex space-x-2'>
                            <div className='w-10 text-left'>Nama</div>
                            <div>:</div>
                            <div>Bagus Jatikusuma</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const openTransactionDetail = (setModel, data) => {
    setModel(model => ({ ...model, mode: 'detail', detailPage: { ...model.detailPage, selectedData: data } }))
}

const closeTransactionDetail = (setModel) => {
    setModel(model => ({ ...model, mode: 'data', detailPage: { ...model.detailPage, selectedData: null } }))
}

const UserTransaction = () => {
    const navigate = useNavigate()

    const { member } = useSelector(state => state.global)

    const [model, setModel] = useState({
        mode: 'data', //data or detail
        detailPage: {
            selectedData: null
        }
    })

    const [isGettingData, setIsGettingData] = useState(false)

    const [transactions, setTransactions] = useState([])
    const [transactionInstructions, setTransactionInstructions] = useState([])

    const [mode, setMode] = useState('data') //data, detailed or instructions
    const [selectedTransaction, setSelectedTransaction] = useState(null)

    useEffect(() => {
        if (!member.id) {
            return
        }

        if (mode === 'data') {

            setIsGettingData(true)

            getTransactions()
                .then(res => {
                    handleResponse200({
                        httpResponse: res,
                        onSuccess: payload => {
                        setTransactions(payload)  
                        },
                        onRecovFailure: errors => errors.forEach(err => {
                            notifyError(err)
                        }),
                        onUnAuth: error => {
                            navigate('/login')
                        },
                        onTechnicalError: errors => errors.forEach(err => {
                            notifyError(err)
                        })
                    })
                })
                .catch(error => {})
                .finally(() => {
                    setIsGettingData(false)
                })
        }
        else if (mode === 'instructions' && selectedTransaction) {
            setIsGettingData(true)

            getTransactionsInstructions(selectedTransaction.idTransaction)
                .then(res => {
                    handleResponse200({
                        httpResponse: res,
                        onSuccess: payload => {
                            setTransactionInstructions(payload)
                        },
                        onRecovFailure: errors => errors.forEach(err => {
                            notifyError(err)
                        }),
                        onUnAuth: error => {
                            navigate('/login')
                        },
                        onTechnicalError: errors => errors.forEach(err => {
                            notifyError(err)
                        })
                    })
                })
                .catch(error => {})
                .finally(() => {
                    setIsGettingData(false)
                })

        }

    }, [member, mode, selectedTransaction])

    return (
        <div
            className='w-full bg-second-color flex justify-center'
        >
            <div
                className='min-h-screen w-[450px]'
            >
                <div
                    className='w-full min-h-full bg-white rounded-lg'
                >
                    <div 
                        className="flex justify-between px-6 font-bold bg-blue-800 text-white rounded-tl-lg rounded-tr-lg rounded-bl-2xl rounded-br-2xl pt-12 pb-10"
                        style={{  
                            fontFamily: 'Nunito',
                            height: "200px"
                        }}
                    >
                        <div className="">
                            <button
                                onClick={() => navigate('/member')}
                            >
                                <BsChevronLeft className="h-6 w-6"/>
                            </button>
                        </div>
                        <div
                            style={{
                                fontSize: '20px',
                            }}
                        >
                            Riwayat Transaksi
                        </div>
                        <div/>
                    </div>
                    <div 
                        className="px-6 "
                    >
                        <div
                            className="p-5 bg-white rounded-lg shadow-lg z-10"
                            style={{
                                marginTop: '-50px'
                            }}
                        >

                            <div
                                className="space-y-5"
                            >
                                {
                                    isGettingData ?
                                        (<div className='flex justify-center'>
                                            <RiLoader5Fill className={"h-8 w-8 text-blue-500 animate-spin"} />
                                        </div>)
                                        :
                                        mode === 'data' ?
                                            transactions.length > 0 ?
                                                transactions.map(d => (
                                                    <UserTransactionSimplified 
                                                        key={d.idTransaction}
                                                        idTransaksi={d.idTransaction}
                                                        trxCreatedAt={d.createdAt}
                                                        expiredAt={d.expiredAt}
                                                        finalPrice={d.finalPrice}
                                                        paymentChannel={d.paymentChannel}
                                                        paymentCode={d.paymentCode}
                                                        idPaketBimbel={d.paketBimbel.id}
                                                        namaPaketBimbel={d.paketBimbel.label}
                                                        namaAnak={d.anak.label}
                                                        status={d.paymentStatus}
                                                        onClickDetail={() => openTransactionDetail(setModel, d)}
                                                        onClickInstructions={() => {
                                                            setMode('instructions')
                                                            setSelectedTransaction(d)
                                                        }}
                                                    />
                                                ))
                                                :
                                                (
                                                    <div 
                                                        className='flex justify-center'
                                                    >
                                                        Tidak ada data
                                                    </div>
                                                )
                                            :
                                            <UserTransactionInstructions 
                                                instructions={transactionInstructions}
                                                onBack={() => {
                                                    setMode('data')
                                                    setSelectedTransaction(null)
                                                }}
                                            />
                                }
                            </div>

                        </div>
                    </div>
                    <div className="py-10">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserTransaction

const dummies = [
    {
        idTransaksi: 'TRX000001',
        trxCreatedAt: '29 Maret 2022, 18:17:03',
        idPaketBimbel: 'PKB02220120001',
        namaPaketBimbel: 'Paket BIMA',
        hargaPaketBimbel: '27000',
        status: 'lunas',
        anak: {
            id: 'ANK0001',
            nama: 'Bagus Jatikusuma'
        }
    },
    {
        idTransaksi: 'TRX000002',
        trxCreatedAt: '29 Maret 2022, 18:27:03',
        idPaketBimbel: 'PKB02220120002',
        namaPaketBimbel: 'Paket BIMA 2',
        hargaPaketBimbel: '27000',
        status: 'BELUM LUNAS',
        anak: {
            id: 'ANK0002',
            nama: 'Bagus Jatikusuma2'
        }
    },
]