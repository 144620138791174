import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { isPhone } from "../utils/PlatformHelper";

export const InfoBanner = () => {
    const { screenSize } = useSelector(state => state.global);

    const [isShow, setIsShow] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsShow(false)
        }, 5000)
    }, [])

    return (
        isShow && (
            <>
            <div 
                className="test-test fixed bg-black top-0 left-0 right-0 bottom-0 opacity-50"
                style={{ zIndex : 51 }}>
            </div>
            <div 
                className="test-test fixed bg-transparent top-0 left-0 right-0 bottom-0 flex items-center justify-center"
                style={{ zIndex : 52 }}
            >
                <div 
                    className="bg-white relative p-10"
                    style={{
                        width: isPhone(screenSize.width) ? "100%" : "800px",
                        // height: isPhone(screenSize.width) ? "100%" : "700px",
                        maxWidth: isPhone(screenSize.width) ? "350px" : "600px",
                        maxHeight: "650px"
                    }}
                >
                    <div className="absolute top-1 right-1">
                        <button 
                            className="rounded-full px-2 bg-red-500 text-white"
                            onClick={() => setIsShow(false)}
                        >
                            X
                        </button>
                    </div>
                    <div style={{
                        textAlign: 'center',
                        fontSize: '23px',
                        textTransform: 'uppercase',
                        marginBottom: '30px'
                    }}>
                        Informasi
                    </div>
                    <div>
                        <p style={{ textAlign: 'center', marginBottom: '30px' }}>
                        Untuk meningkatkan layanan, semua konten di website <b>bimbelalc.id</b> ini akan dialihkan ke domain <b>kuncibelajar.id</b> dalam waktu dekat.
                        </p>
                        <p style={{ textAlign: 'center' }}>
                        Semua layanan tetap berjalan dan dapat diakses sebagaimana biasa.
                        </p>
                    </div>
                </div>
            </div>
            </>
        )
    )

    // return (
    //     isShow && (
    //         <>
    //         <div className="fixed top-0 right-0 bottom-0 left-0 bg-black z-10 opacity-50">
    //         </div>
    //         <div className="fixed top-0 right-0 bottom-0 left-0 z-10">
    //             <div className="w-full h-full flex justify-center items-center">
    //                 <div className="p-5 rounded-lg bg-white w-[400px]" style={{ width: "600px" }}>
    //                     <p className="text-center font-bold">
    //                         PEMBERITAHUAN
    //                     </p>
    //                     <p className="text-center pt-2">
    //                     Pembelian Paket <b>Program Sukses SNBT+2024</b> (Kelas 12 SMA) Bimbel ALC akan <b>ditutup pada Ahad, 30 Juli 2023, pukul 00.00 WIB</b>, dan akan <b>dibuka kembali pada Rabu, 02 Agustus 2024 pukul 09.00 WIB</b>
    //                     </p>
    //                     <div className="flex justify-center mt-5">
    //                         <button 
    //                             className="p-2 px-10 rounded-lg bg-blue-500 text-white"
    //                             onClick={() => {
    //                                 setIsShow(false)
    //                             }}
    //                         >
    //                             OK
    //                         </button>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //         </>
    //     )
    // )
}