import React from 'react'
import NavbarUser from '../../components/NavbarUser'
// import { FaRegQuestionCircle } from 'react-icons/fa'

import camp from '../../assets/camp.png'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'


const TransactionHistory = () => {


    const dataTransactionHistory = [
        {
            idInvoice: "INV-111111",
            statusTransaksi: "Unpaid",
            keteranganTransaksi: "Cicilan (3 dari 3) Pembelian Paket Bimbel SMP"
        },
        {
            idInvoice: "INV-111112",
            statusTransaksi: "Pending",
            keteranganTransaksi: "Cicilan (2 dari 3) Pembelian Paket Bimbel SMP"
        },
        {
            idInvoice: "INV-111113",
            statusTransaksi: "Paid",
            keteranganTransaksi: "Cicilan (1 dari 3) Pembelian Paket Bimbel SMP"
        },
        {
            idInvoice: "INV-111114",
            statusTransaksi: "Expired",
            keteranganTransaksi: "Beli Tunai (Langsung Lunas) Paket Bimbel SMP"
        }
    ]

    return (
        <>
            <NavbarUser />
            <div className='w-full bg-slate-600 pb-[120px] pt-[75px]'>
                <div className='rounded p-2'>
                    <p className='text-xl pt-5 text-white font-bold text-center'>History Transaksi: </p>
                </div>
                <div className='relative h-[505px] lg:h-[675px] overflow-x-auto mx-2 md:mx-6 lg:mx-auto bg-white text-center max-w-[1240px] items-center shadow-lg rounded'>
                    <div className='bg-white text-slate-900 pt-4 pl-1 lg:p-4 lg:m-25 rounded-xl shadow-2xl'>
                        <div className='grid'>
                            {dataTransactionHistory.map(transaction => (
                                <div
                                    className='border-2 border-black md:p-5 md:m-5 m-1 pt-2 pl-2 rounded'
                                    key={transaction.idInvoice}
                                >
                                    <div className='flex justify-between'>
                                        <div
                                            className={
                                                transaction.statusTransaksi === 'Unpaid' ? 'bg-slate-400 w-full mr-5 py-2 px-6 rounded text-white'
                                                    : transaction.statusTransaksi === 'Pending' ? 'bg-blue-400 w-full mr-5 py-2 px-6 rounded text-white'
                                                        : transaction.statusTransaksi === 'Paid' ? 'bg-green-400 w-full mr-5 py-2 px-6 rounded text-white'
                                                            : 'bg-red-400 w-full mr-5 py-2 px-6 rounded text-white'
                                            }>
                                            <p className='md:text-xl text-sm'>{transaction.statusTransaksi}</p>
                                        </div>
                                        {/* <div className='p-2'>
                                            <FaRegQuestionCircle className='w-[25px] h-[25px] mr-2 md:mr-0 items-center mx-auto' />
                                        </div> */}
                                    </div>
                                    <div className='flex'>
                                        <div>
                                            <img src={camp} alt="/" className='ml-4 mt-4 rounded-full w-[50px] h-[50px] md:w-[100px] md:h-[100px]' />
                                        </div>
                                        <div className='md:ml-8 ml-5 md:mt-5 mt-2'>
                                            <Link to="/PaymentInvoice">
                                                <h2 className='font-semibold text-base md:text-2xl text-blue-500 text-left'>{transaction.idInvoice}</h2>
                                            </Link>
                                            <div className='flex'>
                                                <CheckCircleIcon className='md:w-[25px] w-[15px] mt-2 text-main-color' />
                                                <p className='my-auto pl-1 pt-1 font-semibold md:text-base text-sm1'>kuncibelajar</p>
                                            </div>
                                            <p className='text-xs md:text-sm text-left mb-2 mt-1 font-semibold'>{transaction.keteranganTransaksi}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransactionHistory