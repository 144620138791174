import { useEffect, useState } from "react"
import { BsChevronLeft } from "react-icons/bs"
import { RiLoader5Fill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { setMember } from "../../app/GlobalSlice"
import { checkIsAuthenticated, getKabupaten, getKecamatan, getProvinsi, update } from "../../repository/User"
import { handleResponse200 } from "../../utils/HttpUtils"
import { notifyError, notifySuccess } from "../../utils/Notification"
import Select from "react-select"
import { isDesktop, isPhone } from "../../utils/PlatformHelper"

const UserProfile = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { member, screenSize } = useSelector(state => state.global)

    const [form, setForm] = useState({
        id: '',
        nama: '',
        email: '',
        noHp: ''
    })

    useEffect(() => {
        if (!member) return;

        setForm({
            ...form,
            id: member.id,
            nama: member.nama,
            email: member.email,
            noHp: member.hp,
            fullAddress: member.fullAddress
        })

        if (member.provinsi) {
            setProvinsiOpts({ ...provinsiOpts, selectedOpt: { label: member.provinsi.label, value: member.provinsi.id } })
        }

        if (member.kabupaten) {
            setKabupatenOpts({ ...kabupatenOpts, selectedOpt: { label: member.kabupaten.label, value: member.kabupaten.id } })
        }

        if (member.kecamatan) {
            setKecamatanOpts({ ...kecamatanOpts, selectedOpt: { label: member.kecamatan.label, value: member.kecamatan.id } })
        }

    }, [member])

    const [errors, setErrors] = useState([])

    const [isSaving, setIsSaving] = useState(false)

    const [refetchProfile, setRefetchProfile] = useState(false)

    const [provinsiOpts, setProvinsiOpts] = useState({
        selectedOpt: null,
        data: [],
        isLoad: false
    })

    const [kabupatenOpts, setKabupatenOpts] = useState({
        selectedOpt: null,
        data: [],
        isLoad: false
    })

    const [kecamatanOpts, setKecamatanOpts] = useState({
        selectedOpt: null,
        data: [],
        isLoad: false
    })

    // useEffect(() => {
    //     setKabupatenOpts({ ...kabupatenOpts, selectedOpt: null })
    // }, [provinsiOpts.selectedOpt])

    // useEffect(() => {
    //     setKecamatanOpts({ ...kecamatanOpts, selectedOpt: null })
    // }, [kabupatenOpts.selectedOpt])

    useEffect(() => {
        if (!refetchProfile) return;

        checkIsAuthenticated({
            onStartChecking: () => setIsSaving(true),
            onFinishChecking: () => {
                setIsSaving(false)
                setRefetchProfile(false)
            },
            onAuthenticated: payload => {
              const member = {
                id: payload.idOrangtua,
                nama: payload.nama,
                email: payload.email,
                hp: payload.hp,
                provinsi: payload.provinsi,
                kabupaten: payload.kabupaten,
                kecamatan: payload.kecamatan,
                fullAddress: payload.fullAddress
              }
  
              dispatch(setMember(member))
              notifySuccess('Berhasil update profil')
            },
            onUnAuthenticated: () => {}
        })

    }, [refetchProfile])

    function onUpdate(
        nama,
        noHp,
        selectedProvinsi,
        selectedKabupaten,
        selectedKecamatan,
        fullAddress
    ) {

        if (nama === ''
            || noHp === ''
            || !selectedProvinsi
            || !selectedKabupaten
            || !selectedKecamatan
            || fullAddress === ''
        ) {
            return notifyError("Semua field harus diisi")
        }

        setIsSaving(true)

        const payload = {
            namaOrangtua: nama,
            hp: noHp,
            idProvinsi: selectedProvinsi,
            idKabupaten: selectedKabupaten,
            idKecamatan: selectedKecamatan,
            fullAddress: fullAddress
        }

        update(payload)
            .then(res => {

                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setRefetchProfile(true)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })

            })
            .catch(error => {})
            .finally(() => {
                setIsSaving(false)
            })

    }

    function onSelectProvinsiOpts() {
        setProvinsiOpts({ ...provinsiOpts, isLoad: true })
    
        getProvinsi()
            .then(response => {
                if (response.success) {
                const data = response.payload.map(d => ({ label: d.label, value: d.id }))
                setProvinsiOpts({ ...provinsiOpts, data: data, isLoad: false })
                }
                else {
                setProvinsiOpts({ ...provinsiOpts, isLoad: false })
                }
            })
            .catch(error => {
                setProvinsiOpts({ ...provinsiOpts, isLoad: false })
                if (error.response != undefined && error.response != null) {
                if (error.response.status == 401) {
                    notifyError("Forbidden")
                }
                else {
                    notifyError("Server Error")
                }
                }
            })
    }
    
    function onSelectKabupatenOpts() {
        if (!provinsiOpts.selectedOpt) return;
        setKabupatenOpts({ ...kabupatenOpts, isLoad: true })
    
        getKabupaten(provinsiOpts.selectedOpt.value)
            .then(response => {
                if (response.success) {
                const data = response.payload.map(d => ({ label: d.label, value: d.id }))
                setKabupatenOpts({ ...kabupatenOpts, data: data, isLoad: false })
                }
                else {
                setKabupatenOpts({ ...kabupatenOpts, isLoad: false })
                }
            })
            .catch(error => {
                setKabupatenOpts({ ...kabupatenOpts, isLoad: false })
                if (error.response != undefined && error.response != null) {
                if (error.response.status == 401) {
                    notifyError("Forbidden")
                }
                else {
                    notifyError("Server Error")
                }
                }
            })
    }

    function onSelectKecamatanOpts() {
        if (!kabupatenOpts.selectedOpt) return;
        setKecamatanOpts({ ...kecamatanOpts, isLoad: true })
    
        getKecamatan(kabupatenOpts.selectedOpt.value)
            .then(response => {
                if (response.success) {
                const data = response.payload.map(d => ({ label: d.label, value: d.id }))
                setKecamatanOpts({ ...kecamatanOpts, data: data, isLoad: false })
                }
                else {
                setKecamatanOpts({ ...kecamatanOpts, isLoad: false })
                }
            })
            .catch(error => {
                setKecamatanOpts({ ...kecamatanOpts, isLoad: false })
                if (error.response != undefined && error.response != null) {
                if (error.response.status == 401) {
                    notifyError("Forbidden")
                }
                else {
                    notifyError("Server Error")
                }
                }
            })
    }


    return (
        <div
            className={
                'w-full  bg-second-color ' +
                (isDesktop(screenSize.width) ? " h-screen flex justify-center " : "")
            }
        >
            <div
                className={
                    '' +
                    (isPhone(screenSize.width) ? '' : ' w-[450px] h-full ')
                }
            >
                <div
                    className='w-full h-full bg-white rounded-lg overflow-y-auto'
                >
                    <div 
                        className="flex justify-between px-6 font-bold bg-blue-800 text-white rounded-tl-lg rounded-tr-lg rounded-bl-2xl rounded-br-2xl pt-12 pb-10"
                        style={{  
                            fontFamily: 'Nunito',
                            height: "200px"
                        }}
                    >
                        <div className="">
                            <button
                                onClick={() => navigate('/member')}
                                disabled={isSaving}
                            >
                                <BsChevronLeft className="h-6 w-6"/>
                            </button>
                        </div>
                        <div
                            style={{
                                fontSize: '20px',
                            }}
                        >
                            Atur Profil Orangtua
                        </div>
                        <div/>
                    </div>
                    <div 
                        className="px-6 "
                    >
                        <div
                            className="p-5 bg-white rounded-lg shadow-lg z-10"
                            style={{
                                marginTop: '-50px'
                            }}
                        >
                            <div
                                className="space-y-5"
                            >
                                <div
                                    className="space-y-5"
                                >
                                    <div className='space-y-2'>
                                        <div
                                            className="font-bold" 
                                            style={{ 
                                                fontSize: '13px',
                                                fontFamily: 'Nunito'
                                            }}
                                        >
                                            ID
                                        </div>
                                        <div>
                                            <input 
                                                className={
                                                    'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold '
                                                }
                                                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                                value={form.id}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className='space-y-2'>
                                        <div
                                            className="font-bold" 
                                            style={{ 
                                                fontSize: '13px',
                                                fontFamily: 'Nunito'
                                            }} 
                                        >
                                            Nama Lengkap
                                        </div>
                                        <div>
                                            <input 
                                                className={
                                                    'w-full py-2 px-2 border-2 rounded-md outline-none font-bold ' +
                                                    (form.nama !== '' ? ' bg-blue-100 ' : ' bg-red-100 ' )
                                                }
                                                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                                value={form.nama}
                                                onChange={e => setForm({ ...form, nama: e.target.value })}
                                                disabled={isSaving}
                                            />
                                        </div>
                                    </div>
                                    <div className='space-y-2'>
                                        <div
                                            className="font-bold" 
                                            style={{ 
                                                fontSize: '13px',
                                                fontFamily: 'Nunito'
                                            }} 
                                        >
                                            Email
                                        </div>
                                        <div>
                                            <input
                                                className={
                                                    'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold '
                                                }
                                                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                                value={form.email}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className='space-y-2'>
                                        <div
                                            className="font-bold" 
                                            style={{ 
                                                fontSize: '13px',
                                                fontFamily: 'Nunito'
                                            }} 
                                        >
                                            No. HP
                                        </div>
                                        <div
                                            className={
                                                'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold flex items-center space-x-2 ' +
                                                (form.noHp !== null && form.noHp !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                            }
                                            style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                        >
                                            <div>+62</div>
                                            <input
                                                className={
                                                    'w-full h-full bg-blue-100 rounded-md outline-none font-bold flex items-center ' +
                                                    (form.noHp !== null && form.noHp !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                                }
                                                value={form.noHp}
                                                onChange={e => setForm({ ...form, noHp: e.target.value })}
                                                disabled={isSaving}
                                            />
                                        </div>
                                    </div>
                                    <div className='space-y-2'>
                                        <div
                                            className="font-bold" 
                                            style={{ 
                                                fontSize: '13px',
                                                fontFamily: 'Nunito'
                                            }} 
                                        >
                                            Provinsi
                                        </div>
                                        <div
                                            className={
                                                'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold flex items-center space-x-2 '
                                            }
                                            style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                        >
                                            <Select
                                                placeholder="Pilih provinsi"
                                                value={provinsiOpts.selectedOpt}
                                                options={provinsiOpts.data}
                                                isLoading={provinsiOpts.isLoad}
                                                menuPlacement="auto"
                                                className="w-96 border border-gray-200 rounded-md"
                                                onFocus={onSelectProvinsiOpts}
                                                onChange={(value) => {
                                                    setProvinsiOpts({ ...provinsiOpts, selectedOpt: value })
                                                    setKabupatenOpts({ ...kabupatenOpts, selectedOpt: null })
                                                    setKecamatanOpts({ ...kecamatanOpts, selectedOpt: null })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='space-y-2'>
                                        <div
                                            className="font-bold" 
                                            style={{ 
                                                fontSize: '13px',
                                                fontFamily: 'Nunito'
                                            }} 
                                        >
                                            Kota/Kabupaten
                                        </div>
                                        <div
                                            className={
                                                'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold flex items-center space-x-2 '
                                            }
                                            style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                        >
                                            <Select
                                                placeholder="Pilih Kabupaten"
                                                value={kabupatenOpts.selectedOpt}
                                                options={kabupatenOpts.data}
                                                isLoading={kabupatenOpts.isLoad}
                                                menuPlacement="auto"
                                                className="w-96 border border-gray-200 rounded-md"
                                                onFocus={onSelectKabupatenOpts}
                                                onChange={(value) => {
                                                    setKabupatenOpts({ ...kabupatenOpts, selectedOpt: value })
                                                    setKecamatanOpts({ ...kecamatanOpts, selectedOpt: null })
                                                }}
                                            />   
                                        </div>
                                    </div>
                                    <div className='space-y-2'>
                                        <div
                                            className="font-bold" 
                                            style={{ 
                                                fontSize: '13px',
                                                fontFamily: 'Nunito'
                                            }} 
                                        >
                                            Kecamatan
                                        </div>
                                        <div
                                            className={
                                                'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold flex items-center space-x-2 '
                                            }
                                            style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                        >
                                            <Select
                                                placeholder="Pilih Kecamatan"
                                                value={kecamatanOpts.selectedOpt}
                                                options={kecamatanOpts.data}
                                                isLoading={kecamatanOpts.isLoad}
                                                menuPlacement="auto"
                                                className="w-96 border border-gray-200 rounded-md"
                                                onFocus={onSelectKecamatanOpts}
                                                onChange={(value) => {
                                                    setKecamatanOpts({ ...kecamatanOpts, selectedOpt: value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='space-y-2'>
                                        <div
                                            className="font-bold" 
                                            style={{ 
                                                fontSize: '13px',
                                                fontFamily: 'Nunito'
                                            }} 
                                        >
                                            Alamat Lengkap
                                        </div>
                                        <div
                                            className={
                                                'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold flex items-center space-x-2 '
                                            }
                                            style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                        >
                                            <textarea
                                                className="w-full bg-blue-100 outline-none"
                                                value={form.fullAddress}
                                                onChange={e => setForm({ ...form, fullAddress: e.target.value })}
                                                rows={5}
                                                placeholder="Masukan alamat lengkap"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="flex justify-center"
                                >
                                    <button
                                        className="bg-blue-500 text-white px-8 py-1 rounded-md"
                                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                                        disabled={isSaving}
                                        onClick={() => {
                                            onUpdate(
                                                form.nama, 
                                                form.noHp,
                                                provinsiOpts.selectedOpt ? provinsiOpts.selectedOpt.value : null,
                                                kabupatenOpts.selectedOpt ? kabupatenOpts.selectedOpt.value : null,
                                                kecamatanOpts.selectedOpt ? kecamatanOpts.selectedOpt.value : null,
                                                form.fullAddress
                                            )
                                        }}
                                    >
                                        {
                                            isSaving ?
                                                <RiLoader5Fill className={"h-6 w-6 text-white animate-spin"} />
                                                :
                                                'Update'
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-10">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile