import React, { useEffect, useState } from "react";
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { isDesktop } from "../utils/PlatformHelper"
import { AiOutlineMenu } from "react-icons/ai";
import { GiNotebook } from "react-icons/gi";
import { BsChevronDown, BsChevronUp, BsFillTelephoneFill } from "react-icons/bs";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

function Navbar() {

    const { member, programs, screenSize } = useSelector(state => state.global)

    const [dropdownMenu, setDropdownMenu] = useState(false);

    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const [isProgramExpanded, setIsProgramExpanded] = useState(false)

    const [isProgramHovered, setIsProgramHovered] = useState(false)

    const handleClick = () => setDropdownMenu(!dropdownMenu);

    const handleClose = () => setDropdownMenu(!dropdownMenu);

    useEffect(() => {
        if (member.id) {
            setIsLoggedIn(true)
        }
        else {
            setIsLoggedIn(false)
        }
    }, [member])

    return (
        <div
            className={
                "w-full absolute top-0 z-50 flex justify-center py-2 "
            }
        >
            <div 
                className="hidden lg:flex bg-white absolute left-0 top-5"
                style={{
                    height: '83px',
                    width: (screenSize.width - 1260) + 'px'
                }}
            />
            <div 
                className="py-3 z-10"
                style={{ 
                    width: isDesktop(screenSize.width) ? "1260px" : "100%"
                }}
            >
                <div className="flex justify-between items-center w-full h-full">
                    <div 
                        className="flex bg-white rounded-tr-full rounded-br-full px-10 py-2 space-x-10"
                        style={{
                            height: '83px'
                        }}
                    >
                        <div className="flex items-center">
                            <Link to="/">
                                <img src={'/images/logo.png'} width="100px"/>
                            </Link>
                        </div>
                        <div className="lg:flex items-center">
                            <ul 
                                className="hidden lg:flex text-blue-100 font-bold"
                                style={{
                                    fontFamily: "Signika",
                                    color: '#0079C4'
                                }}
                            >
                                <li 
                                    className="group relative hover:text-blue-900"
                                    onMouseEnter={() => setIsProgramHovered(true)}
                                    onMouseLeave={() => setIsProgramHovered(false)}
                                >
                                    <Link to={'#'}>
                                        Program Belajar
                                    </Link>
                                    <div 
                                        className={
                                            "absolute left-2 top-12 p-3 py-5 bg-white z-10 rounded-md shadow-lg space-y-3 w-[320px] " +
                                            (isProgramHovered ? '' : ' hidden ')
                                        }
                                    >
                                        <div>
                                            {programs.map(p => (
                                                <Link to={'/paket-bimbel/'+p.idProgram}>
                                                    <button
                                                        className="w-full p-2 border border-transparent hover:border-blue-300 hover:shadow-lg rounded-md flex space-x-3"
                                                        onClick={() => {
                                                            setIsProgramHovered(false)
                                                        }}
                                                    >
                                                        <div style={{ minWidth: "40px" }}>
                                                            <img src={p.iconBase64} width="40px"/>
                                                        </div>
                                                        <div className="flex-1">
                                                            <div
                                                                className="text-blue-900 text-left font-bold"
                                                                style={{
                                                                    fontSize: '15px'
                                                                }}
                                                            >
                                                                {p.namaProgram}
                                                            </div>
                                                            <div 
                                                                className="text-gray-500 text-left"
                                                                style={{
                                                                    fontSize: '14px'
                                                                }}
                                                                dangerouslySetInnerHTML={{ __html: p.description }}
                                                            />
                                                        </div>
                                                    </button>
                                                </Link>
                                            ))}
                                        </div>
                                        <div
                                            className="text-blue-900 px-2"
                                            style={{
                                                fontSize: '14px'
                                            }}
                                        >
                                            <div className="hover:underline">
                                                <Link to={'/paket-bimbel'}>Lihat semua paket</Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="hover:text-blue-900">
                                    <Link to="/about-us">Tentang Kami</Link>
                                </li>
                                <li className="hover:text-blue-900">
                                    <Link to="/contact-us">Kontak Kami</Link>
                                </li>
                                <li className="hover:text-blue-900">
                                    <Link to="/um-registration">
                                        Pendaftaran <b>Taklukan UM 2023</b>
                                    </Link>
                                </li>
                                <li className="hover:text-blue-900 flex space-x-2">
                                    <GiNotebook className="w-6 h-6"/>
                                    <a href="http://cbt.bimbelalc.id">Try Out</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div 
                        className="hidden lg:flex pr-4 text-white"
                        style={{
                            fontFamily: "Signika"
                        }}
                    >
                        {
                            isLoggedIn ?
                                (<div>
                                    <Link to="/member">
                                        <button 
                                            className="px-08 py-1 px-4 rounded-full bg-transparent border-2 border-white font-bold hover:bg-white hover:text-blue-500"
                                        >
                                            Member
                                        </button>
                                    </Link>    
                                </div>)
                                :
                                (<>
                                <Link to="/register">
                                    <button 
                                        className="px-08 py-1 px-4 rounded-full bg-transparent font-bold"
                                    >
                                        Daftar
                                    </button>
                                </Link>
                                <Link to="/login">
                                    <button 
                                        className="px-08 py-1 px-4 rounded-full bg-transparent border-transparent font-bold hover:text-gray-400 rounded-full bg-white"
                                        style={{
                                            color: '#0079C4'
                                        }}
                                    >
                                        Login
                                    </button>
                                </Link>
                                </>)
                        }
                    </div>
                    <div className="lg:hidden mr-4 h-12 w-12 rounded-full bg-white flex items-center justify-center" onClick={handleClick}>
                        {!dropdownMenu ? <AiOutlineMenu className="w-8 " /> : <XIcon className="w-6" />}
                    </div>
                </div>
                <div className={!dropdownMenu ? 'hidden' : "absolute px-8 py-5 w-full"}>
                    <ul 
                        className={"bg-white w-full px-5 py-5 rounded-lg shadow-lg font-bold text-blue-700 flex flex-col space-y-5 justify-between overflow-y-auto"}
                        style={{
                            fontFamily: "Nunito",
                            height: "400px"
                        }}
                    >
                        <div
                            className="flex flex-col"
                        >
                            <Link to="#">
                                <li className="flex items-center justify-between" onClick={() => setIsProgramExpanded(!isProgramExpanded)}>
                                    <div className="flex space-x-2 items-center">
                                        <GiNotebook className="w-6 h-6"/>
                                        <span>Program Belajar</span>
                                    </div>
                                    <div>
                                        {
                                            isProgramExpanded ?
                                                (<BiChevronUp className="w-7 h-7"/>)
                                                :
                                                (<BiChevronDown className="w-7 h-7"/>)
                                        }
                                    </div>
                                </li>
                            </Link>
                            { isProgramExpanded && (
                                <div 
                                    className="py-2 pb-4 pl-10"
                                >
                                    {programs.map(p => (
                                        <Link to={'/paket-bimbel/'+p.idProgram}>
                                            <button
                                                className="w-full p-2 border border-transparent hover:border-blue-300 hover:shadow-lg rounded-md flex space-x-3"
                                                onClick={handleClick}
                                            >
                                                <div style={{ minWidth: "40px" }}>
                                                    <img src={p.iconBase64} width="40px"/>
                                                </div>
                                                <div className="flex-1">
                                                    <div
                                                        className="text-blue-900 text-left font-bold"
                                                        style={{
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        {p.namaProgram}
                                                    </div>
                                                    <div 
                                                        className="text-gray-500 text-left"
                                                        style={{
                                                            fontSize: '13px'
                                                        }}
                                                        dangerouslySetInnerHTML={{ __html: p.description }}
                                                    />
                                                </div>
                                            </button>
                                        </Link>
                                    ))}
                                </div>
                            )}
                            <Link to="/contact-us">
                                <li className="flex space-x-3 items-center">
                                    <BsFillTelephoneFill className="w-5 h-5"/>
                                    <span onClick={handleClose}>Kontak Kami</span>
                                </li>
                            </Link>
                            <Link to="/um-registration">
                                <li className="flex space-x-3 items-center">
                                    <GiNotebook className="w-5 h-5"/>
                                    <span onClick={handleClose}>
                                    Pendaftaran <b>Taklukan UM 2023</b>
                                    </span>
                                </li>
                            </Link>
                            <li className="flex space-x-3 items-center">
                                <GiNotebook className="w-6 h-6"/>
                                <a href="http://cbt.bimbelalc.id">Try Out</a>
                            </li>
                        </div>
                        
                        <div className="flex flex-col my-4">
                            {
                                isLoggedIn ?
                                    (<div>
                                        <Link to="/member">
                                            <button className="px-8 py-1 w-full border border-blue-600 rounded-full">
                                                Member
                                            </button>
                                        </Link>
                                    </div>)
                                    :
                                    (
                                        <div className="flex flex-col space-y-3">
                                            <Link to="/login">
                                                <button className="px-8 py-1 w-full border border-blue-600 rounded-full">
                                                    Login
                                                </button>
                                            </Link>
                                            <Link to="/register">
                                                <button className="px-8 py-1 w-full border border-blue-600 rounded-full">
                                                    Daftar
                                                </button>
                                            </Link>
                                        </div>
                                    )
                            }
                        </div>
                    </ul>
                </div>
                {/* <ul className={!dropdownMenu ? 'hidden' : "absolute bg-yellow-500 w-full px-8"}>
                    <li className="border-b-2 border-slate-300 w-full text-white">
                        <Link to="/">
                            <span onClick={handleClose}>Home</span>
                        </Link>
                    </li>
                    <li className="border-b-2 border-slate-300 w-full text-white">
                        <Link to="/service">
                            <span onClick={handleClose}>Program Belajar</span>
                        </Link>
                    </li>
                    <li className="border-b-2 border-slate-300 w-full text-white">
                        <Link to="/post">
                            <span onClick={handleClose}>About Us</span>
                        </Link>
                    </li>
                    <div className="flex flex-col my-4">
                        {
                            isLoggedIn ?
                                (<div>Logout</div>)
                                :
                                (
                                    <>
                                    <Link to="/login">
                                        <button className="bg-transparent text-slate-100 px-8 py-3 mb-4 w-full">Login</button>
                                    </Link>
                                    <Link to="/register">
                                        <button className="px-8 py-3 w-full">Daftar</button>
                                    </Link>
                                    </>
                                )
                        }
                    </div>
                </ul> */}
            </div>

        </div>
    );
}

export default Navbar;
