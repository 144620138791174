import { useEffect, useState } from "react"
import { AiFillEye, AiFillEyeInvisible, AiFillMinusCircle } from "react-icons/ai"
import { BiEdit } from "react-icons/bi"
import { BsChevronLeft } from 'react-icons/bs'
import { FaTrash, FaUser } from "react-icons/fa"
import { FiUserPlus } from "react-icons/fi"
import { RiLoader5Fill } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { deleteAnak, getAnak, getKelasIdLabel, getProgramIdLabel, getSekolahIdLabel, registerAnak, updateAnak } from "../../repository/User"
import { handleResponse200 } from "../../utils/HttpUtils"
import { notifyError } from "../../utils/Notification"
import Select from "react-select"

import { IoFileTrayFull } from "react-icons/io5"

const UserAnakSimplified = ({
    id,
    nama,
    onEdit,
    onDelete,
    onViewPahamifyEvaluation
}) => {
    return (
        <div
            className="p-2 relative flex items-center justify-between"
            style={{ fontFamily: 'Nunito' }}
        >
            <div className="flex items-center space-x-2">
                <div className="rounded-mg bg-blue-100 p-2 rounded-md shadow-lg">
                    <FaUser className="h-5 w-5"/>
                </div>
                <div>
                    <div
                        className="font-bold"
                        style={{
                            fontSize: '14px',
                            fontFamily: 'Nunito'
                        }}
                    >
                        {id}
                    </div>
                    <div
                        className="font-bold"
                        style={{
                            fontSize: '14px',
                            fontFamily: 'Nunito'
                        }}
                    >
                        {nama}
                    </div>
                </div>
            </div>
            <div className="flex space-x-2">
                <button
                    className="p-2 rounded-full bg-blue-100 shadow-lg"
                    onClick={onViewPahamifyEvaluation}
                >
                    <IoFileTrayFull className="h-5 w-5"/>
                </button>
                <button
                    className="p-2 rounded-full bg-blue-100 shadow-lg"
                    onClick={onEdit}
                >
                    <BiEdit className="h-5 w-5"/>
                </button>
                <button
                    className="p-2 rounded-full bg-blue-100 shadow-lg"
                    onClick={onDelete}
                >
                    <FaTrash className="text-red-700 h-5 w-5"/>
                </button>
            </div>
        </div>
    )
}

const UserAnakForm = ({
    isUpdate,
    selectedData,
    onBack
}) => {

    const navigate = useNavigate()

    const [form, setForm] = useState({
        id: null,
        nama: '',
        asalSekolahLainnya: '',
        passwordShown: false
    })

    const [kelasOpts, setKelasOpts] = useState({
        selectedOpt: null,
        data: [],
        isLoad: false
    })

    const [programOpts, setProgramOpts] = useState({
        selectedOpt: null,
        data: [],
        isLoad: false
    })

    const [sekolahOpts, setSekolahOpts] = useState({
        selectedOpt: null,
        data: [],
        isLoad: false
    })

    const [jenisKelaminOpts, setJenisKelaminOpts] = useState({
        selectedOpt: null,
        data: [ { label: 'laki-laki', value: 'laki-laki' }, { label: 'perempuan', value: 'perempuan' },  ],
        isLoad: false
    })

    const [isSaving, setIsSaving] = useState(false) 

    function onSelectKelasOpts() {
        setKelasOpts({ ...kelasOpts, isLoad: true })

        getKelasIdLabel()
            .then(response => {
                if (response.success) {
                    const data = response.payload.map(d => ({ label: d.label, value: d.id }))
                    setKelasOpts({ ...kelasOpts, data: data, isLoad: false, rawData: response.payload })
                }
                else {
                    setKelasOpts({ ...kelasOpts, isLoad: false })
                }
            })
            .catch(error => {
                setKelasOpts({ ...kelasOpts, isLoad: false })
                if (error.response != undefined && error.response != null) {
                    if (error.response.status == 401) {
                    notifyError("Forbidden")
                    }
                    else {
                    notifyError("Server Error")
                    }
                }
            })
    }

    useEffect(() => {
        if (isUpdate && selectedData) {
            setForm({
                ...form,
                id: selectedData.idAnak,
                nama: selectedData.nama,
                asalSekolahLainnya: selectedData.sekolahLainnya
            })

            setKelasOpts({
                ...kelasOpts,
                selectedOpt: { label: selectedData.kelas.label, value: selectedData.kelas.id }
            })

            setProgramOpts({
                ...programOpts,
                selectedOpt: { label: selectedData.program.label, value: selectedData.program.id }
            })


            setSekolahOpts({
                ...sekolahOpts,
                selectedOpt: selectedData.sekolah ? { label: selectedData.sekolah.label, value: selectedData.sekolah.id } : { label: 'Lainnya...', value: 'lainnya' }
            })

            setJenisKelaminOpts({
                ...jenisKelaminOpts,
                selectedOpt: { label: selectedData.jenisKelamin, value: selectedData.jenisKelamin }
            })
        }
    }, [])

    function onSelectProgramOpts(idKelas) {
        setProgramOpts({ ...programOpts, isLoad: true })
    
        getProgramIdLabel(idKelas)
            .then(response => {
                if (response.success) {
                const data = response.payload.map(d => ({ label: d.label, value: d.id }))
                setProgramOpts({ ...programOpts, data: data, isLoad: false, rawData: response.payload })
                }
                else {
                setProgramOpts({ ...programOpts, isLoad: false })
                }
            })
            .catch(error => {
                setProgramOpts({ ...programOpts, isLoad: false })
                if (error.response != undefined && error.response != null) {
                if (error.response.status == 401) {
                    notifyError("Forbidden")
                }
                else {
                    notifyError("Server Error")
                }
                }
            })
    }

    function onSelectSekolahOpts() {
        setSekolahOpts({ ...sekolahOpts, isLoad: true })
    
        getSekolahIdLabel()
            .then(response => {
                if (response.success) {
                    const data = response.payload.map(d => ({ label: d.label, value: d.id })).concat([{ label: 'Lainnya...', value: 'lainnya' }])
                    setSekolahOpts({ ...sekolahOpts, data: data, isLoad: false, rawData: response.payload })
                }
                else {
                    setSekolahOpts({ ...sekolahOpts, isLoad: false })
                }
            })
            .catch(error => {
                setSekolahOpts({ ...sekolahOpts, isLoad: false })
                if (error.response != undefined && error.response != null) {
                    if (error.response.status == 401) {
                        notifyError("Forbidden")
                    }
                    else {
                        notifyError("Server Error")
                    }
                }
            })
    }

    function onSave(
        nama,
        idKelas,
        idProgram,
        idSekolah,
        namaAsalSekolah,
        jenisKelamin
    ) {

        const payload = {
            idAnak : form.id,
            namaAnak : nama,
            idKelas : idKelas,
            idProgram : idProgram,
            idSekolah : idSekolah,
            asalSekolahLainnya : namaAsalSekolah,
            jenisKelamin : jenisKelamin
        }

        setIsSaving(true)

        if (isUpdate) {
            updateAnak(payload)
                .then(res => {
                    handleResponse200({
                        httpResponse: res,
                        onSuccess: payload => {
                            onBack()
                        },
                        onRecovFailure: errors => errors.forEach(err => {
                            notifyError(err)
                        }),
                        onUnAuth: error => {
                            navigate('/login')
                        },
                        onTechnicalError: errors => errors.forEach(err => {
                            notifyError(err)
                        })
                    })
                })
                .catch(error => {
                })
                .finally(() => { setIsSaving(false) })
        }
        else {
            registerAnak(payload)
                .then(res => {
                    handleResponse200({
                        httpResponse: res,
                        onSuccess: payload => {
                            onBack()
                        },
                        onRecovFailure: errors => errors.forEach(err => {
                            notifyError(err)
                        }),
                        onUnAuth: error => {
                            navigate('/login')
                        },
                        onTechnicalError: errors => errors.forEach(err => {
                            notifyError(err)
                        })
                    })
                })
                .catch(error => {
                })
                .finally(() => { setIsSaving(false) })
        }

    }


    return (
        <div
            className="px-5 space-y-5"
        >
            <div
                className="font-bold"
            >
                <button
                    className="p-1 rounded-md hover:bg-blue-700 hover:text-white border border-gray-300 flex items-center"
                    onClick={onBack}
                >
                    <BsChevronLeft className="h-5 w-5 mr-1"/>
                </button>
            </div>
            { isUpdate && (
                <div className='space-y-1'>
                    <div style={{ fontSize: '13px' }}>ID</div>
                    <div>
                        <input 
                            className={
                                'w-full py-1 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold '
                            }
                            value={form.id}
                            readOnly
                        />
                    </div>
                </div>
            )}
            <div className='space-y-1'>
                <div style={{ fontSize: '13px' }}>Nama</div>
                <div>
                    <input 
                        className={
                            'w-full py-1 px-2 border-2 rounded-md outline-none ' +
                            (form.nama && form.nama !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                        }
                        style={{ fontFamily: 'Nunito' }}
                        value={form.nama}
                        onChange={e => setForm({ ...form, nama: e.target.value })}
                        disabled={isSaving}
                    />
                </div>
            </div>
            <div className='space-y-1'>
                <div style={{ fontSize: '13px' }}>Kelas</div>
                <div>
                    <Select
                        value={kelasOpts.selectedOpt}
                        options={kelasOpts.data}
                        isLoading={kelasOpts.isLoad}
                        menuPlacement="auto"
                        className="w-full border border-gray-200 rounded-md"
                        onFocus={onSelectKelasOpts}
                        onChange={(value) => {
                            setKelasOpts({ ...kelasOpts, selectedOpt: value })
                            setProgramOpts({ ...programOpts, selectedOpt: null })
                        }}
                        isDisabled={isSaving}
                    />
                </div>
            </div>
            <div
                className="space-y-2"
            >
                <div
                    style={{ fontSize: '13px' }}
                >
                    Program
                </div>
                <div>
                    <Select
                        value={programOpts.selectedOpt}
                        options={programOpts.data}
                        isLoading={programOpts.isLoad}
                        menuPlacement="auto"
                        className="w-full border border-gray-200 rounded-md"
                        onFocus={() => {
                            if (kelasOpts.selectedOpt) {
                            onSelectProgramOpts(kelasOpts.selectedOpt.value)
                            }
                        }}
                        onChange={(value) => {
                            setProgramOpts({ ...programOpts, selectedOpt: value })
                        }}
                        isDisabled={isSaving}
                    />
                </div>
            </div>
            <div
                className="space-y-2"
            >
                <div
                    style={{ fontSize: '13px' }}
                >
                    Asal Sekolah
                </div>
                <div>
                    <Select
                        value={sekolahOpts.selectedOpt}
                        options={sekolahOpts.data}
                        isLoading={sekolahOpts.isLoad}
                        menuPlacement="auto"
                        className="w-full border border-gray-200 rounded-md"
                        onFocus={onSelectSekolahOpts}
                        onChange={(value) => {
                            setSekolahOpts({ ...sekolahOpts, selectedOpt: value })
                        }}
                        isDisabled={isSaving}
                    />
                </div>
            </div>
            {sekolahOpts.selectedOpt && sekolahOpts.selectedOpt.value === 'lainnya' && (
                <div
                    className="space-y-2"
                >
                    <div
                        style={{ fontSize: '13px' }}
                    >
                        Nama Asal Sekolah
                    </div>
                    <div>
                        <input 
                            className={
                                'w-full py-1 px-2 border-2 rounded-md outline-none ' +
                                (form.asalSekolahLainnya && form.asalSekolahLainnya !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                            }
                            style={{ fontFamily: 'Nunito' }}
                            value={form.asalSekolahLainnya}
                            onChange={e => setForm({ ...form, asalSekolahLainnya: e.target.value })}
                            disabled={isSaving}
                        />
                    </div>
                </div>
            )}
            <div
                className="space-y-2"
            >
                <div
                    style={{ fontSize: '13px' }}
                >
                    Jenis Kelamin
                </div>
                <div>
                    <Select
                        value={jenisKelaminOpts.selectedOpt}
                        options={jenisKelaminOpts.data}
                        isLoading={jenisKelaminOpts.isLoad}
                        menuPlacement="auto"
                        className="w-full border border-gray-200 rounded-md"
                        onChange={(value) => {
                            setJenisKelaminOpts({ ...jenisKelaminOpts, selectedOpt: value })
                        }}
                        isDisabled={isSaving}
                    />
                </div>
            </div>
            <div
                className="flex justify-center pt-5"
            >
                <button
                    className="rounded-md bg-blue-500 hover:bg-blue-700 hover:shadow-lg text-white py-1 px-8"
                    style={{ fontSize: '14px' }}
                    onClick={() => {

                        if (!kelasOpts.selectedOpt || !programOpts.selectedOpt || !sekolahOpts.selectedOpt) {
                            return notifyError('Form belum diisi')
                        }

                        if (sekolahOpts.selectedOpt.value === 'lainnya' && form.asalSekolahLainnya === '') {
                            return notifyError('Form belum diisi')
                        }

                        onSave(
                            form.nama,
                            kelasOpts.selectedOpt.value,
                            programOpts.selectedOpt.value,
                            sekolahOpts.selectedOpt.value === 'lainnya' ? null : sekolahOpts.selectedOpt.value,
                            form.asalSekolahLainnya,
                            jenisKelaminOpts.selectedOpt.value
                        )
                    }}
                    isDisabled={isSaving}
                >
                    {
                        isSaving ?
                            <RiLoader5Fill className={"h-6 w-6 text-white animate-spin"} />
                            :
                            'Simpan'
                    }
                </button>
            </div>
        </div>
    )
}

const addNewData = (setModel) => {
    setModel(model => ({ ...model, formView: { ...model.formView, shown: true, mode: 'add', selectedData: null } }))
} 

const updateData = (setModel, data) => {
    setModel(model => ({ ...model, formView: { ...model.formView, shown: true, mode: 'update', selectedData: data } }))
}

const closeForm = (setModel) => {
    setModel(model => ({ ...model, formView: { ...model.formView, shown: false } }))
}



const UserAnak = () => {

    const navigate = useNavigate()

    const { member } = useSelector(state => state.global)

    const [isGettingAnak, setIsGettingAnak] = useState(false)

    const [anak, setAnak] = useState([])

    const [model, setModel] = useState({
        formView: {
            shown: false,
            mode: null, // add or update
            selectedData: null
        }
    })

    const [isDeleting, setIsDeleting] = useState(false)
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
    const [selectedAnak, setSelectedAnak] = useState(null)

    useEffect(() => {
        if (!member || model.formView.shown) return;

        onGettingAnak()
    }, [member, model.formView])

    function onGettingAnak() {
        setIsGettingAnak(true)
        
        getAnak()
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setAnak(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(() => {})
            .finally(() => {
                setIsGettingAnak(false)
            })
    }

    function showDataAnak(anakList) {
        if (anakList.length < 1) {
            return (
                <div className="flex justify-center py-2">
                    Tidak ada data
                </div>
            )
        }

        return anakList.map(d => (
            <UserAnakSimplified
                key={d.idAnak}
                id={d.idAnak}
                nama={d.nama}
                onViewPahamifyEvaluation={() => navigate('/member/anak-evaluasi/' + d.idAnak)}
                onEdit={() => updateData(setModel, d)}
                onDelete={() => {
                    setIsOpenDeleteDialog(true)
                    setSelectedAnak(d)
                }}
            />
        ))
    }

    const LoadBox = () => {
        return (
            <>
                <div
                    className="fixed z-90 top-0 left-0 w-screen h-screen bg-black opacity-50"
                    style={{
                        zIndex: 90
                    }}
                >
                </div>
                <div className="absolute top-0 left-0 h-full w-full">
                    <div className="flex justify-center items-center w-full h-full">
                        <div 
                            className="bg-white p-10 z-90 rounded-lg space-y-5"
                            style={{
                                zIndex: 90
                            }}
                        >
                            <div className="flex justify-center py-5">
                                <RiLoader5Fill className={"h-10 w-10 text-blue-500 animate-spin"} />
                            </div>
                            <div
                                className="font-bold"
                                style={{
                                    fontFamily: 'Nunito'
                                }}
                            >
                                Sedang memproses. Mohon tunggu.
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const DeleteDialogBox = ({
        selectedAnak,
        onClose,
        onDelete
    }) => {
        return (
            <>
                <div
                    className="fixed z-90 top-0 left-0 w-screen h-screen bg-black opacity-50"
                    style={{
                        zIndex: 90
                    }}
                >
                </div>
                <div className="absolute top-0 left-0 h-full w-full">
                    <div className="flex justify-center items-center w-full h-full">
                        <div 
                            className="bg-white p-10 rounded-lg space-y-5 "
                            style={{
                                zIndex: '99'
                            }}
                        >
                            <div
                                className="font-bold"
                                style={{
                                    fontFamily: 'Nunito'
                                }}
                            >
                                Anda akan menghapus data ini. Lanjutkan?
                            </div>
                            <div className="flex justify-between px-10">
                                <button 
                                    className="py-1 px-8 border border-gray-400 rounded-md shadow-lg"
                                    onClick={onClose}
                                >
                                    Batal
                                </button>
                                <button 
                                    className="py-1 px-8 border border-red-400 rounded-md shadow-lg hover:bg-red-500 hover:text-white"
                                    onClick={onDelete}
                                >
                                    Iya
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function onDelete(idAnak) {
        setIsOpenDeleteDialog(false)
        setIsDeleting(true)

        deleteAnak(idAnak)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        return null
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .then(() => {

                getAnak()
                    .then(res => {
                        handleResponse200({
                            httpResponse: res,
                            onSuccess: payload => {
                                setAnak(payload)
                            },
                            onRecovFailure: errors => errors.forEach(err => {
                                notifyError(err)
                            }),
                            onUnAuth: error => {
                                navigate('/login')
                            },
                            onTechnicalError: errors => errors.forEach(err => {
                                notifyError(err)
                            })
                        })
                    })
                    .catch(() => {})
                    .finally(() => {
                    })

            })
            .catch(error => {})
            .finally(() => setIsDeleting(false))
    }



    return (
        <div
            className='w-full h-screen bg-second-color flex justify-center'
        >
            <div
                className='w-[450px] h-full'
            >
                <div
                    className='w-full h-full bg-white rounded-lg'
                >
                    <div 
                        className="flex justify-between px-6 font-bold bg-blue-800 text-white rounded-tl-lg rounded-tr-lg rounded-bl-2xl rounded-br-2xl pt-12 pb-10"
                        style={{  
                            fontFamily: 'Nunito',
                            height: "200px"
                        }}
                    >
                        <div className="">
                            <button
                                onClick={() => navigate('/member')}
                            >
                                <BsChevronLeft className="h-6 w-6"/>
                            </button>
                        </div>
                        <div
                            style={{
                                fontSize: '20px',
                            }}
                        >
                            Data Anak
                        </div>
                        <div/>
                    </div>
                    <div 
                        className="px-6 "
                    >
                        <div
                            className="p-5 bg-white rounded-lg shadow-lg z-10"
                            style={{
                                marginTop: '-50px'
                            }}
                        >

                            <div
                                className="space-y-3"
                            >
                                {isOpenDeleteDialog && (
                                    <DeleteDialogBox 
                                        selectedAnak={selectedAnak}
                                        onClose={() => {
                                            setIsOpenDeleteDialog(false)
                                            setSelectedAnak(null)
                                        }}
                                        onDelete={() => onDelete(selectedAnak.idAnak)}
                                    />  
                                )}
                                {isDeleting && <LoadBox />}
                                {
                                    model.formView.shown ?
                                        <UserAnakForm
                                            isUpdate={model.formView.mode === 'update'}
                                            selectedData={model.formView.selectedData}
                                            onBack={() => closeForm(setModel)}
                                        />
                                        :
                                        (
                                            <>
                                            <div
                                                className="flex justify-end"
                                            >
                                                <button
                                                    className="flex items-center bg-blue-500 text-white p-2 px-3  rounded-md hover:bg-blue-700 hover:shadow-lg"
                                                    style={{ fontSize: '13px' }}
                                                    onClick={() => addNewData(setModel)}
                                                >
                                                    <FiUserPlus className="h-5 w-5 mr-2"/>
                                                    <label 
                                                        className="font-bold"

                                                    >
                                                        Tambah data
                                                    </label>
                                                </button>
                                            </div>
                                            <div
                                                className="space-y-3"
                                            >
                                                {
                                                    isGettingAnak ?
                                                        (
                                                        <div className="flex justify-center">
                                                            <RiLoader5Fill className={"h-6 w-6 text-blue-500 animate-spin"} />
                                                        </div>
                                                        )
                                                        :
                                                        showDataAnak(anak)              
                                                }
                                            </div>
                                            </>
                                        )
                                }
                            </div>

                        </div>
                    </div>
                    <div className="py-10">

                    </div>
                </div>
            </div>
        </div>
    )

}

export default UserAnak