import React from 'react'
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import About from "../components/About";
import Testimony from "../components/Testimony";
import ProgramBelajarBox from "../components/ProgramBelajarBox"
// import Support from "../components/Support";
import AllInOne from "../components/AllInOne";
import Footer from "../components/Footer";
import AdsBanner from '../components/AdsBanner';
import Teachers from '../components/Teachers';
import Programs from '../components/Programs';
import { HomeTopBanner } from '../components/HomeTopBanner';
import { HomeSecondBanner } from '../components/HomeSecondBanner';
import { InfoBanner } from '../components/InfoBanner';

const Homepage = () => {

    return (
        <>
            <div
                className='w-full flex justify-center'
            >
                <Navbar />
                <div className='w-full'>
                    <HomeTopBanner />
                    <InfoBanner />
                    <div className='pb-32 bg-second-color' style={{ marginTop: '-50px' }}>
                        <ProgramBelajarBox />
                    </div>
                    <Testimony />
                    {/* <HomeSecondBanner /> */}
                    {/* <Testimony />
                    <AllInOne /> */}
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Homepage