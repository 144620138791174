import React, { useEffect, useState } from 'react'
import waveBottom from '../assets/waveBottom.svg'
import "swiper/css/bundle"

import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from 'swiper'

// Image
import studentPicture from '../assets/collegeStudent.jpg'
import { getTestimony } from '../repository/User';
import { handleResponse200 } from '../utils/HttpUtils';
import { notifyError } from '../utils/Notification';
import { useSelector } from 'react-redux';
import { isPhone } from '../utils/PlatformHelper';


const Testimony = () => {

    SwiperCore.use([Autoplay]);

    const { screenSize } = useSelector(state => state.global)

    const [studentData, setStudentData] = useState([])

    useEffect(() => {
        getTestimony()
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        console.log(payload)
                        setStudentData(payload.map(p => ({
                            studentID: p.nama,
                            studentName: p.name,
                            studentTitle: p.sekolah,
                            studentMessage: p.testimony,
                            studentImage : p.imageUrl
                        })))
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        notifyError('Forbidden. Please Login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {

            })
            .finally(() => {})
    }, [])

    return (
        <>
        <div className='w-full bg-second-color py-10'>
            <div className=''>
                <div className='max-w-[1240px] mx-auto'>
                    <div className='text-center'>
                        <h2 
                            className='font-bold text-white'
                            style={{
                                fontSize: "32px",
                                fontFamily: "Nunito"
                            }}
                        >
                            Kata mereka tentang Bimbel ALC
                        </h2>
                    </div>
                    <div className=''>
                        <Swiper
                            slidesPerView={isPhone(screenSize.width) ? 1 : 3}
                            breakpoints={{
                                640: {
                                    width: 640,
                                    slidesPerView: 1,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                                1024: {
                                    width: 1024,
                                    slidesPerView: 3,
                                }
                            }}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            navigation
                            loop={true}
                            spaceBetween={30}
                            grabCursor={true}
                            pagination={{ clickable: true }}
                            modules={[Pagination, Navigation]}
                            className="grid grid-cols-1 lg:grid-cols-3 relative lg:gap-x-8 lg:gap-y-16 px-4 pt-12 sm:pt-20 text-black"
                        >
                            {studentData.map(student => (
                                <SwiperSlide key={student.studentID}>
                                    <div className='bg-white rounded-xl shadow-2xl pb-2 mt-16 mx-2 mb-10'>
                                        <div className='p-8'>
                                            <img 
                                                src={student.studentImage} 
                                                className='w-24 h-24  p-2 rounded-full mt-[-4rem]' alt="/" 
                                                style={{
                                                    backgroundColor: 'rgb(187, 244, 36)'
                                                }}
                                            />
                                            <div 
                                                className='pt-5'
                                                style={{
                                                    fontFamily: 'Nunito'
                                                }}
                                            >
                                                <div className='mb-6' style={{ height: '80px' }}>
                                                    <h3 
                                                        className='font-bold text-2xl'
                                                    >
                                                        {student.studentName}
                                                    </h3>
                                                    <p className='text-lg'>{student.studentTitle}</p>
                                                </div>
                                                <div className='h-[200px] overflow-y-scroll'>
                                                    <div 
                                                        className="text-left"
                                                        style={{
                                                            fontSize: '13px'
                                                        }}
                                                        dangerouslySetInnerHTML={{ __html: student.studentMessage }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                </div>

            </div>
        </div>
        </>
    )
}

export default Testimony