import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Homepage from "./pages/Homepage";
import Login from "./pages/Login";
import Post from "./pages/Post";
import Register from "./pages/Register";
import ProgramBimbel from "./pages/Program/ProgramBimbel";
import ProgramUTBK from "./pages/Program/ProgramUTBK";
import PaymentMethod from "./pages/Payment/PaymentMethod";
import PaymentChannel from "./pages/Payment/PaymentChannel";
import PaymentInvoice from "./pages/Payment/PaymentInvoice";
import PaymentDetail from "./pages/Payment/PaymentDetail";
import TransactionHistory from "./pages/User/TransactionHistory";
import UserPackage from "./pages/User/UserPackage";
import ProgramBelajar from "./pages/ProgramBelajar";
import Whatsapp from "./components/Whatsapp";
import UserPage from "./pages/User/UsePage";
import UserProfile from "./components/User/UserProfile";
import UserAnak from "./components/User/UserAnak";
import UserTransaction from "./components/User/UserTransaction";
import UserPaketBimbel from "./components/User/UserPaketBimbel";

import { useEffect, useState } from "react";
import { checkIsAuthenticated, getSystemProfile } from "./repository/User";
import { useDispatch, useSelector } from "react-redux";

import { setMember, setPrograms, setScreenSize, setSystemProfile } from "./app/GlobalSlice";
import { Toaster } from "react-hot-toast";
import UserPassword from "./components/User/UserPassword";
import PaketBimbel from "./pages/User/PaketBimbel";
import Checkout from "./pages/Checkout";
import { getPublicProgram } from "./repository/PaketBimbel";
import { handleResponse200 } from "./utils/HttpUtils";
import { notifyError } from "./utils/Notification";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import EmailVerification from "./pages/EmailVerification";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import UserCicilan from "./components/User/UserCicilan";
import UserAnakPahamify from "./components/User/UserAnakPahamify";
import UserAnakPresensi from "./components/User/UserAnakPresensi";
import UserAnakPresensiDetail from "./components/User/UserAnakPresensiDetail";
import UmRegistration from "./pages/UmRegistration";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function App() {

  const dispatch = useDispatch()

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  useEffect(() => {
      checkIsAuthenticated({
        onStartChecking: () => {},
        onFinishChecking: () => {},
        onAuthenticated: payload => {
          const member = {
            id: payload.idOrangtua,
            nama: payload.nama,
            email: payload.email,
            hp: payload.hp,
            provinsi: payload.provinsi,
            kabupaten: payload.kabupaten,
            kecamatan: payload.kecamatan,
            fullAddress: payload.fullAddress
          }

          dispatch(setMember(member))
        },
        onUnAuthenticated: () => {}
      })

      getPublicProgram()
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
                dispatch(setPrograms(payload))
            },
            onRecovFailure: errors => errors.forEach(err => {
                notifyError(err)
            }),
            onUnAuth: error => {
                // notifyError('Forbidden. Please Login')
            },
            onTechnicalError: errors => errors.forEach(err => {
                notifyError(err)
            })
          })
        })
        .catch(error => {

        })
        .finally(() => {  })

      getSystemProfile()
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
                dispatch(setSystemProfile(payload))
            },
            onRecovFailure: errors => errors.forEach(err => {
                notifyError(err)
            }),
            onUnAuth: error => {
                // notifyError('Forbidden. Please Login')
            },
            onTechnicalError: errors => errors.forEach(err => {
                notifyError(err)
            })
          })
        })
        .catch(error => {})
        .finally(() => {})

  }, [])

  useEffect(() => {
    dispatch(setScreenSize(windowDimensions))
  }, [windowDimensions])

  return (
    <>
    <Toaster
      position="top-center"
    />
    <Routes>
      <Route path="/*">
        <Route index element={<Homepage />} />
      </Route>
      <Route path="/service" element={<ProgramBelajar />} />
      <Route path="/post" element={<Post />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/programBimbel" element={<ProgramBimbel />} />
      <Route path="/programUTBK" element={<ProgramUTBK />} />
      <Route path="/paymentMethod" element={<PaymentMethod />} />
      <Route path="/paymentChannel" element={<PaymentChannel />} />
      <Route path="/paymentInvoice" element={<PaymentInvoice />} />
      <Route path="/paymentDetail" element={<PaymentDetail />} />
      <Route path="/transactionHistory" element={<TransactionHistory />} />
      <Route path="/member">
        <Route path="" element={<UserPage />} />
        <Route path="profil" element={<UserProfile />}/>
        <Route path="anak" element={<UserAnak />}/>
        <Route path="anak-presensi" element={<UserAnakPresensi />}/>
        <Route path="anak-presensi-detail/:idanak" element={<UserAnakPresensiDetail />}/>
        <Route path="anak-evaluasi/:idanak" element={<UserAnakPahamify />}/>
        <Route path="transaksi" element={<UserTransaction />}/>
        <Route path="cicilan" element={<UserCicilan />}/>
        <Route path="paket" element={<UserPaketBimbel />}/>
        <Route path="password" element={<UserPassword />}/>
      </Route>
      <Route path="/paket-bimbel/:idProgram" element={<PaketBimbel />}/>
      <Route path="/paket-bimbel" element={<PaketBimbel />}/>
      <Route path="/userPackage" element={<UserPackage />} />
      <Route path="/goToWhatsapp" element={<Whatsapp />} />
      <Route path="/checkout/:paketId" element={<Checkout />}/>
      <Route path="/about-us" element={<AboutUs />}/>
      <Route path="/contact-us" element={<Contact />}/>
      <Route path="/um-registration" element={<UmRegistration />}/>
      <Route path="/email-verification/:memberId/:token" element={<EmailVerification />}/>
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/reset-password/:memberId/:token" element={<ResetPassword />}/>
    </Routes>
    </>
  );
}

export default App;
