import { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { RiLoader5Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getCicilan, getCicilanTransactions, getTransactionsInstructions } from "../../repository/User";
import { handleResponse200 } from "../../utils/HttpUtils";
import { notifyError } from "../../utils/Notification";
import { format } from "date-fns"

const UserTransactionInstructions = ({
    instructions,
    onBack
}) => {

    const Box = ({ instruction }) => {
        return (
            <div>
                <div 
                    className='font-bold uppercase p-2 rounded-md shadow-lg border bg-blue-600 text-white' 
                    style={{ fontFamily: 'Nunito' }}
                >
                    Via {instruction.title}
                </div>
                <div className='pl-5 pt-2'>
                    <ul className='list-decimal'>
                        { instruction.steps.map(s => (
                            <li className='py-1' style={{ fontSize: '15px' }} >{s}</li>
                        )) }
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className='space-y-5'>
            <div className='flex'>
                <button 
                    className='py-2 px-5 rounded-lg border flex items-center space-x-2 shadow-lg'
                    style={{
                        fontSize: '14px'
                    }}
                    onClick={onBack}
                >
                    <BsChevronLeft />
                    <label className='cursor-pointer'>Kembali</label>
                </button>
            </div>
            <div className='space-y-5'>
                {instructions.map(i => (
                    <Box instruction={i} />
                ))}
            </div>
        </div>
    )

}

const UserTransactionSimplified = ({
    idTransaksi,
    trxCreatedAt,
    expiredAt,
    finalPrice,
    paymentChannel,
    paymentCode,
    namaPaketBimbel,
    namaAnak,
    status,
    onClickDetail,
    onClickInstructions
}) => {

    function makeColor(paymentStatus) {
        switch (paymentStatus) {
            case "PAID":
                return "bg-green-500"
            
            case "FAILED" :
                return "bg-red-500"
            
            case "EXPIRED" :
                return "bg-blue-500"
            
            case "REFUND" :
                return "bg-blue-500"
            
            case "UNPAID" :
                return "bg-blue-500"

            default:
                return "bg-blue-500"
        }
    }

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
          { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
    }
     

    return (
        <div
            className="rounded-md border border-gray-300 relative divide-y divide-gray-300"
        >
            <div
                className="p-2 space-x-3 font-bold uppercase"
                style={{ fontSize: '15px' }}
            >
                {/* <label>{format(new Date(trxCreatedAt), "dd/MM/yyyy HH:mm")}</label> */}
                <label>ID Transaksi : {idTransaksi}</label>
            </div>
            <div className="p-2 space-y-1">
                <div>
                    <label
                        className="font-bold"
                        style={{ fontFamily: 'Nunito', fontSize: '15px' }}
                    >
                        {namaPaketBimbel}
                    </label>
                </div>
                <div
                    className=""
                    style={{ fontSize: '14px' }}
                >
                    {namaAnak}
                </div>
            </div>
            <div
                className='p-2'
                style={{ fontSize: '14px' }}
            >
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>tgl transaksi</div>
                    <div>:</div>
                    <div>{format(new Date(trxCreatedAt), "dd/MM/yyyy HH:mm")}</div>
                </div>
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Deadline</div>
                    <div>:</div>
                    <div className='font-bold'>{format(new Date(expiredAt), "dd/MM/yyyy HH:mm")}</div>
                </div>
            </div>
            <div
                className='p-2'
                style={{ fontSize: '14px' }}
            >
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>total bayar</div>
                    <div>:</div>
                    <div>{formatRupiah(finalPrice)}</div>
                </div>
                {finalPrice > 0 && (
                    <div
                        className='space-x-2 flex'
                    >
                        <div className='text-left w-24'>Bayar via</div>
                        <div>:</div>
                        <div>{paymentChannel}</div>
                    </div>
                )}
                {finalPrice > 0 && (
                    <div
                        className='space-x-2 flex'
                    >
                        <div className='text-left w-24'>Kode Bayar</div>
                        <div>:</div>
                        <div>{paymentCode}</div>
                    </div>
                )}
            </div>
            {finalPrice > 0 && (
                <div className='flex justify-center py-2'>
                    <button
                        className="p-1 px-3 rounded-md font-bold text-white bg-blue-500"
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                        onClick={onClickInstructions}
                    >
                        Lihat Cara Bayar
                    </button>
                </div>
            )}
            <div
                className="p-1 px-2 flex items-center justify-between"
            >
                <div
                    className='flex space-x-2'
                >
                    <div 
                        className={
                            "p-1 px-3 rounded-md font-bold text-white "+
                            makeColor(status)
                        }
                        style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                    >
                        {status}
                    </div>

                </div>
            </div>
        </div>
    )
}

const SelectedCicilanBox = ({
    selectedCicilan,
    trxs,
    onBack
}) => {
    const navigate = useNavigate

    const [selectedTrx, setSelectedTrx] = useState(null)

    const [isGettingData, setIsGettingData] = useState(false)

    const [transactionInstructions, setTransactionInstructions] = useState([])

    useEffect(() => {
        if (!selectedTrx) {
            setTransactionInstructions([])
            return 
        }

        setIsGettingData(true)

        getTransactionsInstructions(selectedTrx.idTransaction)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setTransactionInstructions(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {})
            .finally(() => {
                setIsGettingData(false)
            })


    }, [selectedTrx])

    return (
        <div>
            {
                isGettingData ?
                    (<div className='flex justify-center'>
                        <RiLoader5Fill className={"h-8 w-8 text-blue-500 animate-spin"} />
                    </div>)
                    :
                    selectedTrx ?
                        (<UserTransactionInstructions 
                            instructions={transactionInstructions}
                            onBack={() => {
                                setSelectedTrx(null)
                            }}
                        />)
                        :
                        (<div className="space-y-3">
                            <div className='flex'>
                                <button 
                                    className='py-2 px-5 rounded-lg border flex items-center space-x-2 shadow-lg'
                                    style={{
                                        fontSize: '14px'
                                    }}
                                    onClick={onBack}
                                >
                                    <BsChevronLeft />
                                    <label className='cursor-pointer'>Kembali</label>
                                </button>
                            </div>
                            <div
                                className='p-2'
                                style={{ fontSize: '14px' }}
                            >
                                <div
                                    className='space-x-2 flex'
                                >
                                    <div className='text-left w-24'>Paket Bimbel</div>
                                    <div>:</div>
                                    <div>{selectedCicilan.namaPaketBimbel}</div>
                                </div>
                                <div
                                    className='space-x-2 flex'
                                >
                                    <div className='text-left w-24'>Anak</div>
                                    <div>:</div>
                                    <div className=''>{selectedCicilan.namaAnak}</div>
                                </div>
                            </div>
                            <div>
                                {
                                    trxs.length > 0 ?
                                        trxs.map(d => (
                                            <UserTransactionSimplified 
                                                key={d.idTransaction}
                                                idTransaksi={d.idTransaction}
                                                trxCreatedAt={d.createdAt}
                                                expiredAt={d.expiredAt}
                                                finalPrice={d.finalPrice}
                                                paymentChannel={d.paymentChannel}
                                                paymentCode={d.paymentCode}
                                                idPaketBimbel={d.paketBimbel.id}
                                                namaPaketBimbel={d.paketBimbel.label}
                                                namaAnak={d.anak.label}
                                                status={d.paymentStatus}
                                                onClickDetail={() => {
                                                    //
                                                }}
                                                onClickInstructions={() => {
                                                    setSelectedTrx(d)
                                                }}
                                            />
                                        ))
                                        :
                                        (
                                            <div 
                                                className='flex justify-center'
                                            >
                                                Tidak ada data Transaksi
                                            </div> 
                                        )
                                }
                            </div>
                        </div>)
            }
        </div>
    )

}

const UserCicilanBox = ({
    idCicilan,
    idAnak,
    namaAnak,
    idPaketBimbel,
    namaPaketBimbel,
    hargaPaketBimbel,
    namaSkemaCicilan,
    jmlFrekuensiCicilan,
    biayaPendafaran,
    biayaPokokCicilan,
    biayaPertamaBayar,
    sisaCicilan,
    isLunas,
    createdAt,
    onViewCicilanTransaction
}) => {

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
          { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
    }

    return (
        <div
            className="rounded-md border border-gray-300 relative divide-y divide-gray-300"
        >
            <div
                className="p-2 space-x-3 font-bold uppercase"
                style={{ fontSize: '15px' }}
            >
                <label>ID Cicilan : {idCicilan}</label>
            </div>
            <div
                className='p-2'
                style={{ fontSize: '14px' }}
            >
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Tgl dibuat</div>
                    <div>:</div>
                    <div>{format(new Date(createdAt), "dd/MM/yyyy HH:mm")}</div>
                </div>
            </div>
            <div
                className='p-2'
                style={{ fontSize: '14px' }}
            >
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Id Anak</div>
                    <div>:</div>
                    <div>{idAnak}</div>
                </div>
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Nama Anak</div>
                    <div>:</div>
                    <div className='font-bold'>{namaAnak}</div>
                </div>
            </div>
            <div
                className='p-2'
                style={{ fontSize: '14px' }}
            >
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Paket Bimbel</div>
                    <div>:</div>
                    <div>{namaPaketBimbel}</div>
                </div>
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Harga</div>
                    <div>:</div>
                    <div className='font-bold'>{formatRupiah(hargaPaketBimbel)}</div>
                </div>
            </div>
            <div
                className='p-2'
                style={{ fontSize: '14px' }}
            >
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Skema Cicilan</div>
                    <div>:</div>
                    <div>{namaSkemaCicilan}</div>
                </div>
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Jml Cicilan</div>
                    <div>:</div>
                    <div>{jmlFrekuensiCicilan} Kali</div>
                </div>
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Biaya Daftar</div>
                    <div>:</div>
                    <div>{formatRupiah(biayaPendafaran)}</div>
                </div>
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Pokok Cicilan</div>
                    <div>:</div>
                    <div>{formatRupiah(biayaPokokCicilan)}</div>
                </div>
                <div
                    className='space-x-2 flex'
                >
                    <div className='text-left w-24'>Awal Bayar</div>
                    <div>:</div>
                    <div>{formatRupiah(biayaPertamaBayar)}</div>
                </div>
                <div
                    className='space-x-2 flex font-bold'
                >
                    <div className='text-left w-24'>Sisa Cicilan</div>
                    <div>:</div>
                    <div>{sisaCicilan} Kali</div>
                </div>

            </div>
            <div
                className='p-2 flex justify-center'
                style={{ fontSize: '14px' }}
            >
                <button
                    className="p-1 px-3 rounded-md font-bold text-white bg-blue-500"
                    style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                    onClick={onViewCicilanTransaction}
                >
                    Lihat Tagihan
                </button>
            </div>
            <div
                className='p-2 font-bold'
                style={{ fontSize: '14px' }}
            >
                {
                    isLunas ?
                        "Lunas"
                        :
                        "Belum Lunas"
                }
            </div>

        </div>
    )


}

const UserCicilan = () => {

    const navigate = useNavigate()

    const { member } = useSelector(state => state.global)

    const [isGettingData, setIsGettingData] = useState(false)

    const [cicilan, setCicilan] = useState([])

    const [cicilanTrx, setCicilanTrx] = useState([])

    const [selectedCicilan, setSelectedCicilan] = useState(null)

    useEffect(() => {

        setIsGettingData(true)

        getCicilan()
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setCicilan(payload)  
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {
                notifyError('Critical Error. Please Contact Developer.')
            })
            .finally(() => setIsGettingData(false))

    }, [])

    useEffect(() => {

        if (!selectedCicilan) {
            return setCicilanTrx([])
        }

        onViewCicilanTransaction(selectedCicilan.idCicilanMember)

    }, [selectedCicilan])

    function onViewCicilanTransaction(idCicilan) {
        setIsGettingData(true)

        getCicilanTransactions(idCicilan)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setCicilanTrx(payload)  
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {
                notifyError('Critical Error. Please Contact Developer.')
            })
            .finally(() => setIsGettingData(false))
    }

    return (
        <div
            className='w-full bg-second-color flex justify-center'
        >
            <div
                className='min-h-screen w-[450px]'
            >
                <div
                    className='w-full min-h-full bg-white rounded-lg'
                >

                    <div 
                        className="flex justify-between px-6 font-bold bg-blue-800 text-white rounded-tl-lg rounded-tr-lg rounded-bl-2xl rounded-br-2xl pt-12 pb-10"
                        style={{  
                            fontFamily: 'Nunito',
                            height: "200px"
                        }}
                    >
                        <div className="">
                            <button
                                onClick={() => navigate('/member')}
                            >
                                <BsChevronLeft className="h-6 w-6"/>
                            </button>
                        </div>
                        <div
                            style={{
                                fontSize: '20px',
                            }}
                        >
                            Riwayat Cicilan
                        </div>
                        <div/>
                    </div>
                    <div 
                        className="px-6 "
                    >
                        <div
                            className="p-5 bg-white rounded-lg shadow-lg z-10"
                            style={{
                                marginTop: '-50px'
                            }}
                        >
                            <div
                                className="space-y-5"
                            >
                                {
                                    isGettingData ?
                                        (<div className='flex justify-center'>
                                            <RiLoader5Fill className={"h-8 w-8 text-blue-500 animate-spin"} />
                                        </div>)
                                        :
                                        selectedCicilan === null ?
                                            cicilan.length > 0 ?
                                                cicilan.map(c => (
                                                    <UserCicilanBox 
                                                        key={c.idCicilanMember}
                                                        idCicilan={c.idCicilanMember}
                                                        idAnak={c.idAnak}
                                                        namaAnak={c.namaAnak}
                                                        idPaketBimbel={c.idPaketBimbel}
                                                        namaPaketBimbel={c.namaPaketBimbel}
                                                        hargaPaketBimbel={c.hargaPaketBimbel}
                                                        namaSkemaCicilan={c.namaSkemaCicilan}
                                                        jmlFrekuensiCicilan={c.jmlFrekuensiCicilan}
                                                        biayaPendafaran={c.biayaPendaftaran}
                                                        biayaPokokCicilan={c.biayaPokokCicilan}
                                                        biayaPertamaBayar={c.biayaPertamaBayar}
                                                        sisaCicilan={c.sisaCicilan}
                                                        isLunas={c.isLunas}
                                                        createdAt={c.createdAt}
                                                        onViewCicilanTransaction={() => setSelectedCicilan(c)}
                                                    />
                                                ))
                                                :
                                                (
                                                    <div 
                                                        className='flex justify-center'
                                                    >
                                                        Tidak ada data
                                                    </div>
                                                )
                                            :
                                            <SelectedCicilanBox
                                                selectedCicilan={selectedCicilan}
                                                trxs={cicilanTrx}
                                                onBack={() => setSelectedCicilan(null)}
                                            />

                                }
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )    

}

export default UserCicilan;