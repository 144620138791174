import { useEffect, useState } from "react"
import { RiLoader5Fill } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import { verifiedEmail } from "../repository/User"
import { handleResponse200 } from "../utils/HttpUtils"
import { notifyError, notifySuccess } from "../utils/Notification"
import { isDesktop, isPhone } from "../utils/PlatformHelper"

const EmailVerification = () => {
    
    const { memberId, token } = useParams()

    const { member, screenSize } = useSelector(state => state.global)

    const [state, setState] = useState('checking') //checking, valid, invalid, error

    useEffect(() => {
        setState('checking')

        verifiedEmail(memberId, token)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        notifySuccess('Verifikasi email berhasil')
                        setState('valid')
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                        setState('invalid')
                    }),
                    onUnAuth: error => {
                        
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                        setState('error')
                    })
                })
            })
            .catch(error => {

            })

    }, [])

    return (
        <>
        <div
            className='w-full h-screen bg-second-color flex justify-center'
        >
            <Navbar />
            <div
                className={
                    'h-full flex items-center '+
                    (isPhone(screenSize.width) ? " px-5 " : "")
                }
                style={{
                    width: isDesktop(screenSize.width) ? '450px' : '100%'
                }}
            >
                <div
                    className='w-full space-y-2'
                >
                    <div
                        className='w-full bg-white rounded-lg space-y-8 px-8 py-10'
                    >

                        {
                            state === 'checking' ?
                            (
                                <div>
                                    <div className="flex justify-center py-5">
                                        <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
                                    </div>
                                    <div
                                        className="flex justify-center font-bold"
                                        style={{
                                            fontFamily: 'Nunito',
                                            fontSize: '18px'
                                        }}
                                    >
                                        Mohon tunggu sebentar, verifikasi token..
                                    </div>
                                </div>
                            )
                            :
                            state === 'valid' ?
                                (<div className="space-y-5">
                                    <div 
                                        className="text-center font-bold"
                                        style={{
                                            fontFamily: 'Nunito',
                                            fontSize: '18px'
                                        }}
                                    >
                                        Verifikasi Email Token Berhasil. Klik tombol dibawah untuk Login.
                                    </div>
                                    <div className='flex justify-center'>
                                        <Link to="/login">
                                            <button 
                                                className='w-60 py-1 rounded-full border-2 border-blue-500 hover:bg-blue-500 hover:text-white font-bold'
                                                style={{
                                                    fontFamily: 'Nunito'
                                                }}
                                            >
                                                Login
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                )
                                :
                                state === 'invalid' ?
                                    (<div
                                        className="flex justify-center font-bold"
                                        style={{
                                            fontFamily: 'Nunito',
                                            fontSize: '18px'
                                        }}
                                    >
                                        Verifikasi Token Gagal. Token Invalid
                                    </div>)
                                    :
                                    (<div>
                                        Error Happened. Please Contact Developer.
                                    </div>)
                        }

                    </div>
                </div>

            </div>
        </div>
        <Footer />
        </>
    )
}

export default EmailVerification