import { useEffect } from "react"
import { useState } from "react"
import { BsChevronLeft } from "react-icons/bs"
import { FaUser } from "react-icons/fa"
import { RiLoader5Fill } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { getAnak } from "../../repository/User"
import { handleResponse200 } from "../../utils/HttpUtils"
import { notifyError } from "../../utils/Notification"

const UserAnakSimplified = ({
    id,
    nama,
    onView
}) => {
    return (
        <div
            className="p-2 relative flex items-center justify-between"
            style={{ fontFamily: 'Nunito' }}
        >
            <div className="flex items-center space-x-2">
                <div className="rounded-mg bg-blue-100 p-2 rounded-md shadow-lg">
                    <FaUser className="h-5 w-5"/>
                </div>
                <div>
                    <div
                        className="font-bold"
                        style={{
                            fontSize: '14px',
                            fontFamily: 'Nunito'
                        }}
                    >
                        {id}
                    </div>
                    <div
                        className="font-bold"
                        style={{
                            fontSize: '14px',
                            fontFamily: 'Nunito'
                        }}
                    >
                        {nama}
                    </div>
                </div>
            </div>
            <div className="flex space-x-2">
                <button
                    className="p-2 rounded-lg bg-blue-100 shadow-lg"
                    onClick={onView}
                >
                    Lihat
                </button>
            </div>
        </div>
    )
}

const UserAnakPresensi = () => {

    const navigate = useNavigate()

    const { member } = useSelector(state => state.global)

    const [isGettingAnak, setIsGettingAnak] = useState(false)
    

    const [anak, setAnak] = useState([])

    useEffect(() => {
        onGettingAnak()
    }, [])

    function onGettingAnak() {
        setIsGettingAnak(true)
        
        getAnak()
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setAnak(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(() => {})
            .finally(() => {
                setIsGettingAnak(false)
            })
    }

    function showDataAnak(anakList) {
        if (anakList.length < 1) {
            return (
                <div className="flex justify-center py-2">
                    Tidak ada data
                </div>
            )
        }

        return anakList.map(d => (
            <UserAnakSimplified
                key={d.idAnak}
                id={d.idAnak}
                nama={d.nama}
                onView={() => navigate("/member/anak-presensi-detail/" + d.idAnak)}
            />
        ))
    }




    return (
        <div
            className='w-full h-screen bg-second-color flex justify-center'
        >
            <div
                className='w-[450px] h-full'
            >
                <div
                    className='w-full h-full bg-white rounded-lg'
                >
                    <div 
                        className="flex justify-between px-6 font-bold bg-blue-800 text-white rounded-tl-lg rounded-tr-lg rounded-bl-2xl rounded-br-2xl pt-12 pb-10"
                        style={{  
                            fontFamily: 'Nunito',
                            height: "200px"
                        }}
                    >
                        <div className="">
                            <button
                                onClick={() => navigate('/member')}
                            >
                                <BsChevronLeft className="h-6 w-6"/>
                            </button>
                        </div>
                        <div
                            style={{
                                fontSize: '20px',
                            }}
                        >
                            Data Presensi Anak
                        </div>
                        <div/>
                    </div>
                    <div 
                        className="px-6 "
                    >
                        <div
                            className="p-5 bg-white rounded-lg shadow-lg z-10"
                            style={{
                                marginTop: '-50px'
                            }}
                        >

                            <div
                                className="space-y-3"
                            >
                                {
                                    <>
                                    <div
                                        className="flex justify-end"
                                    >
                                    </div>
                                    <div
                                        className="space-y-3"
                                    >
                                        {
                                            isGettingAnak ?
                                                (
                                                <div className="flex justify-center">
                                                    <RiLoader5Fill className={"h-6 w-6 text-blue-500 animate-spin"} />
                                                </div>
                                                )
                                                :
                                                showDataAnak(anak)              
                                        }
                                    </div>
                                    </>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="py-10">
                        
                    </div>
                </div>
            </div>
        </div>
    )


}

export default UserAnakPresensi;