import { configureStore } from '@reduxjs/toolkit'
import GlobalReducer from './GlobalSlice'
import { setupListeners } from '@reduxjs/toolkit/query'

export const store = configureStore({
  reducer: {
    global: GlobalReducer
  }
})

setupListeners(store.dispatch)