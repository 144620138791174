import React, { useState } from 'react'
import control from '../assets/icons/control.png'
import logo from '../assets/kunci-belajar.png'

import {
    UserGroupIcon,
    ChatAlt2Icon,
    ChartBarIcon,
    AcademicCapIcon,
} from '@heroicons/react/solid'

const Sidebar = () => {

    const [open, setOpen] = useState(true);
    const Menus = [
        {
            "title": "Dashboard",
            "icon": <UserGroupIcon className='w-[25px]' />
        },
        {
            "title": "Data Lorem",
            "icon": <ChatAlt2Icon className='w-[25px]' />
        },
        {
            "title": "Data Lorem",
            "icon": <AcademicCapIcon className='w-[25px]' />
        },
        {
            "title": "Data Lorem",
            "icon": <ChartBarIcon className='w-[25px]' />
        },
        {
            "title": "Data Lorem",
            "icon": <UserGroupIcon className='w-[25px]' />
        },
        {
            "title": "Data Lorem",
            "icon": <AcademicCapIcon className='w-[25px]' />
        },
    ]

    return (
        <div className={`${open ? "w-72" : "w-20"} p-5 pt-4 duration-300 h-screen bg-main-color relative`}>
            <img
                src={control}
                className={`absolute cursor-pointer -right-4 top-9 w-7 border-2 border-main-color rounded-full ${!open && "rotate-180"}`}
                alt="control"
                onClick={() => setOpen(!open)}
            />
            <div className='flex gap-x-4 items-center'>
                <img
                    src={logo}
                    alt="logo"
                    className={`cursor-pointer duration-500`}
                />
                {/* <h1 className={`text-white origin-left font-medium text-xl duration-300`}>Kunci Belajar</h1> */}
            </div>
            <ul className='pt-6'>
                {Menus.map((menu, index) => (
                    <li
                        key={index}
                        className={`text-gray-200 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-blue-600 ${menu.gap ? "mt-9" : "mt-2"}`}>
                        {menu.icon}
                        <span className={`${!open && "hidden"} origin-left duration-200`}>
                            {menu.title}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Sidebar