import { useEffect } from "react"
import { useSelector } from "react-redux"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import { isDesktop, isPhone } from "../utils/PlatformHelper"

const AboutUs = () => {
    const { screenSize } = useSelector(state => state.global)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <div
            className={
                'w-full bg-second-color ' +
                (isDesktop(screenSize.width) ? " flex justify-center pt-[50px] h-screen  " : "") +
                (isPhone(screenSize.width) ? " pt-[30px] pb-[80px] " : "")
            }
        >
            <Navbar />
            <div
                className={
                    'space-y-10 ' +
                    (isPhone(screenSize.width) ? "px-5" : "")
                }
                style={{
                    width: isPhone(screenSize.width) ? "100%" : "1200px"
                }}
            >

                <div
                    className='w-full bg-white rounded-lg px-10 py-10 mt-[100px]'
                >
                    <div 
                        className={
                            (isDesktop(screenSize.width) ? "flex items-center space-x-16" : " space-y-10 ")
                        }
                        style={{
                            fontFamily: 'Nunito'
                        }}
                    >
                        <div>
                            <img 
                                src="/images/logo.png"
                                width={ isPhone(screenSize.width) ? '150px' : '150px'}
                            />
                        </div>
                        <div
                            className="space-y-5 flex-1"
                        >
                            <div className="space-y-3">
                                <div 
                                    className="font-bold text-blue-900"
                                    style={{
                                        fontSize: '24px'
                                    }}
                                >
                                    <h1>Visi Bimbel ALC</h1>
                                </div>
                                <div
                                    className="font-bold space-y-2 text-gray-500"
                                >
                                    <p>
                                    menjadi mitra bimbingan belajar yang profesional untuk mewujudkan murid yang cerdas dan berakhlak
                                    </p>
                                </div>
                            </div>
                            <div className="space-y-3">
                                <div 
                                    className="font-bold text-blue-900"
                                    style={{
                                        fontSize: '24px'
                                    }}
                                >
                                    <h1>Misi</h1>
                                </div>
                                <div
                                    className="font-bold space-y-2 text-gray-500 space-y-3"
                                >
                                    <div className="space-y-1">
                                        <div className="py-1 w-60 pl-5 bg-blue-800 text-white font-bold rounded-tr-full rounded-br-full uppercase">
                                            Tutor
                                        </div>
                                        <div className="pl-5">
                                            Mewujudkan Tutor yang kompeten dan berintegritas
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <div className="py-1 w-60 pl-5 bg-blue-800 text-white font-bold rounded-tr-full rounded-br-full uppercase">
                                            Lulusan
                                        </div>
                                        <div className="pl-5">
                                        Membentuk Pribadi Murid yang cerdas, kreatif dan berakhlak
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <div className="py-1 w-60 pl-5 bg-blue-800 text-white font-bold rounded-tr-full rounded-br-full uppercase">
                                            Sistem Layanan
                                        </div>
                                        <div className="pl-5">
                                        Mewujudkan sistem layanan bimbingan belajar yang nyaman, akuntabel dan responsif
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <Footer />
        </>
    )
}

export default AboutUs