import { useEffect, useState } from "react"
import { FaWhatsapp } from "react-icons/fa"
import { RiLoader5Fill } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import { getDetailPublicPaketBimbel } from "../repository/PaketBimbel"
import { createTransaction, getPaymentChan } from "../repository/Payment"
import { getAnak, getTransactionsInstructions } from "../repository/User"
import { handleResponse200 } from "../utils/HttpUtils"
import { notifyError, notifySuccess } from "../utils/Notification"
import { format } from "date-fns"
import { GoStop } from "react-icons/go"
import { GrCheckbox } from "react-icons/gr"
import { AiOutlineCheck } from "react-icons/ai"

const InValidMemberAddress = () => {
    return (
        <div
            className="flex justify-center"
        >
            <div
                className="flex justify-center"
                style={{
                    marginTop: '50px',
                    width: "400px"
                }}
            >
                <div
                    className="space-y-5"
                >
                    <div 
                        className="w-full p-3 px-5 rounded-md border border-gray-300 shadow-lg divide-y divide-gray-300"
                    >
                        <div
                            className="space-y-3 py-5"
                        >
                            <div className="flex justify-center py-3">
                                <GoStop className="text-red-500 h-32 w-32"/>
                            </div>
                            <div 
                                className="text-center"
                            >
                                Anda belum melengkapi data alamat
                            </div>
                            <div className="flex justify-center">
                                <Link to={"/member/profil"}>
                                    <button
                                        className="py-1 px-8 bg-blue-500 text-white rounded-full"
                                    >
                                        Lengkapi Sekarang
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )   
}

const UnSignedBox = () => {
    return (
        <div
            className="flex justify-center"
        >
            <div
                className="flex justify-center"
                style={{
                    marginTop: '50px',
                    width: "400px"
                }}
            >
                <div
                    className="space-y-5"
                >
                    <div 
                        className="w-full p-3 px-5 rounded-md border border-gray-300 shadow-lg divide-y divide-gray-300"
                    >
                        <div
                            className="space-y-3 py-5"
                        >
                            <div className="flex justify-center">
                                <GoStop className="text-red-500 h-32 w-32"/>
                            </div>
                            <div 
                                className="text-center"
                            >
                                Anda perlu login untuk melakukan pembelian
                            </div>
                            <div className="flex justify-center">
                                <Link to={"/login"}>
                                    <button
                                        className="py-1 px-8 bg-blue-500 text-white rounded-full"
                                    >
                                        Masuk Akun
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div
                            className="space-y-3 py-5"
                        >
                            <div 
                                className="text-center"
                            >
                                Belum punya Akun? Klik tombol di bawah
                            </div>
                            <div className="flex justify-center">
                                <Link 
                                    to={"/register"}
                                >
                                    <button
                                        className="py-1 px-8 bg-red-500 text-white rounded-full"
                                    >
                                        Daftar Akun
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div 
                        className="w-full p-3 rounded-md border border-gray-300 shadow-lg space-y-5"
                    >
                        <div 
                            className="text-center"
                        >
                            Perlu bantuan?
                        </div>
                        <div className="px-5">
                            <div>
                                <button
                                    className="w-full py-2 px-8 bg-green-600 text-white rounded-full flex items-center justify-center space-x-2 font-bold"
                                >
                                    <FaWhatsapp className="h-5 w-5"/>
                                    <label className="hover:cursor-pointer">{'+62 822 1560 5265'}</label>
                                </button>
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    )
}

const CheckoutSuccess = ({
    transactionDetail
}) => {

    const navigate = useNavigate()

    if (!transactionDetail) return notifyError('UnExpected Error : Transaction Detail Not Found')
    
    const UserTransactionInstructions = ({
        instructions
    }) => {
    
        const Box = ({ instruction }) => {
            return (
                <div>
                    <div 
                        className='font-bold uppercase p-2 rounded-md shadow-lg border bg-blue-600 text-white' 
                        style={{ fontFamily: 'Nunito' }}
                    >
                        Via {instruction.title}
                    </div>
                    <div className='pl-5 pt-2'>
                        <ul className='list-decimal'>
                            { instruction.steps.map(s => (
                                <li key={s} className='py-1' style={{ fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: s }}></li>
                            )) }
                        </ul>
                    </div>
                </div>
            )
        }
    
        return (
            <div className='space-y-5'>
                <div className='space-y-5'>
                    {instructions.map(i => (
                        <Box key={i.title} instruction={i} />
                    ))}
                </div>
            </div>
        )
    
    }
    
    const FreePaketResult = () => {
        return (
            <div
                className="p-3 rounded-lg border border-gray-300 shadow-lg space-y-5 "
            >
                <div
                    className="text-center font-bold"
                    style={{ fontFamily: 'Nunito', fontSize: '15px' }}
                >
                    Proses transaksi berhasil dilakukan dengan detail sebagai berikut
                </div>
                <div
                    className="space-y-3"
                    style={{
                        fontSize: '15px',
                        fontFamily: 'Nunito'
                    }}
                >
                    <div className="space-y-1">
                        <div className="font-bold">
                            Id Transaksi
                        </div>
                        <div 
                            className="flex items-center justify-between p-2 py-1 border border-gray-300 rounded-lg font-bold bg-gray-100"
                            style={{
                                fontSize: '14px',
                                fontFamily: 'Nunito'
                            }}
                        >
                            <label>{transactionDetail.idTransaction}</label>
                            <button 
                                className="px-5 py-1 border bg-blue-500 text-white rounded-md shadow-lg"
                                onClick={() => {
                                    navigator.clipboard.writeText(transactionDetail.idTransaction)
                                    notifySuccess('Berhasil disalin')
                                }}
                            >
                                Salin
                            </button>
                        </div>
                    </div>
                    <div className="space-y-1">
                        <div className="font-bold">
                            Tanggal transaksi
                        </div>
                        <div 
                            className="flex items-center justify-between p-2 py-1 border border-gray-300 rounded-lg font-bold bg-gray-100"
                            style={{
                                fontSize: '14px',
                                fontFamily: 'Nunito'
                            }}
                        >
                            {format(new Date(transactionDetail.createdAt), "dd/MM/yyyy HH:mm")}
                        </div>
                    </div>
                    <div className="space-y-1">
                        <div className="font-bold">
                            Status
                        </div>
                        <div 
                            className="flex items-center justify-between p-2 py-1 border border-gray-300 rounded-lg font-bold bg-gray-100"
                            style={{
                                fontSize: '14px',
                                fontFamily: 'Nunito'
                            }}
                        >
                            Selesai
                        </div>
                    </div>
                    
                </div>
                <div
                    className="text-center"
                    style={{
                        fontSize: '15px',
                        fontFamily: 'Nunito'
                    }}
                >
                    Untuk informasi lebih lanjut silahkan klik <Link to={"/member/transaksi"}><span className="font-bold text-blue-900 underline">kunjungi halaman transaksi</span></Link> 
                </div>
            </div>
        )
    }
    

    return (
        <div className="space-y-5">
            {
                transactionDetail.expiredAt ?
                    (<>
                    <div
                        className="p-3 rounded-lg border border-gray-300 shadow-lg space-y-5 "
                    >
                        <div
                            className="text-center font-bold"
                            style={{ fontFamily: 'Nunito', fontSize: '15px' }}
                        >
                            Proses transaksi berhasil dilakukan dengan detail sebagai berikut
                        </div>
                        <div
                            className="space-y-3"
                            style={{
                                fontSize: '15px',
                                fontFamily: 'Nunito'
                            }}
                        >
                            <div className="space-y-1">
                                <div className="font-bold">
                                    Id Transaksi
                                </div>
                                <div 
                                    className="flex items-center justify-between p-2 py-1 border border-gray-300 rounded-lg font-bold bg-gray-100"
                                    style={{
                                        fontSize: '14px',
                                        fontFamily: 'Nunito'
                                    }}
                                >
                                    <label>{transactionDetail.idTransaction}</label>
                                    <button 
                                        className="px-5 py-1 border bg-blue-500 text-white rounded-md shadow-lg"
                                        onClick={() => {
                                            navigator.clipboard.writeText(transactionDetail.idTransaction)
                                            notifySuccess('Berhasil disalin')
                                        }}
                                    >
                                        Salin
                                    </button>
                                </div>
                            </div>
                            <div className="space-y-1">
                                <div className="font-bold">
                                    Tanggal transaksi
                                </div>
                                <div 
                                    className="flex items-center justify-between p-2 py-1 border border-gray-300 rounded-lg font-bold bg-gray-100"
                                    style={{
                                        fontSize: '14px',
                                        fontFamily: 'Nunito'
                                    }}
                                >
                                    {format(new Date(transactionDetail.createdAt), "dd/MM/yyyy HH:mm")}
                                </div>
                            </div>
                            <div className="space-y-1">
                                <div className="font-bold">
                                    Batas Akhir Pembayaran
                                </div>
                                <div 
                                    className="flex items-center justify-between p-2 py-1 border border-gray-300 rounded-lg font-bold bg-gray-100"
                                    style={{
                                        fontSize: '14px',
                                        fontFamily: 'Nunito'
                                    }}
                                >
                                    {format(new Date(transactionDetail.expiredAt), "dd/MM/yyyy HH:mm")}
                                </div>
                            </div>
                            <div className="space-y-1">
                                <div className="font-bold">
                                    Metode Pembayaran
                                </div>
                                <div 
                                    className="flex items-center justify-between p-2 py-1 border border-gray-300 rounded-lg font-bold bg-gray-100"
                                    style={{
                                        fontSize: '14px',
                                        fontFamily: 'Nunito'
                                    }}
                                >
                                    {transactionDetail.paymentDetail.payment_name}
                                </div>
                            </div>
                            <div className="space-y-1">
                                <div className="font-bold">
                                    Kode Bayar
                                </div>
                                <div 
                                    className="flex items-center justify-between p-2 py-1 border border-gray-300 rounded-lg font-bold bg-gray-100"
                                    style={{
                                        fontSize: '14px',
                                        fontFamily: 'Nunito'
                                    }}
                                >
                                    <label>{transactionDetail.paymentDetail.pay_code}</label>
                                    <button 
                                        className="px-5 py-1 border bg-blue-500 text-white rounded-md shadow-lg"
                                        onClick={() => {
                                            navigator.clipboard.writeText(transactionDetail.paymentDetail.pay_code)
                                            notifySuccess('Berhasil disalin')
                                        }}
                                    >
                                        Salin
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div
                            className="text-center"
                            style={{
                                fontSize: '15px',
                                fontFamily: 'Nunito'
                            }}
                        >
                            Untuk informasi lebih lanjut silahkan klik <Link to={"/member/transaksi"}><span className="font-bold text-blue-900 underline">kunjungi halaman transaksi</span></Link> 
                        </div>
                    </div>
                    <div className="p-3 rounded-lg border border-gray-300 shadow-lg space-y-3">
                        <div
                            className="font-bold text-center"
                            style={{
                                fontFamily: 'Nunito'
                            }}
                        >
                            Tata cara pembayaran
                        </div>
                        <div>
                            <UserTransactionInstructions 
                                instructions={transactionDetail.paymentDetail.instructions}
                            />
                        </div>
                    </div>
                    </>
                    )
                    :
                    <FreePaketResult />
            }

        </div>
    )


}

const CheckoutBox = ({
    idPaketBimbel
}) => {

    const [groupedPayChan, setGroupedPayChan] = useState([])

    const [isGettingPayChan, setIsGettingPayChan] = useState(false)
    const [isGettingDetailPaket, setIsGettingDetailPaket] = useState(false)
    const [isGettingAnak, setIsGettingAnak] = useState(false)

    const [payChans, setPayChans] = useState([])
    const [paketBimbel, setPaketBimbel] = useState(null)
    const [anak, setAnak] = useState([])

    const [selectedAnak, setSelectedAnak] = useState(null)
    const [selectedPaymentType, setSelectedPaymentType] = useState('cash') //null or cash or credit
    const [selectedPayChan, setSelectedPayChan] = useState(null)

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
          { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
    }

    useEffect(() => {
        window.scrollTo(0, 10);
    }, [selectedAnak])

    useEffect(() => {
        window.scrollTo(0, 300);
    }, [selectedPayChan])

    useEffect(() => {

        setIsGettingPayChan(true)
        setIsGettingDetailPaket(true)
        setIsGettingAnak(true)

        getPaymentChan()
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setPayChans(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {})
            .finally(() => { setIsGettingPayChan(false) })

        getAnak()
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setAnak(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {})
            .finally(() => { setIsGettingAnak(false) })

        getDetailPublicPaketBimbel(idPaketBimbel)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setPaketBimbel(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {})
            .finally(() => { setIsGettingDetailPaket(false) })

        
    }, [])

    const PaketBox = ({
        isLoad,
        paketDetail
    }) => {
        return (
            <div className="w-full p-5 border rounded-lg shadow-lg space-y-3">
                <div
                    className="font-bold"
                    style={{
                        fontFamily: 'Nunito',
                        fontSize: '18px'
                    }}
                >
                    Detail Paket Belajar
                </div>
                <div>
                    {
                        isLoad ?
                            (
                                <div
                                    className="py-3 flex justify-center"
                                >
                                    <RiLoader5Fill className={"h-8 w-8 text-blue-500 animate-spin"} />
                                </div>
                            )
                            :
                            paketDetail ?
                                (<div
                                    style={{
                                        fontSize: '15px'
                                    }}
                                >
                                    <div className="flex space-x-2">
                                        <div className="w-20">ID</div>
                                        <div>:</div>
                                        <div>{paketDetail.idPaketBimbel}</div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div className="w-20">Nama</div>
                                        <div>:</div>
                                        <div>{paketDetail.namaPaketBimbel}</div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div className="w-20">Program</div>
                                        <div>:</div>
                                        <div>{paketDetail.program.label}</div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div className="w-20">Harga</div>
                                        <div>:</div>
                                        <div className="font-bold">{formatRupiah(paketDetail.price)}</div>
                                    </div>
                                </div>)
                                :
                                (<div>Paket tidak ditemukan</div>)
                    }
                </div>
            </div>
        )
    }

    const AnakBox = ({
        isLoad,
        anaks,
        selectedAnak,
        onSelectedAnak,
        idProgram
    }) => {

        const [mode, setMode] = useState('result') //result or form

        return (
            <div className="w-full p-5 border rounded-lg shadow-lg space-y-3">
                {
                    mode === 'result' ?
                        (<>
                        <div
                            className="font-bold"
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: '18px'
                            }}
                        >
                            Detail Anak
                        </div>
                        <div>
                            {
                                isLoad ?
                                    (
                                        <div
                                            className="py-3 flex justify-center"
                                        >
                                            <RiLoader5Fill className={"h-8 w-8 text-blue-500 animate-spin"} />
                                        </div>
                                    )
                                    :
                                    anaks.length == 0 ?
                                        (<div className="space-y-2">
                                            <div className="text-center">Data Anak tidak tersedia. Klik tombol di bawah untuk menambah data anak.</div>
                                            <div className="px-10">
                                                <Link to={"/member/anak"} >
                                                    <button className="py-1 w-full rounded-full bg-blue-500 text-white hover:bg-blue-900 hover:shadow-lg">
                                                        Tambah data anak
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>)
                                        :
                                        selectedAnak ?
                                            (<div>
                                                <div
                                                    style={{
                                                        fontSize: '15px'
                                                    }}
                                                >

                                                    <div className="flex space-x-2">
                                                        <div className="w-20">ID</div>
                                                        <div>:</div>
                                                        <div>{selectedAnak.idAnak}</div>
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <div className="w-20">Nama</div>
                                                        <div>:</div>
                                                        <div>{selectedAnak.nama}</div>
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <div className="w-20">Kelas</div>
                                                        <div>:</div>
                                                        <div>{selectedAnak.kelas.label}</div>
                                                    </div>
                                                    <div className="flex space-x-2">
                                                        <div className="w-20">Program</div>
                                                        <div>:</div>
                                                        <div>{selectedAnak.program.label}</div>
                                                    </div>

                                                </div>
                                                <div className="pt-3">
                                                    <button 
                                                        className="py-1 w-full rounded-full bg-blue-500 text-white hover:bg-blue-900 hover:shadow-lg font-bold"
                                                        style={{
                                                            fontFamily: 'Nunito'
                                                        }}
                                                        onClick={() => setMode('form')}
                                                    >
                                                        Ubah pilihan anak
                                                    </button>
                                                </div>
                                            </div>)
                                            :
                                            (<div>
                                                <button 
                                                    className="py-1 w-full rounded-full bg-blue-500 text-white hover:bg-blue-900 hover:shadow-lg font-bold"
                                                    style={{
                                                        fontFamily: 'Nunito'
                                                    }}
                                                    onClick={() => setMode('form')}
                                                >
                                                    Pilih salah satu anak
                                                </button>
                                            </div>
                                            )   
                            }
                        </div>
                        </>)
                    :
                        (<>
                        <div
                            className="font-bold"
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: '18px'
                            }}
                        >
                            Pilih salah satu Anak
                        </div>
                        <div className="space-y-3">
                            {anaks.map(a => (
                                <div 
                                    key={a.idAnak}
                                    className="border border-gray-300 rounded-lg"
                                    style={{
                                        fontSize: '15px'
                                    }}
                                >
                                    { a.program.id != idProgram && (
                                        <div
                                            className="py-1 px-5 bg-red-500 text-white rounded-tl-lg rounded-tr-lg rounded-bl-lg font-bold"
                                        >
                                            Program berbeda. Anak tidak dapat dipilih
                                        </div>
                                    )}
                                    <div className="p-2">
                                        <div className="flex space-x-2">
                                            <div className="w-20">ID</div>
                                            <div>:</div>
                                            <div>{a.idAnak}</div>
                                        </div>
                                        <div className="flex space-x-2">
                                            <div className="w-20">Nama</div>
                                            <div>:</div>
                                            <div>{a.nama}</div>
                                        </div>
                                        <div className="flex space-x-2">
                                            <div className="w-20">Kelas</div>
                                            <div>:</div>
                                            <div>{a.kelas.label}</div>
                                        </div>
                                        <div className="flex space-x-2">
                                            <div className="w-20">Program</div>
                                            <div>:</div>
                                            <div>{a.program.label}</div>
                                        </div>
                                        <div className="pt-3">
                                            {a.program.id == idProgram && (
                                                <button 
                                                    className={
                                                        "py-1 w-full rounded-full hover:shadow-lg font-bold " +
                                                        (a.program.id == idProgram ? ' bg-blue-500 text-white hover:bg-blue-900 ' : ' bg-gray-300 text-black cursor-not-allowed')
                                                    }
                                                    style={{
                                                        fontFamily: 'Nunito'
                                                    }}
                                                    onClick={() => {
                                                        setMode('mode')
                                                        onSelectedAnak(a)
                                                    }}
                                                    disabled={a.program.id != idProgram}
                                                >
                                                    Pilih
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        </>)
                }
            </div>
        )
    }

    const PaymentTypeBox = ({
        paketDetail,
        skemaCicilan,
        selectedPaymentType,
        setSelectedPaymentType
    }) => {

        return (
            <div className="w-full p-5 border rounded-lg shadow-lg space-y-3">
                <div
                    className="font-bold"
                    style={{
                        fontFamily: 'Nunito',
                        fontSize: '18px'
                    }}
                >
                    Jenis Pembayaran
                </div>
                <div className="space-y-5">

                    <div>
                        <div className="rounded-lg bg-gray-100 p-2 flex items-center justify-between">
                            <label>Pembayaran Lunas</label>
                            <button
                                onClick={() => setSelectedPaymentType('cash')}
                            >
                                {
                                    selectedPaymentType === 'cash' ?
                                    <AiOutlineCheck className="bg-green-500 text-white h-5 w-5"/> 
                                    :
                                    <GrCheckbox className="h-5 w-5"/> 
                                }
                            </button>
                        </div>
                        <div
                            className="space-y-2 pl-5 pt-2"
                            style={{
                                fontSize: '14px'
                            }}
                        >
                            <div className="flex space-x-3">
                                <div className="w-24">Jml. Bayar</div>
                                <div>:</div>
                                <div>{formatRupiah(paketDetail.price)}</div>
                            </div>
                        </div>
                        {paketDetail.discount && (
                            <>
                            <div
                                className="space-y-2 pl-5 pt-2"
                                style={{
                                    fontSize: '14px'
                                }}
                            >
                                <div className="flex space-x-3">
                                    <div className="w-24">Discount</div>
                                    <div>:</div>
                                    <div>
                                        {
                                            paketDetail.discount.discountType === 'discount_by_nominal' ?
                                                formatRupiah(paketDetail.discount.nominal)
                                                :
                                                (paketDetail.discount.nominal + '%')
                                        }
                                    </div>
                                </div>
                            </div>
                            <div
                                className="space-y-2 pl-5 pt-2"
                                style={{
                                    fontSize: '14px'
                                }}
                            >
                                <div className="flex space-x-3">
                                    <div className="w-24">Harga Akhir</div>
                                    <div>:</div>
                                    <div>
                                        {formatRupiah(paketDetail.finalPrice)}
                                    </div>
                                </div>
                            </div>
                            </>
                        )}
                    </div>

                    <div>
                        <div className="rounded-lg bg-gray-100 p-2 flex items-center justify-between">
                            <label>{"Bayar Cicilan"}</label>
                            <button
                                onClick={() => setSelectedPaymentType('credit')}
                            >
                                {
                                    selectedPaymentType === 'credit' ?
                                    <AiOutlineCheck className="bg-green-500 text-white h-5 w-5"/> 
                                    :
                                    <GrCheckbox className="h-5 w-5"/> 
                                }
                            </button>
                        </div>
                        <div 
                            className="space-y-2 pl-5 pt-2"
                            style={{
                                fontSize: '14px'
                            }}
                        >
                            <div className="flex space-x-3">
                                <div className="w-24">Jml. Cicilan</div>
                                <div>:</div>
                                <div>{skemaCicilan.jmlFrekuensiCicilan} Kali</div>
                            </div>
                            <div className="flex space-x-3">
                                <div className="w-24">Biaya Daftar</div>
                                <div>:</div>
                                <div>{formatRupiah(skemaCicilan.biayaPendaftaran)}</div>
                            </div>
                            <div className="flex space-x-3">
                                <div className="w-24">Biaya Pokok</div>
                                <div>:</div>
                                <div>{formatRupiah(skemaCicilan.biayaPokokCicilan)}</div>
                            </div>
                            <div className="flex space-x-3">
                                <div className="w-24">Awal Bayar</div>
                                <div>:</div>
                                <div>{formatRupiah(skemaCicilan.biayaPertamaBayar)}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )        
    }

    const PaymentBox = ({
        isLoad,
        payChans,
        selectedPayChan,
        onSelectedPayChan
    }) => {

        const [mode, setMode] = useState('result') //result or form

        return (
            <div className="w-full p-5 border rounded-lg shadow-lg space-y-3">
                {
                    mode === 'result' ?
                        (<>
                        <div
                            className="font-bold"
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: '18px'
                            }}
                        >
                            Metode Pembayaran
                        </div>
                        <div>
                            {
                                isLoad ?
                                    (
                                        <div
                                            className="py-3 flex justify-center"
                                        >
                                            <RiLoader5Fill className={"h-8 w-8 text-blue-500 animate-spin"} />
                                        </div>
                                    )
                                    :
                                    payChans.length == 0 ?
                                        (<div className="space-y-2">
                                            <div className="text-center">Data Pembayaran tidak tersedia</div>
                                        </div>)
                                        :
                                        selectedPayChan ?
                                            (<div>
                                                <div
                                                    style={{
                                                        fontSize: '15px',
                                                        fontFamily: 'Nunito'
                                                    }}
                                                >

                                                    <div 
                                                        className="p-2 rounded-md border border-gray-300 flex items-center space-x-2"
                                                    >
                                                        <div>
                                                            {<img src={selectedPayChan.icon_url} width={'60px'}/>}
                                                        </div>
                                                        <div>
                                                            <div className="font-bold">{selectedPayChan.name}</div>
                                                            <div className="font-bold">
                                                                <div>Biaya Admin : {formatRupiah(selectedPayChan.total_fee.flat)}</div>   
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="pt-3">
                                                    <button 
                                                        className="py-1 w-full rounded-full bg-blue-500 text-white hover:bg-blue-900 hover:shadow-lg font-bold"
                                                        style={{
                                                            fontFamily: 'Nunito'
                                                        }}
                                                        onClick={() => {
                                                            setMode('form')
                                                        }}
                                                    >
                                                        Ubah metode pembayaran
                                                    </button>
                                                </div>
                                            </div>)
                                            :
                                            (<div>
                                                <button 
                                                    className="py-1 w-full rounded-full bg-blue-500 text-white hover:bg-blue-900 hover:shadow-lg font-bold"
                                                    style={{
                                                        fontFamily: 'Nunito'
                                                    }}
                                                    onClick={() => {
                                                        setMode('form')
                                                    }}
                                                >
                                                    Pilih salah metode pembayaran
                                                </button>
                                            </div>
                                            )
                            }
                        </div>
                        </>)
                        :
                        (<>
                        <div
                            className="font-bold"
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: '18px'
                            }}
                        >
                            Pilih salah satu Metode Pembayaran
                        </div>
                        <div className="space-y-5">
                            {payChans.map(p => (
                                <div>
                                    <div 
                                        key={p.groupdChan}
                                        className="border border-gray-300 rounded-lg p-2 font-bold shadow-lg"
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Nunito'
                                        }}
                                    >
                                        {p.groupChan}
                                    </div>
                                    <div 
                                        className="pt-2 pl-2 space-y-2"
                                        style={{
                                            fontFamily: 'Nunito',
                                            fontSize: '15px'
                                        }}
                                    >
                                        {p.channels.map(c => (
                                            <div 
                                                className="p-2 px-3 rounded-md border border-gray-300 flex items-center space-x-5"
                                            >
                                                <div>
                                                    {<img src={c.icon_url} width={'60px'}/>}
                                                </div>
                                                <div>
                                                    <div className="font-bold">{c.name}</div>
                                                    <div className="font-bold">
                                                        <div>Biaya Admin : {formatRupiah(c.total_fee.flat)}</div>   
                                                    </div>
                                                    <div className="pt-2">
                                                        <button 
                                                            className="py-1 px-16 rounded-full bg-blue-500 text-white font-bold hover:bg-blue-900 hover:shadow-lg"
                                                            onClick={() => {
                                                                onSelectedPayChan(c)
                                                                setMode('result')
                                                            }}
                                                        >
                                                            Pilih 
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        </>)
                }
            </div>
        )
    }

    const SummaryBox = ({
        paketBimbel,
        selectedAnak,
        selectedPaymentType,
        selectedPayChan,
        onCheckout
    }) => {

        return (
            <div 
                className="w-full py-5 border rounded-lg shadow-lg space-y-3"
            >
                <div
                    className="font-bold px-5"
                    style={{
                        fontFamily: 'Nunito',
                        fontSize: '18px'
                    }}
                >
                    Rincian Pembayaran
                </div>
                <div
                    className="px-5"
                    style={{
                        fontSize: '14px',
                        fontFamily: 'Nunito'
                    }}
                >
                    <div className="flex space-x-2">
                        <div className="w-20">Total Bayar </div>
                        <div>:</div>
                        <div>{paketBimbel ? selectedPaymentType === 'cash' ? formatRupiah(paketBimbel.finalPrice) : formatRupiah(paketBimbel.skemaCicilan.biayaPertamaBayar) : '-'}</div>
                    </div>
                    <div className="flex space-x-2">
                        <div className="w-20">Biaya Admin</div>
                        <div>:</div>
                        <div>{selectedPayChan ? formatRupiah(selectedPayChan.total_fee.flat) : '-'}</div>
                    </div>
                    {selectedPayChan && paketBimbel && (
                        <div className="flex space-x-2">
                            <div className="w-20">Total Bayar</div>
                            <div>:</div>
                            <div className="font-bold" style={{ fontSize: '18px' }}>
                                {
                                    selectedPaymentType === 'cash' ? 
                                        formatRupiah(selectedPayChan.total_fee.flat + paketBimbel.finalPrice)
                                        :
                                        formatRupiah(selectedPayChan.total_fee.flat + paketBimbel.skemaCicilan.biayaPertamaBayar)
                                }
                            </div>
                        </div>
                    )}
                </div>
                <div className="px-6">
                    <button 
                        className={
                            "py-1 w-full rounded-full font-bold " +
                            ( !selectedAnak || (paketBimbel.finalPrice > 0 ? !selectedPayChan : false) ? ' bg-gray-300 cursor-not-allowed ' : ' bg-red-500 text-white hover:bg-red-900 hover:shadow-lg ')
                            // ( !selectedAnak ? ' bg-gray-300 cursor-not-allowed ' : ' bg-red-500 text-white hover:bg-red-900 hover:shadow-lg ')
                        }
                        style={{
                            fontFamily: 'Nunito'
                        }}
                        onClick={() => {
                            onCheckout(
                                paketBimbel.idPaketBimbel, 
                                selectedAnak.idAnak, 
                                selectedPayChan ? selectedPayChan.code : null, 
                                selectedPayChan ? selectedPayChan.name : null,
                                selectedPaymentType === "credit" && paketBimbel.skemaCicilan ?
                                    paketBimbel.skemaCicilan.idSkemaCicilan
                                    :
                                    null
                            )
                        }}
                        disabled={!paketBimbel && !selectedAnak || (paketBimbel.finalPrice > 0 ? !selectedPayChan : false)}
                        // disabled={!selectedAnak}
                    >
                        {paketBimbel.finalPrice > 0 ? 'Lanjut Pembayaran' : 'Selesai'}
                    </button>
                </div>

            </div>
        )
    }

    const LoadBox = () => {
        return (
            <>
                <div
                    className="fixed z-90 top-0 left-0 w-screen h-screen bg-black opacity-50"
                >
                </div>
                <div className="absolute top-0 left-0 h-full w-full">
                    <div className="flex justify-center items-center w-full h-full">
                        <div className="bg-white p-10 z-90 rounded-lg space-y-5">
                            <div className="flex justify-center py-5">
                                <RiLoader5Fill className={"h-10 w-10 text-blue-500 animate-spin"} />
                            </div>
                            <div
                                className="font-bold"
                                style={{
                                    fontFamily: 'Nunito'
                                }}
                            >
                                Sedang memproses. Mohon tunggu.
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const navigate = useNavigate()

    const [isCheckingOut, setIsCheckingOut] = useState(false)
    const [transactionDetail, setTransactionDetail] = useState(null)
    const [mode, setMode] = useState('form') //form or success


    function onCheckout(idPaketBimbel, idAnak, paymentMethod, paymentChannel, idSkemaCicilan) {
        setIsCheckingOut(true)

        const payload = {
            idAnak : idAnak,
            idPaketBimbel : idPaketBimbel,
            paymentMethod : paymentMethod,
            paymentChannel : paymentChannel,
            idSkemaCicilan : idSkemaCicilan 
        }

        createTransaction(payload)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setMode('success')
                        setTransactionDetail(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(err => {})
            .finally(() => setIsCheckingOut(false))

    }

    return (
        <div
            className="flex justify-center"
            
        >
            <div
                className="space-y-5"
                style={{
                    width: "600px"
                }}
            >
                { isCheckingOut && <LoadBox />}
                {
                    mode === 'success' ?
                        <CheckoutSuccess
                            transactionDetail={transactionDetail}
                        />
                        :
                        (<>
                        <PaketBox 
                            isLoad={isGettingDetailPaket}
                            paketDetail={paketBimbel}
                        />
                        <AnakBox
                            isLoad={isGettingAnak}
                            anaks={anak}
                            selectedAnak={selectedAnak}
                            onSelectedAnak={anak => setSelectedAnak(anak)}
                            idProgram={paketBimbel ? paketBimbel.program.id : null}
                        />
                        {paketBimbel && paketBimbel.skemaCicilan && (
                            <PaymentTypeBox 
                                paketDetail={paketBimbel}
                                skemaCicilan={paketBimbel.skemaCicilan}
                                selectedPaymentType={selectedPaymentType}
                                setSelectedPaymentType={payType => setSelectedPaymentType(payType)}
                            />
                        )}

                        {paketBimbel && (
                            (paketBimbel.idPaketBimbel === 'PBM2307030001'
                                && selectedPaymentType === 'cash') ?
                                (<div className="w-full p-5 border rounded-lg shadow-lg space-y-3">
                                    <div className="text-center">
                                        Untuk pembayaran paket <b>SNBT+ Long Course</b> Lunas, silahkan menghubungi Humas kami di bawah ini:
                                    </div>
                                    <div className="flex justify-center">
                                        <a 
                                            className="text-center bg-green-600 text-white w-[300px] py-1 rounded-full shadow-lg"
                                            href="https://wa.me/6282215605265"
                                        >
                                        +62 822 15605265
                                        </a>
                                    </div>
                                    <div className="text-center">
                                        atau
                                    </div>
                                    <div className="flex justify-center">
                                        <a 
                                            className="text-center bg-green-600 text-white w-[300px] py-1 rounded-full shadow-lg"
                                            href="https://wa.me/6281214103806"
                                        >
                                        +62 812 14103806
                                        </a>
                                    </div>
                                </div>)
                            :
                            paketBimbel.finalPrice > 0 && (
                                <PaymentBox 
                                    isLoad={isGettingPayChan}
                                    payChans={payChans}
                                    selectedPayChan={selectedPayChan}
                                    onSelectedPayChan={payChan => setSelectedPayChan(payChan)}
                                />
                            )
                        )}


                        {paketBimbel && (
                            (paketBimbel.idPaketBimbel === 'PBM2307030001'
                                && selectedPaymentType === 'cash') ?
                                <div></div>
                                :
                                <SummaryBox 
                                    paketBimbel={paketBimbel}
                                    selectedAnak={selectedAnak}
                                    selectedPaymentType={selectedPaymentType}
                                    selectedPayChan={selectedPayChan}
                                    onCheckout={onCheckout}
                                />
                        )}
                        </>)
                }
            </div>
        </div>
    )
}

const Checkout = () => {
    const navigate = useNavigate()

    const { member } = useSelector(state => state.global)

    let { paketId } = useParams()

    useEffect(() => {
        if (!paketId) {
            navigate('/paket-bimbel')
        }

        window.scrollTo(0, 0)

    }, [])

    function isMemberAddressValid(member) {
        return member.provinsi && member.kabupaten && member.kecamatan && member.fullAddress && member.fullAddress !== ''
    }

    return (
        <div
            className='w-full pt-[100px] flex justify-center'
        >
            <Navbar />
            <div className='w-full'>
                {
                    member.id ?
                        isMemberAddressValid(member) ?
                            <CheckoutBox 
                                idPaketBimbel={paketId}
                            />
                            :
                            <InValidMemberAddress />
                        :
                        <UnSignedBox />
                }
                <div className="pt-60">
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Checkout