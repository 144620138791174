import React from 'react'
import Sidebar from '../components/Sidebar'

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';


const Dashboard = () => {

    ChartJS.register(
        RadialLinearScale,
        PointElement,
        LineElement,
        Filler,
        Tooltip,
        Legend
    )

    const exampleData = {
        labels: ['Math', 'English', 'Biology', 'History', 'Physic', 'Indonesia'],
        datasets: [
            {
                label: '# of Votes',
                data: [2, 9, 4, 10, 3, 3],
                backgroundColor: '#42a897',
                borderColor: '#00CBA9',
                borderWidth: 1,
            },
        ],
    }

    return (
        <div className='flex'>
            <Sidebar />
            <div className='p-7 text-2xl font-semibold flex-1 h-screen'>
                <h1>Dashboard</h1>
                <div>
                    <div className='flex'>
                        <div className='lg:w-[500px] md:w-[250px] hidden md:flex shadow-xl'>
                            <Radar data={exampleData} />
                        </div>
                        <div className=''>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Dashboard