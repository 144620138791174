import axios from "axios"
import { handleResponse200 } from '../utils/HttpUtils'
import { notifyError } from "../utils/Notification"

export const getSystemProfile = async () => {
    const finalUrl = 
        window._HOST + "public/system-profile"

    const response =
        await axios.get(finalUrl)
    
    return response.data
}

export const getAnak = async () => {
    const finalUrl =
        window._HOST + "member/anak/data"

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const getAnakEvaluasi = async (idanak) => {
    const finalUrl =
        window._HOST + "member/anak-pahamify-evaluation/" + idanak

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const getKelasIdLabel = async () => {
    const finalUrl =
        window._HOST + "member/kelas/id-label"

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const getSekolahIdLabel = async () => {
    const finalUrl =
        window._HOST + "member/sekolah/id-label"

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const getProgramIdLabel = async (idKelas) => {
    const finalUrl =
        window._HOST + "member/program/id-label/" + idKelas

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const login = async (payload) => {
    const finalUrl =
        window._HOST + "member/login"

    const response =
        await axios.post(finalUrl, payload)

    return response.data
}

export const getProfile = async () => {
    const finalUrl =
        window._HOST + "member/profile"
    
    const response =
        await axios.get(finalUrl)
    
    return response.data
}

export const getLatestTransaction = async () => {
    const finalUrl =
        window._HOST + "member/latest-transaction"
    
    const response =
        await axios.get(finalUrl)
    
    return response.data
}

export const getCicilan = async () => {
    const finalUrl =
        window._HOST + "member/cicilan"
    
    const response =
        await axios.get(finalUrl)
    
    return response.data
}

export const getCicilanTransactions = async (idCicilan) => {
    const finalUrl =
        window._HOST + "member/cicilan-transaction/" + idCicilan
    
    const response =
        await axios.get(finalUrl)
    
    return response.data
}

export const getTransactions = async () => {
    const finalUrl =
        window._HOST + "member/transaction-data"
    
    const response =
        await axios.get(finalUrl)
    
    return response.data
}

export const getTransactionsInstructions = async (idTransaction) => {
    const finalUrl =
        window._HOST + "member/payment-instruction/"+idTransaction
    
    const response =
        await axios.get(finalUrl)
    
    return response.data
}

export const getTransactionDetail = async (idTransaction) => {
    const finalUrl =
        window._HOST + "member/transaction-data"
    
    const response =
        await axios.get(finalUrl)
    
    return response.data
}

export const changePassword = async (payload) => {
    const finalUrl =
        window._HOST + "member/change-password"
    
    const response =
        await axios.put(finalUrl, payload)
    
    return response.data
}

export const register = async (payload) => {
    const finalUrl =
        window._HOST + "member/registration"

    const response =
        await axios.post(finalUrl, payload)

    return response.data
}

export const logOut = async () => {
    const finalUrl =
        window._HOST + "member/logout"

    const response =
        await axios.post(finalUrl, {})

    return response.data
}

export const update = async (payload) => {
    const finalUrl =
        window._HOST + "member/profile"

    const response =
        await axios.put(finalUrl, payload)

    return response.data
}

export const registerAnak = async (payload) => {
    const finalUrl =
        window._HOST + "member/anak/registration"

    const response =
        await axios.post(finalUrl, payload)

    return response.data
}

export const updateAnak = async (payload) => {
    const finalUrl =
        window._HOST + "member/anak/profile"

    const response =
        await axios.put(finalUrl, payload)

    return response.data
}

export const deleteAnak = async (idAnak) => {
    const finalUrl =
        window._HOST + "member/anak/delete/" + idAnak 

    const response =
        await axios.delete(finalUrl)

    return response.data
}

export const checkIsAuthenticated = ({
    onStartChecking,
    onFinishChecking,
    onAuthenticated,
    onUnAuthenticated
}) => {

    onStartChecking()

    getProfile()
        .then(res => {
            handleResponse200({
                httpResponse: res,
                onSuccess: payload => {
                    onAuthenticated(payload)
                },
                onRecovFailure: errors => errors.forEach(err => {
                    notifyError(err)
                }),
                onUnAuth: error => {
                    onUnAuthenticated()
                },
                onTechnicalError: errors => errors.forEach(err => {
                    notifyError(err)
                })
            })
        })
        .catch(error => {})
        .finally(() => onFinishChecking())

}

export const getProvinsi = async () => {
    const finalUrl =
        window._HOST + "public/provinsi" 

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const getKabupaten = async (idProvinsi) => {
    const finalUrl =
        window._HOST + "public/kabupaten" + (idProvinsi ? "?id-provinsi="+idProvinsi : "" ) 

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const getKecamatan = async (idKabupaten) => {
    const finalUrl =
        window._HOST + "public/kecamatan" + (idKabupaten ? "?id-kabupaten="+idKabupaten : "" ) 

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const verifiedEmail = async (email, token) => {
    const finalUrl =
        window._HOST + "member/mail-verification/" + email + "/" + token

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const forgetPassword = async (email) => {
    const finalUrl =
        window._HOST + "member/forget-password/" + email 

    const response =
        await axios.put(finalUrl)

    return response.data
}

export const resetPassword = async (payload) => {
    const finalUrl =
        window._HOST + "member/reset-password/" 

    const response =
        await axios.put(finalUrl, payload)

    return response.data
}

export const getTestimony = async () => {
    const finalUrl =
        window._HOST + "public/testimony/" 

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const getPresensiDates = async (idAnak) => {
    const finalUrl =
        window._HOST + "member/anak-presensi-date/" + idAnak
    
    const response =
        await axios.get(finalUrl)

    return response.data
}

export const getPresensiDetail = async (idAnak, date) => {
    const finalUrl = 
        window._HOST + "member/anak-presensi/" + idAnak + "/" + date
    
    const response =
        await axios.get(finalUrl)
    
    return response.data
}

export const getPresensiSummary = async (idAnak) => {
    const finalUrl = 
        window._HOST + "member/anak-presensi-summary/" + idAnak
    
    const response =
        await axios.get(finalUrl)
    
    return response.data
}