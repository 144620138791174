import { MenuIcon, XIcon } from '@heroicons/react/outline';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const NavbarUser = () => {
    const [dropdownMenu, setDropdownMenu] = useState(false);

    const handleClick = () => setDropdownMenu(!dropdownMenu);

    const handleClose = () => setDropdownMenu(!dropdownMenu);

    return (
        <div className="w-screen h-[80px] z-10 bg-slate-400 fixed drop-shadow-lg">
            <div className="px-2 flex justify-between items-center w-full h-full">
                <div className="flex items-center">
                    <Link to="/">
                        <h1 className="text-3xl font-bold mr-4 text-white">BIMBEL ALC</h1>
                    </Link>
                    <ul className="hidden lg:flex text-white">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/service">Pilih Program</Link>
                        </li>
                        <li>
                            <Link to="/userPackage">Paket Saya</Link>
                        </li>
                        <li>
                            <Link to="/transactionHistory">Daftar Transaksi</Link>
                        </li>
                    </ul>
                </div>
                <div className="hidden lg:flex pr-4">
                    {/* <Link to="/login">
                        <button className="border-none bg-transparent text-gray-100 mr-4 mt-3">
                            Login
                        </button>
                    </Link> */}
                    <button className="px-08 py-3 px-4">Logout</button>
                </div>
                <div className="lg:hidden mr-4" onClick={handleClick}>
                    {!dropdownMenu ? <MenuIcon className="w-5" /> : <XIcon className="w-5" />}

                </div>
            </div>

            <ul className={!dropdownMenu ? 'hidden' : "absolute bg-slate-400 w-full px-8"}>
                <li className="border-b-2 border-zinc-300 w-full text-white">
                    <Link to="/">
                        <span onClick={handleClose}>Home</span>
                    </Link>
                </li>
                <li className="border-b-2 border-zinc-300 w-full text-white">
                    <Link to="/service">
                        <span onClick={handleClose}>Pilih Program</span>
                    </Link>
                </li>
                <li className="border-b-2 border-zinc-300 w-full text-white">
                    <Link to="/userPackage">
                        <span onClick={handleClose}>Paket Saya</span>
                    </Link>
                </li>
                <li className="border-b-2 border-zinc-300 w-full text-white">
                    <Link to="/transactionHistory">
                        <span onClick={handleClose}>Daftar Transaksi</span>
                    </Link>
                </li>
                <div className="flex flex-col my-4">
                    {/* <Link to="/login">
                        <button className="bg-transparent text-gray-100 px-8 py-3 mb-4 w-full">Login</button>
                    </Link> */}
                    <button className="px-8 py-3">Logout</button>
                </div>
            </ul>
        </div>
    );
}

export default NavbarUser