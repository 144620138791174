import { useState } from "react"
import { useEffect } from "react"
import { BsChevronLeft } from "react-icons/bs"
import { RiLoader5Fill } from "react-icons/ri"
import { useNavigate, useParams } from "react-router"
import { getAnakEvaluasi } from "../../repository/User"
import { handleResponse200 } from "../../utils/HttpUtils"
import { notifyError } from "../../utils/Notification"

const UserAnakPahamify = () => {

    const navigate = useNavigate()

    const { idanak } = useParams()

    const [isLoad, setIsLoad] = useState(false)

    const [evals, setEvals] = useState([])

    const evalDummies = [
        {
            sessionLabel: "Test",
            fileUrl: "https://bimbelalc.id"
        },
        {
            sessionLabel: "Test 2",
            fileUrl: "https://bimbelalc.id"
        }
    ]

    useEffect(() => {
        if (!idanak) return;

        setIsLoad(true)

        getAnakEvaluasi(idanak)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setEvals(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {

            })
            .finally(() => {
                setIsLoad(false)
            })
    }, [idanak])

    const LoadBox = () => {
        return (
            <>
                <div
                    className="fixed z-90 top-0 left-0 w-screen h-screen bg-black opacity-50"
                    style={{
                        zIndex: 90
                    }}
                >
                </div>
                <div className="absolute top-0 left-0 h-full w-full">
                    <div className="flex justify-center items-center w-full h-full">
                        <div 
                            className="bg-white p-10 z-90 rounded-lg space-y-5"
                            style={{
                                zIndex: 90
                            }}
                        >
                            <div className="flex justify-center py-5">
                                <RiLoader5Fill className={"h-10 w-10 text-blue-500 animate-spin"} />
                            </div>
                            <div
                                className="font-bold"
                                style={{
                                    fontFamily: 'Nunito'
                                }}
                            >
                                Sedang memproses. Mohon tunggu.
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    
    return (
        <div
            className='w-full h-screen bg-second-color flex justify-center'
        >
            <div
                className='w-[450px] h-full'
            >
                {isLoad && <LoadBox />}
                <div
                    className='w-full h-full bg-white rounded-lg'
                >
                    <div 
                        className="flex justify-between px-6 font-bold bg-blue-800 text-white rounded-tl-lg rounded-tr-lg rounded-bl-2xl rounded-br-2xl pt-12 pb-10"
                        style={{  
                            fontFamily: 'Nunito',
                            height: "200px"
                        }}
                    >
                        <div className="">
                            <button
                                onClick={() => navigate('/member/anak')}
                            >
                                <BsChevronLeft className="h-6 w-6"/>
                            </button>
                        </div>
                        <div
                            style={{
                                fontSize: '20px',
                            }}
                        >
                            Data Anak
                        </div>
                        <div/>
                    </div>
                    <div 
                        className="px-5 "
                    >
                        {
                            evals.length === 0 ?
                                (<div className="pt-5 text-center">
                                    Tidak ada data
                                </div>)
                                :
                                (<div
                                    className="p-3 bg-white border rounded-lg shadow-lg z-10 space-y-3 divide-y divide-gray-300"
                                    style={{
                                        marginTop: '-20px'
                                    }}
                                >
                                    { evals.map(e => (
                                        <div
                                            className="p-2 space-y-1"
                                            style={{ fontFamily: 'Nunito', fontSize: '15px' }}
                                        >
                                            <div className="font-bold">{e.sessionLabel}</div>
                                            <div>
                                                <a href={e.fileUrl} target={"_blank"}>
                                                <button 
                                                    className="py-1.5 px-5 bg-blue-600 hover:bg-blue-400 text-white rounded-md"
                                                    style={{ fontSize: '15px' }}
                                                >
                                                    Download
                                                </button>
                                                </a>
                                            </div>
                                        </div>
                                    )) }
                                </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default UserAnakPahamify;