import { useState } from "react"
import { BsMailbox, BsMailbox2 } from "react-icons/bs"
import { RiLoader5Fill } from "react-icons/ri"
import { useSelector } from "react-redux"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import { forgetPassword } from "../repository/User"
import { handleResponse200 } from "../utils/HttpUtils"
import { notifyError } from "../utils/Notification"
import { isDesktop, isPhone } from "../utils/PlatformHelper"

const ResetPasswordSuccess = () => {
    return (
        <div
            className='w-full bg-white rounded-lg space-y-5 px-8 py-10'
        >
            <div
                className='text-center font-bold'
                style={{ 
                    fontFamily: "Nunito",
                    fontSize: "20px"
                }}
            >
                Reset Password
            </div>
            <div className="flex justify-center py-2">
                <BsMailbox2 className="h-20 w-20 text-red-500"/>
            </div>
            <div
                className='text-center flex flex-col'
            >
                <label>Email berisi url reset password sudah terkirim</label>
                <label>Silahkan cek email anda untuk melanjutkan proses.</label>
            </div>

        </div>
    )
}

const ForgetPassword = () => {
    const { member, screenSize } = useSelector(state => state.global)

    const [email, setEmail] = useState('')

    const [isProcessing, setIsProcessing] = useState(false)

    const [successReset, setSuccessReset] = useState(false)

    function onReset(emailStr) {
        setIsProcessing(true)

        forgetPassword(emailStr)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setSuccessReset(true)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        notifyError('Forbidden. Please Login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {
                notifyError('Unexpected Error. Please Contact Developer')
            })
            .finally(() => setIsProcessing(false))
    }

    return (
        <>
        <div
            className='w-full h-screen bg-second-color flex justify-center'
        >
            <Navbar />
            <div
                className={
                    'h-full flex items-center '+
                    (isPhone(screenSize.width) ? " px-5 " : "")
                }
                style={{
                    width: isDesktop(screenSize.width) ? '450px' : '100%'
                }}
            >
                <div
                    className='w-full space-y-2'
                >
                    {
                        successReset ?
                            (<ResetPasswordSuccess />)
                            :
                            (<div
                                className='w-full bg-white rounded-lg space-y-5 px-8 py-10'
                            >
                                <div
                                    className='text-center font-bold'
                                    style={{ 
                                        fontFamily: "Nunito",
                                        fontSize: "20px"
                                    }}
                                >
                                    Lupa Password?
                                </div>
                                <div className="space-y-3">
                                    <div>
                                        <input 
                                            className="w-full p-2 border border-gray-300 bg-gray-100 outline-none"
                                            placeholder="Masukan Email Anda"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            disabled={isProcessing}
                                        />
                                    </div>
                                    <div className="flex justify-center">
                                        <button
                                            className="w-60 py-2 bg-red-400 text-white hover:bg-red-700 flex justify-center"
                                            onClick={() => onReset(email)}
                                            disabled={isProcessing}
                                        >
                                            {
                                                isProcessing ?
                                                    (<RiLoader5Fill className="h-6 w-6 animate-spin"/>)
                                                    :
                                                    ("Reset Password")
                                            }
                                        </button>
                                    </div>
                                </div>
        
                            </div>)
                    }
                </div>

            </div>
        </div>
        <Footer />
        </>
    )
}

export default ForgetPassword