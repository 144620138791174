import { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { RiLoader5Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { resetPassword } from "../repository/User";
import { handleResponse200 } from "../utils/HttpUtils";
import { notifyError } from "../utils/Notification";
import { isDesktop, isPhone } from "../utils/PlatformHelper";

const ResetPasswordSuccess = () => {
    return (
        <div
            className='w-full bg-white rounded-lg space-y-5 px-8 py-10'
        >
            <div
                className='text-center font-bold'
                style={{ 
                    fontFamily: "Nunito",
                    fontSize: "20px"
                }}
            >
                Reset Password Berhasil
            </div>
            <div className="flex justify-center py-2">
                <BsCheckLg className="h-20 w-20 text-green-700"/>
            </div>
            <div
                className='text-center flex flex-col items-center'
            >
                <label>Reset password berhasil</label>
                <label>Silahkan klik tombol dibawah untuk login</label>
                <Link
                    to={"/login"}
                >
                    <button
                        className="w-60 py-2 bg-blue-400 text-white hover:bg-blue-700 flex justify-center my-3"
                    >
                        Login
                    </button>
                </Link>
            </div>

        </div>
    )
}

const ResetPassword = () => {
    const { memberId, token } = useParams()

    const { member, screenSize } = useSelector(state => state.global)

    const [newPassword, setNewPassword] = useState('')

    const [isProcessing, setIsProcessing] = useState(false)

    const [isPasswordShown, setIsPasswordShown] = useState(false)

    const [isResetSuccess, setIsResetSuccess] = useState(false)

    function onResetPassword(newPasswordStr) {
        setIsProcessing(true)

        const payload = {
            memberId : memberId, 
            token : token,
            newPassword : newPasswordStr 
        }
        
        resetPassword(payload)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setIsResetSuccess(true)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        notifyError('Forbidden. Please Login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {
                notifyError('Unexpected Error. Please Contact Developer')
            })
            .finally(() => setIsProcessing(false))
    }

    return (
        <>
        <div
            className='w-full h-screen bg-second-color flex justify-center'
        >
            <Navbar />
            <div
                className={
                    'h-full flex items-center '+
                    (isPhone(screenSize.width) ? " px-5 " : "")
                }
                style={{
                    width: isDesktop(screenSize.width) ? '450px' : '100%'
                }}
            >
                <div
                    className='w-full space-y-2'
                >
                    
                    {
                        isResetSuccess ?
                            (<ResetPasswordSuccess />)
                            :
                            (<div
                                className='w-full bg-white rounded-lg space-y-5 px-8 py-10'
                            >
                                <div
                                    className='text-center font-bold'
                                    style={{ 
                                        fontFamily: "Nunito",
                                        fontSize: "20px"
                                    }}
                                >
                                    Atur Password Baru
                                </div>
                                <div className="space-y-3">
                                    <div>
                                        <div
                                            className={
                                                'w-full py-2 px-2 border-2 bg-blue-100 rounded-md outline-none font-bold flex ' +
                                                (newPassword !== null && newPassword !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                            }
                                        >
                                            <input 
                                                type={isPasswordShown ? 'text' : 'password'}
                                                className={
                                                    'w-full bg-blue-100 rounded-md outline-none font-bold ' +
                                                    (newPassword !== null && newPassword !== '' ? ' bg-blue-100 ' : ' bg-red-100 ')
                                                }
                                                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                                                placeholder='Masukan Password Baru di sini'
                                                value={newPassword}
                                                onChange={e => setNewPassword(e.target.value)}
                                                disabled={isProcessing}
                                            />
                                            <button
                                                onClick={() => setIsPasswordShown(!isPasswordShown)}
                                            >
                                                { isPasswordShown ?
                                                    <AiFillEye className='h-6 w-6 text-blue-700'/>
                                                    :
                                                    <AiFillEyeInvisible className='h-6 w-6 text-blue-700'/>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <button
                                            className="w-60 py-2 bg-red-400 text-white hover:bg-red-700 flex justify-center"
                                            onClick={() => onResetPassword(newPassword)}
                                            disabled={isProcessing}
                                        >
                                            {
                                                isProcessing ?
                                                    (<RiLoader5Fill className="h-6 w-6 animate-spin"/>)
                                                    :
                                                    ("Reset Password")
                                            }
                                        </button>
                                    </div>
                                </div>
        
                            </div>)
                    }
                </div>

            </div>
        </div>
        <Footer />
        </>
    )
}

export default ResetPassword;