import { useEffect, useRef, useState } from "react"
import { AiOutlineMenu } from "react-icons/ai"
import { BsCheckLg } from "react-icons/bs"
import { RiLoader5Fill } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"
import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"
import { getProgramDetail, getPublicPaketBimbel, getPublicProgram } from "../../repository/PaketBimbel"
import { handleResponse200 } from "../../utils/HttpUtils"
import { notifyError } from "../../utils/Notification"
import { isDesktop, isPhone } from "../../utils/PlatformHelper"

const TitleBox = ({
    program,
    isLoad
}) => {

    const { screenSize } = useSelector(state => state.global)

    return (
        <div
            className="bg-second-color flex justify-center pt-[150px] pb-[80px]"
            style={{
                minHeight: '300px'
            }}
        >
            <div
                className=" px-10 flex"
                style={{ width: "1260px" }}
            >
                {
                    !isLoad ?
                        program ?
                            (<div 
                                className={
                                    "h-full w-full " +
                                    (isDesktop(screenSize.width) ? " flex items-center space-x-20 " : " space-y-10 ")
                                }
                            >
                                <div 
                                    className={
                                        "space-y-5 " +
                                        (isDesktop(screenSize.width) ? " w-[60%] " : "")
                                    }
                                >
                                    <div
                                        className="text-white font-bold flex items-center"
                                        style={{
                                            fontSize: '36px',
                                            fontFamily: 'Nunito'
                                        }}
                                    >
                                        {program.namaProgram}
                                    </div>
                                    <div 
                                        className="text-white font-bold"
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Nunito'
                                        }}
                                        dangerouslySetInnerHTML={{ __html: program.description}}
                                    />
                                        {/* {program.description}
                                    </div>    */}

                                </div>
                                <div className={ isPhone(screenSize.width) ? " flex justify-center " : "" }>
                                    <img src={program.iconBase64} width="200px"/>
                                </div>
                            </div>)
                            :
                            (<div
                                className="text-white font-bold flex items-center py-16"
                                style={{
                                    fontSize: '36px',
                                    fontFamily: 'Nunito'
                                }}
                            >
                                Paket Belajar Bimbel ALC
                            </div>)
                        :
                        (<div
                            className="w-full flex justify-center items-center"
                        >
                            <RiLoader5Fill />
                        </div>)
                }
                
            </div>
        </div>
    )
}

const ProgramBox = ({
    onSelectedProgram
}) => {

    const { screenSize } = useSelector(state => state.global)

    const [programs, setPrograms] = useState([])

    const [isGettingData, setIsGettingData] = useState(false)

    const [isDropdown, setIsDropdown] = useState(false)

    useEffect(() => {
        setIsGettingData(true)

        getPublicProgram()
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setPrograms(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {})
            .finally(() => setIsGettingData(false))
    }, [])

    const [selectedProgram, setSelectedProgram] = useState(null)

    useEffect(() => {
        onSelectedProgram(selectedProgram)
    }, [selectedProgram])

    const ItemBox = ({
        item,
        isActive,
        onClick
    }) => {
        return (
            <button
                className={
                    "py-2 px-8 rounded-full font-bold cursor-pointer " +
                    (isActive ? " bg-blue-800 text-white " : " bg-blue-100 text-blue-900 ")
                }
                style={{
                    fontFamily: 'Nunito'
                }}
                onClick={onClick}
            >
                {item}
            </button>
        )
    }

    return (
        <div className="relative">
        <div
            className="flex justify-center"
            style={{
                marginTop: '-50px'
            }}
        >
            <div
                className="rounded-lg p-6 flex items-center justify-center bg-white shadow-lg"
                style={{ 
                    width: "1260px", 
                    zIndex: 1
                }}
            >
                {
                    isGettingData ?
                        (
                            <RiLoader5Fill className={"h-8 w-8 text-blue-500 animate-spin"} />
                        )
                        :
                        (
                            <>
                            <div
                                className={
                                    "font-bold text-blue-900 " +
                                    (isDesktop(screenSize.width) ? " w-1/3 " : " w-full ")
                                }
                                style={{
                                    fontSize: '20px',
                                    fontFamily: 'Nunito'
                                }}
                            >
                                Kategori Paket
                            </div>

                            <div
                                className="w-full flex justify-end space-x-4"
                            >
                                {
                                    isPhone(screenSize.width) ?
                                        (<div>
                                            <button
                                                className={
                                                    "h-12 w-12 rounded-full  flex justify-center items-center " +
                                                    (isDropdown ? " bg-blue-600 text-white " : "")
                                                }
                                                onClick={() => setIsDropdown(!isDropdown)}
                                            >
                                                <AiOutlineMenu className="h-6 w-6"/>
                                            </button>
                                        </div>)
                                        :
                                        (
                                            <>
                                                <ItemBox 
                                                    item={"Semua"}
                                                    isActive={selectedProgram === null}
                                                    onClick={() => setSelectedProgram(null)}
                                                />
                                                {programs.map(p => (
                                                    <ItemBox
                                                        key={p.idProgram}
                                                        item={p.namaProgram}
                                                        isActive={selectedProgram ? selectedProgram.idProgram === p.idProgram : false}
                                                        onClick={() => setSelectedProgram(p)}
                                                    />
                                                ))}
                                            </>
                                        )
                                }
                            </div>
                            </>
                        )
                }
            </div>
        </div>
        {isDropdown && (
            <div 
                className="w-full bg-white rounded-lg absolute left-0 shadow-lg border py-5"
                style={{
                    top: 100,
                    zIndex: 99
                }}
            >
                <div className="flex flex-col space-y-3">
                    <ItemBox 
                        item={"Semua"}
                        isActive={selectedProgram === null}
                        onClick={() => {
                            setSelectedProgram(null)
                            setIsDropdown(!isDropdown)
                        }}
                    />
                    {programs.map(p => (
                        <ItemBox
                            key={p.idProgram}
                            item={p.namaProgram}
                            isActive={selectedProgram ? selectedProgram.idProgram === p.idProgram : false}
                            onClick={() => {
                                setSelectedProgram(p)
                                setIsDropdown(!isDropdown)
                            }}
                        />
                    ))}
                </div>

            </div>
        )}
        </div>
    )
}

const PaketList = ({
    idProgram,
    onShowDeadlineSnbt
}) => {
    const { screenSize } = useSelector(state => state.global)

    const navigate = useNavigate() 

    const [pakets, setPakets] = useState([])

    const [isGettingData, setIsGettingData] = useState(false)

    const currentProg = useRef(idProgram)

    useEffect(() => {
        currentProg.current = idProgram //ambil yang terbaru karena default awal selalu null
        
        setIsGettingData(true)

        getPublicPaketBimbel(idProgram)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        if (currentProg.current == idProgram) {
                            setPakets(payload)
                        }
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {})
            .finally(() => { 
                setIsGettingData(false) 
            })
    }, [idProgram])

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
          { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
    }

    const BenefitItem = ({
        benefit
    }) => {
        return (
            <div 
                className="flex items-start space-x-3"
            >
                <BsCheckLg className="text-blue-700 "/>
                <label 
                    className="flex-1 text-gray-600 text-justify"
                    style={{
                        fontSize: '14px',
                        fontFamily: 'Nunito'
                    }}
                >
                    {benefit}
                </label>
            </div>
        )
    }


    const PaketItem = ({
        paket,
        onSelected
    }) => {

        return (
            <div
                className="relative border border-blue-300 rounded-lg hover:shadow-lg hover:border-blue-700 space-y-3"
                style={{

                }}
            >
                <div
                    className="text-center font-bold pt-2 py-2 rounded-lg rounded-lg text-white"
                    style={{
                        fontFamily: 'Nunito',
                        fontSize: '17px',
                        backgroundColor: paket.color
                    }}
                >
                    {paket.namaPaketBimbel}
                </div>
                <div className="flex justify-center py-2">
                    <div style={{ height: '260px' }}>
                        <img src={paket.iconBase64} width="200px"/>
                    </div>
                </div>
                <div
                    className="px-5 text-gray-600 py-2 scrollbar-thin scrollbar-thumb-blue-700 scrollbar-track-blue-300 overflow-y-scroll"
                    style={{
                        fontFamily: 'Nunito',
                        fontSize: '15px',
                        height: '200px'
                    }}
                >
                    {paket.description}
                </div>
                <div
                    className="px-5"
                    style={{
                        fontFamily: 'Nunito'
                    }}
                >
                    <div
                        className="font-bold text-gray-600"
                        style={{
                            fontSize: '16px'
                        }}
                    >
                        Harga Paket
                    </div>
                    <div 
                        className="font-bold"
                        style={{
                            fontSize: '20px'
                        }}
                    >
                        {formatRupiah(paket.price)}
                    </div>
                    {paket.skemaCicilan && (
                        <div
                            className="flex"
                            style={{
                                fontSize: '14px'
                            }}
                        >
                            <div className="px-3 py-1 rounded-full bg-green-100 text-green-700 font-bold text-center">
                                Cicilan Tersedia
                            </div>
                        </div>
                    )}
                    {paket.discount && (
                        <div
                            className="flex py-2"
                            style={{
                                fontSize: '14px'
                            }}
                        >
                            <div className="px-3 py-1 rounded-full bg-red-100 text-red-700 font-bold text-center">
                                Diskon Lunas
                            </div>
                        </div>
                    )}

                </div>
                <div
                    className="w-full border-t border-b border-gray-300 py-3 px-5 scrollbar-thin scrollbar-thumb-blue-700 scrollbar-track-blue-300 overflow-y-scroll space-y-3 scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                    style={{
                        height: '200px',
                        marginBottom: '70px'
                    }}
                >
                    {paket.benefits.map(b => (
                        <BenefitItem
                            key={b}
                            benefit={b} 
                        />
                    ))}
                </div>
                <div
                    className="absolute bottom-0 left-0 right-0 flex justify-center py-2 pb-4 px-5"
                >
                    <button
                        className="w-full py-2 rounded-full bg-red-500 text-white font-bold"
                        style={{
                            fontSize: '14px',
                            fontFamily: 'Nunito'
                        }}
                        onClick={() => {
                            // onSelected();
                            if (paket.idPaketBimbel == "PBM2307030001"
                                || paket.idPaketBimbel == "PBM2307030002"
                                || paket.idPaketBimbel == "PBM2307030003"
                                || paket.idPaketBimbel == "PBM2206210002") {
                                onShowDeadlineSnbt(paket.idPaketBimbel);
                            }
                            else {
                                onSelected();
                            }
                        }}
                    >
                        Pilih Paket
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div
            className="flex justify-center"
            style={{
                marginTop: '-50px'
            }}
        >
            {
                isGettingData ?
                    (
                        <div
                            className="flex justify-center"
                            style={{
                                marginTop: '-50px',
                                paddingTop: '150px',
                                width: "1260px"
                            }}
                        >
                            <RiLoader5Fill className={"h-8 w-8 text-blue-500 animate-spin"} />
                        </div>
                    )
                    :
                    (<div 
                        className={
                            "w-full grid "+
                            (isDesktop(screenSize.width) ? " px-10 grid-cols-4 gap-x-8 gap-y-5 " : "") +
                            (isPhone(screenSize.width) ? " px-2 grid-cols-1 gap-y-3 " : "")
                        }
                        style={{
                            marginTop: '0px',
                            paddingTop: '150px',
                            width: "1260px"
                        }}
                    >
        
                        { 
                            pakets.map(p => (
                                <PaketItem 
                                    key={p.idPaketBimbel}
                                    paket={p}
                                    onSelected={() => navigate('/checkout/'+p.idPaketBimbel)}
                                />
                            ))   
                        }
                    </div>)
            }

        </div>
    )
}

const PaketBimbel = () => {

    const { idProgram } = useParams()

    const navigate = useNavigate()

    const [selectedProgram, setSelectedProgram] = useState(null)

    const [isGettingProgram, setIsGettingProgram] = useState(false)

    const [deadlineSnbtShown, setDeadlineSnbtShown] = useState(false)
    const [selectedSnbt, setSelectedSnbt] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        
        // if (idProgram) {
        //     onGetDetailProgram()
        //     if (idProgram == "PRG2205230001" || idProgram == "PRG2307030001") {
        //         setDeadlineSnbtShown(true)
        //     }
        // }
    }, [idProgram])

    function onGetDetailProgram() {
        setIsGettingProgram(true)

        getProgramDetail(idProgram)
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        if (payload) {
                            setSelectedProgram(payload)
                        }
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {})
            .finally(() => { setIsGettingProgram(false) })
    }

    return (
        <div
            className='w-full flex justify-center'
        >
            { deadlineSnbtShown && (
                <>
                <div className="fixed top-0 right-0 bottom-0 left-0 bg-black z-10 opacity-50">
                </div>
                <div className="fixed top-0 right-0 bottom-0 left-0 z-10">
                    <div className="w-full h-full flex justify-center items-center">
                        <div className="p-5 rounded-lg bg-white w-[400px]" style={{ width: "600px" }}>
                            <p className="text-center font-bold">
                                PEMBERITAHUAN
                            </p>
                            <p className="text-center pt-2">
                            Pembelian Paket <b>Program Sukses SNBT+2024</b> (Kelas 12 SMA) Bimbel ALC akan <b>ditutup pada Ahad, 30 Juli 2023, pukul 00.00 WIB</b>, dan akan <b>dibuka kembali pada Rabu, 02 Agustus 2024 pukul 09.00 WIB</b>
                            </p>
                            <div className="flex justify-center mt-5">
                                <button 
                                    className="p-2 px-10 rounded-lg bg-blue-500 text-white"
                                    onClick={() => {
                                        if (selectedSnbt) {
                                            navigate('/checkout/'+selectedSnbt)  
                                        } 
                                        else {
                                            setDeadlineSnbtShown(false)   
                                        }
                                    }}
                                >
                                    {selectedSnbt ? "LANJUTKAN" : "OK"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
            <Navbar />
            <div className='w-full'>
                <TitleBox
                    program={idProgram ? selectedProgram : null}
                    isLoad={isGettingProgram}
                />
                { !idProgram && (
                    <ProgramBox 
                        onSelectedProgram={program => setSelectedProgram(program)}
                    />
                )}
                <PaketList 
                    idProgram={selectedProgram ? selectedProgram.idProgram : null}
                    onShowDeadlineSnbt={(idPaketBimbel) => {
                        navigate('/checkout/'+idPaketBimbel)
                        // if (idProgram == "PRG2205230001" || idProgram == "PRG2307030001") {
                        //     navigate('/checkout/'+idPaketBimbel)
                        // }
                        // else {
                        //         setDeadlineSnbtShown(true)
                        //         setSelectedSnbt(idPaketBimbel)

                        // }
                    }}
                />
                {idProgram && (
                    <div className="w-full flex justify-center pt-10" style={{ fontFamily: 'Nunito' }}>
                        <Link to={"/paket-bimbel"}>
                            <button className="px-10 py-2 rounded-full bg-red-500 text-white font-bold hover:bg-red-700 hover:shadow-lg">
                                Lihat Semua Paket
                            </button>
                        </Link>
                    </div>
                )}
                <div className="pt-20">
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default PaketBimbel