// import { CheckCircleIcon } from '@heroicons/react/outline'
import React from 'react'
import NavbarUser from '../../components/NavbarUser'
import camp from '../../assets/camp.png'

const UserPackage = () => {

    const dataUserPackage = [
        {
            idUserPackage: "idup11111",
            packageName: "Paket Bimbel Gold SMP",
            jumlahCicilan: 3,
            jumlahCicilanSudahDibayar: 1,
            tanggalJatuhTempo: '2022-05-01'
        },
        {
            idUserPackage: "idup22222",
            packageName: "Paket Bimbel Silver SMP",
            jumlahCicilan: 0,
            jumlahCicilanSudahDibayar: 0,
            tanggalJatuhTempo: '2019-04-01'
        },
        {
            idUserPackage: "idup33333",
            packageName: "Paket Bimbel Silver SMP",
            jumlahCicilan: 0,
            jumlahCicilanSudahDibayar: 0,
            tanggalJatuhTempo: '2022-04-20'
        },
        {
            idUserPackage: "idup44444",
            packageName: "Paket Bimbel Silver SMP",
            jumlahCicilan: 0,
            jumlahCicilanSudahDibayar: 0,
            tanggalJatuhTempo: '2023-04-01'
        }
    ]

    const getCurrentDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }

    const today = getCurrentDate();

    return (
        <>
            <NavbarUser />
            <div className='w-full bg-slate-600 pb-[120px] pt-[75px]'>
                <div className='rounded p-2'>
                    <p className='text-xl pt-5 text-white font-bold text-center'>Paket Saya</p>
                </div>
                <div className='relative h-[505px] lg:h-[675px] overflow-x-auto mx-2 md:mx-6 lg:mx-auto bg-white text-center max-w-[1240px] items-center shadow-lg rounded'>
                    <div className='bg-white text-slate-900 pt-4 pl-1 lg:p-4 lg:m-25 rounded-xl shadow-2xl'>
                        <div className='grid'>
                            {dataUserPackage.map(UserPackage => (
                                <div
                                    key={UserPackage.idUserPackage}
                                    className='border-2 relative shadow-xl border-gray-500 md:p-5 md:m-5 m-1 pt-2 pl-2 rounded-lg'
                                >
                                    <div className="flex flex-col lg:justify-between">
                                        <div>
                                            <h2 className='md:text-xl text-base text-left text-main-color font-bold border-b-2 border-black'>{UserPackage.packageName}</h2>
                                            <p className='text-xs md:text-base text-left text-gray-500 font-semibold mb-3'>
                                                {UserPackage.jumlahCicilan <= 0 ? "Tipe Paket: Bayar Langsung Lunas" : "Tipe Paket: Cicilan" + UserPackage.jumlahCicilan + " Kali"}
                                                <span className='text-red-400'>{UserPackage.jumlahCicilan > 0 && " (" + UserPackage.jumlahCicilanSudahDibayar + "/" + UserPackage.jumlahCicilan + ")"}</span>
                                            </p>
                                            <img src={camp} alt="/" className='ml-4 mt-4 mb-2 rounded-full w-[50px] h-[50px] md:w-[100px] md:h-[100px]' />
                                        </div>
                                        <div>
                                            <p className='md:text-lg text-xs text-gray-500 font-semibold mr-1'>Next Due Date : </p>
                                            <p className='md:text-base text-xs '>{UserPackage.tanggalJatuhTempo}</p>
                                        </div>
                                    </div>
                                    {UserPackage.tanggalJatuhTempo < today && (
                                        <div className='absolute top-2 right-2 bg-red-500 rounded text-white py-1 px-4'>
                                            <p>Expired</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserPackage