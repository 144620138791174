import React, { useEffect } from 'react'
import { CheckIcon } from '@heroicons/react/solid';
import needHelp from '../assets/question.svg'
import { FaWhatsapp } from 'react-icons/fa'
import { Link } from 'react-router-dom';

const Services = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const dataPrograms = [
        {
            "idProgram": "program001",
            "namaProgram": "Program UTBK",
            "keteranganProgram": "Program Persiapan Ujian Masuk Universitas",
            "imageProgram": "https://cdn.pixabay.com/photo/2013/04/19/01/15/university-105709_960_720.jpg",
            "detailProgram": "Paket Belajar Lengkap + Dibimbing Guru Berpengalaman",
            "hargaAwalProgram": "10.000",
            "linkProgram": "/ProgramUTBK",
            "benefitProgram": [
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur facere quisquam deleniti.",
                "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Numquam excepturi dignissimos natus enim a veniam.",
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique?",
                "Lorem ipsum dolor sit amet.",
                "Lorem, ipsum dolor sit amet consectetur adipisicing.",
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil?"
            ]
        },
        {
            "idProgram": "program002",
            "namaProgram": "Program Bimbel",
            "keteranganProgram": "Program Bimbingan Belajar Berstandar Internasional",
            "imageProgram": "https://cdn.pixabay.com/photo/2022/04/03/18/28/webcam-7109621_960_720.png",
            "detailProgram": "Paket Bimbel Termasuk Video Pembelajaran, Konsultasi Pelajaran, Tes Minat dan Bakat",
            "hargaAwalProgram": "20.000",
            "linkProgram": "/ProgramBimbel",
            "benefitProgram": [
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae beatae debitis eos eligendi. Rerum.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                "Lorem ipsum dolor, sit amet consectetur adipisicing.",
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores modi maxime aut.",
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa!"
            ]
        }
    ]
    return (
        <div className='w-full text-white bg-slate-200'>
            <div className='max-w-[1240px] mx-auto p-12'>
                <div className='text-center py-8 text-[#00CBA9] mt-4'>
                    <h2 className='text-xl md:text-3xl uppercase font-bolder'>Program Belajar</h2>
                    <h3 className='text-3xl md:text-5xl font-bold text-[#00CBA9] py-8'>Lorem ipsum dolor sit amet consectetur adipisicing.</h3>
                </div>

                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                    {dataPrograms.map((program) => (
                        <div className='bg-white text-slate-900 p-10 rounded-xl shadow-2xl relative' key={program.idProgram}>
                            <span className='uppercase px-3 py-1 bg-main-color text-white rounded-2xl text-xs md:text-sm'>{program.namaProgram}</span>
                            <div>
                                <p className='text-xl md:text-3xl text-center font-bold text-second-color py-4'>{program.keteranganProgram}</p>
                            </div>
                            <div className='mt-5'>
                                <img src={program.imageProgram} className="mx-auto h-[250px] w-[500px] rounded-lg" alt="/" />
                            </div>
                            <p className='text-base md:text-xl pt-8 pb-2 text-slate-500'>{program.detailProgram}</p>
                            <p className='text-base md:text-xl pb-2 text-second-color font-bold'>Harga mulai dari: Rp. {program.hargaAwalProgram} / Hari</p>
                            <div className='mx-auto'>
                                <Link to={program.linkProgram}>
                                    <button className='py-2 px-4 md:py-4 md:px-8 my-4'>Lihat Pilihan Paket</button>
                                </Link>
                            </div>
                            <p className='text-base md:text-xl pt-4 pb-2 text-slate-700 font-semibold'>Paket sudah termasuk:</p>
                            <div>
                                {program.benefitProgram.map((benefit, index) => (
                                    <div className='flex text-xs mb-2 md:text-base' key={program.idProgram + index}>
                                        <span><CheckIcon className='w-7 mr-4 text-second-color' /> </span>
                                        <p>{benefit}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <div className='py-12 bg-[#00CBA9]'>
                <div className='grid md:grid-cols-2 items-center'>
                    <div className='mx-auto'>
                        <div className='py-8 text-white mt-4 text-center'>
                            <h2 className='text-5xl font-bold my-2'>Punya Pertanyaan? Hubungi Kami</h2>
                            <h3 className='text-3xl font-bold my-2'>Kami siap membantu anda melalui aplikasi berikut.</h3>
                            <div className='mx-auto'>
                                <div className='items-center mx-auto mt-5 text-xl'>
                                    <Link to="/goToWhatsapp" className='mx-auto cursor-pointer'>
                                        <span><FaWhatsapp className='w-[50px] h-[50px] mx-auto mt-4' /> Whatsapp </span>
                                    </Link>
                                    {/* <span className='mx-auto ml-5 cursor-pointer'><FaEnvelope className='w-[50px] h-[50px] mx-auto mt-4' /> Email </span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mx-auto'>
                        <img src={needHelp} alt="/" className='w-[350px] h-[350px]' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services