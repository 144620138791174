import { createSlice } from '@reduxjs/toolkit'
import { format } from 'date-fns'

const initMember = {
  id: null,
  nama: null,
  email: null,
  hp: null,
  provinsi: null,
  kabupaten: null,
  kecamatan: null,
  fullAddress: null
}

const initContactProfile = {
  whatsapp: '',
  phone: '',
  email: ''
}

const initSocialMediaProfile = {
  facebook: '',
  twitter: '',
  instagram: ''
}

const initSystemProfile = {
  contact: initContactProfile,
  socialMedia: initSocialMediaProfile
}

const initialState = {
  programs: [],
  member: initMember,
  systemProfile: initSystemProfile,
  screenSize: {
    width: 0,
    height: 0
  },
  screenLoad: false,
  changePasswordDialog: {
    isOpen: false
  }
}

export const GlobalSlice = createSlice({
  name: 'Global',
  initialState,
  reducers: {
    setPrograms: (state, { payload }) => {
      state.programs = payload
    },
    resetMember: state => {
      state.member.id = null
      state.member.nama = null
      state.member.email = null
      state.member.hp = null
      state.member.provinsi = null
      state.member.kabupaten = null
      state.member.kecamatan = null
      state.member.fullAddress = null
    },
    setMember: (state, { payload }) => {
      state.member.id = payload.id
      state.member.nama = payload.nama
      state.member.email = payload.email
      state.member.hp = payload.hp
      state.member.provinsi = payload.provinsi
      state.member.kabupaten = payload.kabupaten
      state.member.kecamatan = payload.kecamatan
      state.member.fullAddress = payload.fullAddress
    },
    setSystemProfile: (state, { payload }) => {
      state.systemProfile = payload
    },
    setScreenSize: (state, { payload }) => {
      state.screenSize = payload
    },
    setScreenLoad: (state, { payload }) => {
      state.screenLoad = payload
    },
    openChangePasswordDialog: (state) => {
      state.changePasswordDialog.isOpen = true
    },
    closeChangePasswordDialog: (state) => {
      state.changePasswordDialog.isOpen = false
    }
  }
})

export const isLoggedIn = (member) => member.id !== null

export const {
  setPrograms,
  resetMember,
  setMember,
  setSystemProfile,
  setScreenSize,
  setScreenLoad,
  openChangePasswordDialog,
  closeChangePasswordDialog
} = GlobalSlice.actions

export default GlobalSlice.reducer