import React, { useEffect } from 'react'

const Whatsapp = () => {

    useEffect(() => {
        window.location.href = "https://api.whatsapp.com/send/?phone=6289677432367&text=Halo%21+Saya+mau+menanyakan+layanan+Kunci+Belajar.+Saya+lihat+dari+Website&app_absent=0"
    }, [])

    return (
        <div>Whatsapp</div>
    )
}

export default Whatsapp