import { useEffect } from "react"
import { useSelector } from "react-redux"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import { isDesktop, isPhone } from "../utils/PlatformHelper"

const Contact = () => {
    const { screenSize } = useSelector(state => state.global)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <div
            className={
                'w-full bg-second-color ' +
                (isDesktop(screenSize.width) ? " flex justify-center pt-[50px] h-screen " : "") +
                (isPhone(screenSize.width) ? " pt-[30px] pb-[80px] " : "")
            }
        >
            <Navbar />
            <div
                className={
                    'space-y-10 ' +
                    (isPhone(screenSize.width) ? "px-5" : "")
                }
                style={{
                    width: isPhone(screenSize.width) ? "100%" : "1200px"
                }}
            >

                <div className="space-y-2 mt-[100px]">
                    <div
                        className="font-bold text-white"
                        style={{
                            fontFamily: 'Nunito',
                            fontSize: '24px'
                        }}
                    >
                       <h1>Kontak Kami</h1>
                    </div>
                    <div className="rounded-lg">
                        <iframe
                            src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1666.2012190481553!2d107.71935236090252!3d-6.657247435365722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x49be53415e299e26!2sAs%20Syifa%20Boarding%20School%20Subang!5e0!3m2!1sid!2sid!4v1609961480122!5m2!1sid!2sid"}
                            width="100%"
                            height="450"
                            style={{ border: "10px" }}

                        />
                    </div>
                </div>
                <div className="space-y-2 mt-[100px]">
                    <div
                        className="font-bold text-white"
                        style={{
                            fontFamily: 'Nunito',
                            fontSize: '24px'
                        }}
                    >
                       <h2>Lokasi dan Detail</h2>
                    </div>
                    <div className="w-full bg-white rounded-lg px-5 py-5">
                        <div
                            className={
                            "text-gray-600"
                            }
                        >
                            <div className="flex space-x-3">
                            <label className="text-left inline-block w-16">Alamat</label>
                            <label>:</label>
                            <label className="text-justify text-gray-600 flex-1">{'Jl. Subang-Bandung KM. 12, Tambakan, Kec. Jalancagak, Subang, Jawa Barat 41281'}</label>
                            </div>
                            <div className="flex space-x-3">
                            <label className="text-left inline-block w-16">Email</label>
                            <label>:</label>
                            <label className="text-justify text-gray-600">{'bimbelalc.subang@gmail.com'}</label>
                            </div>
                            <div className="flex space-x-3">
                            <label className="text-left inline-block w-16">Telp.</label>
                            <label>:</label>
                            <label className="text-justify text-gray-600">{'6281339094092'}</label>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <Footer />
        </>
    )
}

export default Contact