import { useEffect, useState } from "react"
import UserAnak from "../../components/User/UserAnak"
import UserPaketBimbel from "../../components/User/UserPaketBimbel"
import UserProfile from "../../components/User/UserProfile"
import UserTransaction from "../../components/User/UserTransaction"
import UserUjian from "../../components/User/UserUjian"

import { 
    FaKey,
    FaUserCog,
    FaUsers,
    FaUsersCog
} from "react-icons/fa"
import { GiWhiteBook } from "react-icons/gi"
import { BsFillCreditCard2FrontFill } from "react-icons/bs"
import { Route, Routes, useNavigate } from "react-router"
import { format } from "date-fns"
import { useDispatch, useSelector } from "react-redux"
import { getLatestTransaction, logOut } from "../../repository/User"
import { handleResponse200 } from "../../utils/HttpUtils"
import { notifyError } from "../../utils/Notification"
import { RiLoader5Fill } from "react-icons/ri"
import { resetMember } from "../../app/GlobalSlice"
import { Link } from "react-router-dom"

const TransactionBox = ({
    namaPaket,
    dateObj
}) => {

    return (
        <div className="bg-blue-100 p-2 px-3 rounded-md flex items-center space-x-3">
            <div>
                <BsFillCreditCard2FrontFill className="h-8 w-8 text-blue-900"/>
            </div>
            <div className="">
                <div
                    className="font-bold"
                    style={{
                        fontFamily: 'Nunito',
                        fontSize: '16px'
                    }}
                >
                    {namaPaket}
                </div>
                <div
                    className="font-bold text-blue-900"
                    style={{
                        fontSize: '14px'
                    }}
                >
                    {format(dateObj, "dd/MM/yyyy HH:mm")}
                </div>
            </div>
        </div>
    )
}

const LatestTransactionBox = () => {
    const navigate = useNavigate()

    const [isLoad, setIsLoad] = useState(false)

    const [latestTransactions, setLatestTransactions] = useState([])

    useEffect(() => {
        setIsLoad(true)

        getLatestTransaction()
            .then(res => {
                handleResponse200({
                    httpResponse: res,
                    onSuccess: payload => {
                        setLatestTransactions(payload)
                    },
                    onRecovFailure: errors => errors.forEach(err => {
                        notifyError(err)
                    }),
                    onUnAuth: error => {
                        navigate('/login')
                    },
                    onTechnicalError: errors => errors.forEach(err => {
                        notifyError(err)
                    })
                })
            })
            .catch(error => {})
            .finally(() => { setIsLoad(false) })
    }, [])

    function showLatestTransaction(transactions) {
        if (transactions.length < 1) {
            return (
                <div 
                    className="bg-blue-100 p-2 px-3 rounded-md flex items-center justify-center font-bold"
                    style={{
                        fontFamily: 'Nunito'
                    }}
                >
                    Tidak ada data
                </div>
            )
        }

        return (
            <div className="space-y-3">
                <div className="space-y-3">
                    {transactions.map(t => (
                        <TransactionBox 
                            namaPaket={t.namaPaketBimbel}
                            dateObj={new Date()}
                        />
                    ))}
                </div>
                <div 
                    className="text-center font-bold text-blue-700 underline"
                    style={{
                        fontFamily: 'Nunito'
                    }}
                >
                    <Link to={"/member/transaksi"}>
                        Lihat Semua
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <div
            className="px-6 pt-6 space-y-3"
        >
            <div
                className="font-bold"
                style={{
                    fontFamily: 'Nunito'
                }}
            >
                Transaksi terakhir
            </div>
            { 
                isLoad ?
                    (<div className="flex justify-center">
                        <RiLoader5Fill className={"h-10 w-10 text-blue-500 animate-spin"} />
                    </div>)
                    :
                    (
                    <div
                        className=""
                    >
                        { showLatestTransaction(latestTransactions) }   
                    </div>
                    )
            }
        </div>
    )
}

function delay(t, v) {
    return new Promise(function(resolve) { 
        setTimeout(resolve.bind(null, v), t)
    });
 }

const UserPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { member } = useSelector(state => state.global)

    const [mainMenu, setMainMenu] = useState({
        activeMenu: 'profil',
        menus: [ 
            {
                id: 'profil',
                label: 'Profil Orangtua',
                icon: <FaUserCog className="h-8 w-8 text-blue-900"/>,
                onClick: () => navigate('/member/profil')
            },
            {
                id: 'anak',
                label: 'Profil Anak',
                icon: <FaUsersCog className="h-8 w-8 text-blue-900"/>,
                onClick: () => navigate('/member/anak')
            },
            {
                id: 'cicilan',
                label: 'Cicilan',
                icon: <BsFillCreditCard2FrontFill className="h-8 w-8 text-blue-900"/>,
                onClick: () => navigate('/member/cicilan')
            },
            {
                id: 'transaksi',
                label: 'Transaksi',
                icon: <BsFillCreditCard2FrontFill className="h-8 w-8 text-blue-900"/>,
                onClick: () => navigate('/member/transaksi')
            },
            {
                id: 'password',
                label: 'Password',
                icon: <FaKey className="h-8 w-8 text-blue-900"/>,
                onClick: () => navigate('/member/password')
            },
            {
                id: 'presensi',
                label: 'Presensi',
                icon: <FaUsers className="h-8 w-8 text-blue-900"/>,
                onClick: () => navigate('/member/anak-presensi')
            }
        ]
    })

    const [latestTransaction, setLatestTransaction] = useState([])

    const [isLoggingOut, setIsLoggingOut] = useState(false)
    const [isGettingTransaction, setIsGettingTransaction] = useState(false)

    useEffect(() => {
        if (!member) return;

        onGettingLatestTransaction()
    }, [member])

    function onLogout() {
        setIsLoggingOut(true)

        delay(3000)
            .then(() => {
                logOut()
                    .then(res => {
                        handleResponse200({
                            httpResponse: res,
                            onSuccess: payload => {
                                dispatch(resetMember())
                                navigate('/login')
                            },
                            onRecovFailure: errors => errors.forEach(err => {
                                notifyError(err)
                            }),
                            onUnAuth: error => {
                                navigate('/login')
                            },
                            onTechnicalError: errors => errors.forEach(err => {
                                notifyError(err)
                            })
                        })
                    })
                    .catch(() => {})
                    .finally(() => {
                        setIsLoggingOut(false)
                    })
            })
    }

    function onGettingLatestTransaction() {
        setIsGettingTransaction(true)

        delay(3000)
            .then(() => {
                setLatestTransaction([
                    {
                        idTransaction: 'TRX0001',
                        transactionDate: new Date().toISOString(),
                        namaPaketBimbel: 'Paket Bima 1',
                    }
                ])
                
                setIsGettingTransaction(false)
            })
    }

    return (
        <div
            className='w-full h-screen bg-second-color flex justify-center'
        >
            <div
                className='w-[450px] h-full'
            >
                <div
                    className='w-full h-full bg-white rounded-lg relative'
                >
                    { isLoggingOut && (
                        <>
                        <div className="absolute w-full h-full z-10 bg-black left-0 opacity-40">
                        </div>
                        <div className="absolute z-20 top-1/2 left-0 w-full">
                            <div className="w-full flex justify-center">
                                <RiLoader5Fill className={"h-16 w-16 text-blue-500 animate-spin"} />
                            </div>
                        </div>
                        </>
                    )}
                    <div 
                        className="px-6 font-bold bg-blue-800 text-white rounded-tl-lg rounded-tr-lg rounded-bl-2xl rounded-br-2xl pt-5 pb-10"
                        style={{  
                            fontFamily: 'Nunito',
                            height: "200px"
                        }}
                    >
                        <div className="flex justify-end">
                            <button 
                                className="px-08 py-1 px-4 rounded-full bg-red-500 border-2 border-white font-bold hover:bg-white hover:text-blue-500"
                                onClick={onLogout}
                            >
                                Logout
                            </button>
                        </div>
                        <div>
                            <div
                                style={{
                                    fontSize: '16px',
                                }}
                            >
                                Selamat datang,
                            </div>
                            <div
                                style={{
                                    fontSize: '20px',
                                }}
                            >
                                {member.nama}
                            </div>
                        </div>
                        <div
                            className="py-3"
                        >
                            <Link to={'/paket-bimbel'}>
                                <button
                                    className="w-full py-1 border-2 border-white rounded-full bg-green-600 font-bold hover:bg-white hover:text-blue-900"
                                    style={{
                                        fontFamily: 'Nunito'
                                    }}
                                >
                                    Lihat Paket Bimbel
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div 
                        className="px-6 "
                    >
                        <div
                            className="p-5 bg-white rounded-lg shadow-lg"
                            style={{
                                marginTop: '-30px'
                            }}
                        >
                            <div className="p-3 rounded-lg bg-blue-500 text-white space-y-3">
                                <div className="space-y-1">
                                    <p className="font-bold">Informasi</p>
                                    <p>Untuk pengerjaan tryout BIMA dapat diakses melalui:</p>
                                    <p>
                                        <a 
                                            href="http://cbt.bimbelalc.id"
                                            className="font-bold underline"
                                            target={"_blank"}
                                        >
                                        http://cbt.bimbelalc.id
                                        </a>
                                    </p>
                                    <p className="space-x-2">
                                        <label>ID</label>
                                        <label>:</label>
                                        <label className="italic">(ID Anak)</label>
                                    </p>
                                    <p className="space-x-2">
                                        <label>Password</label>
                                        <label>:</label>
                                        <label className="italic">ALC2023</label>
                                    </p>
                                </div>
                                <div>
                                    <a href={"http://cbt.bimbelalc.id"} target="_blank">
                                        <button
                                            className="py-1 w-full rounded-full bg-green-600 hover:bg-green-700 text-white"
                                        >
                                            Try Out
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div
                                className="p-5 bg-white rounded-lg shadow-lg grid grid-cols-3 gap-y-4 gap-x-8"
                                // style={{
                                //     marginTop: '-30px'
                                // }}
                            >
                                { mainMenu.menus.map(m => (
                                    <div
                                        className="space-y-2"
                                        key={m.id}
                                    >
                                        <div className="flex justify-center">
                                            <div 
                                                className="flex items-center justify-center py-3 bg-blue-100 shadow-lg rounded-md p-1 hover:cursor-pointer"
                                                style={{
                                                    height: '70px',
                                                    width: '70px'
                                                }}
                                                onClick={m.onClick}
                                            >
                                                {m.icon}
                                            </div>
                                        </div>
                                        <div 
                                            className="flex justify-center font-bold text-gray-600 text-center"
                                            style={{
                                                fontFamily: 'Nunito'
                                            }}
                                        >
                                            {m.label}
                                        </div>
                                    </div>
                                )) }
                            </div>

                        </div>
                    </div>
                    <LatestTransactionBox />
                    <div className="py-10">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPage