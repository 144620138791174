import { BsChevronLeft } from "react-icons/bs"
import { Link } from "react-router-dom"

const UserPaketBimbelSimplified = ({
    idTransaksi,
    trxCreatedAt,
    idPaketBimbel,
    namaPaketBimbel,
    namaAnak
}) => {
    return (
        <div
            className="rounded-md border border-gray-300 relative divide-y divide-gray-300"
        >
            <div
                className="p-2 space-x-3"
                style={{ fontSize: '14px' }}
            >
                <label>{trxCreatedAt}</label>
                <labe>trx id : {idTransaksi}</labe>
            </div>
            <div className="p-2 space-y-1">
                <div>
                    <label
                        className="font-bold"
                        style={{ fontFamily: 'Nunito', fontSize: '15px' }}
                    >
                        {namaPaketBimbel}
                    </label>
                </div>
                <div
                    className=""
                    style={{ fontSize: '14px' }}
                >
                    {namaAnak}
                </div>
            </div>
            <div
                className="p-2"
            >

            </div>
        </div>
    )
}

const UserPaketBimbel = () => {

    return (
        <div
            className='w-full h-screen bg-second-color flex justify-center'
        >
            <div
                className='w-[450px] h-full'
            >
                <div
                    className='w-full h-full bg-white rounded-lg'
                >
                    <div 
                        className="flex justify-between px-6 font-bold bg-blue-800 text-white rounded-tl-lg rounded-tr-lg rounded-bl-2xl rounded-br-2xl pt-12 pb-10"
                        style={{  
                            fontFamily: 'Nunito',
                            height: "200px"
                        }}
                    >
                        <div className="">
                            <Link to="/member">
                                <BsChevronLeft className="h-6 w-6"/>
                            </Link>
                        </div>
                        <div
                            style={{
                                fontSize: '20px',
                            }}
                        >
                            Paket Bimbel
                        </div>
                        <div/>
                    </div>
                    <div 
                        className="px-6 "
                    >
                        <div
                            className="p-5 bg-white rounded-lg shadow-lg z-10"
                            style={{
                                marginTop: '-50px'
                            }}
                        >

                            <div
                                className="space-y-5"
                            >
                                {dummies.map(d => (
                                    <UserPaketBimbelSimplified 
                                        idTransaksi={d.idTransaksi}
                                        trxCreatedAt={d.trxCreatedAt}
                                        idPaketBimbel={d.idPaketBimbel}
                                        namaPaketBimbel={d.namaPaketBimbel}
                                        namaAnak={d.anak.nama}
                                    />
                                ))}
                            </div>

                        </div>
                    </div>
                    <div className="py-10">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPaketBimbel

const dummies = [
    {
        idTransaksi: 'TRX000001',
        trxCreatedAt: '29 Maret 2022, 18:17:03',
        idPaketBimbel: 'PKB02220120001',
        namaPaketBimbel: 'Paket BIMA',
        hargaPaketBimbel: '27000',
        status: 'paid',
        anak: {
            id: 'ANK0001',
            nama: 'Bagus Jatikusuma'
        }
    },
    {
        idTransaksi: 'TRX000002',
        trxCreatedAt: '29 Maret 2022, 18:27:03',
        idPaketBimbel: 'PKB02220120002',
        namaPaketBimbel: 'Paket BIMA 2',
        hargaPaketBimbel: '27000',
        status: 'paid',
        anak: {
            id: 'ANK0002',
            nama: 'Bagus Jatikusuma2'
        }
    },
]