import React from 'react'

// Import Swiper React Components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination } from "swiper";

const PromotionSlide = () => {

    const promotionSlides = [
        {
            id: 'A',
            image: "https://cdn.pixabay.com/photo/2016/09/03/09/18/girl-1641215_960_720.jpg"
        },
        {
            id: 'B',
            image: "https://cdn.pixabay.com/photo/2014/03/12/18/45/boys-286245_960_720.jpg"
        },
        {
            id: 'C',
            image: "https://cdn.pixabay.com/photo/2016/11/14/04/06/grandmother-1822560_960_720.jpg"
        }
    ]

    return (
        <div className='w-full'>
            <div className='max-w-[1240px] mx-auto pt-12'>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="p-6"
                >
                    {promotionSlides.map((slide, index) => {
                        return (
                            <SwiperSlide key={Math.random()}>
                                <div className='mt-16 p-4 rounded-lg h-[250px] lg:h-[400px] bg-[#00CBA9] mx-4'>
                                    <img src={slide.image} alt="/" className='w-full pb-4 lg:pb-16 h-[215px] lg:h-[400px]' />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    )
}

export default PromotionSlide