import React, { useEffect } from 'react'
// import Article from '../components/Article'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import PromotionSlide from '../components/Slides/PromotionSlide'

const Post = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <Navbar />
            <div className='w-full pt-32 p-10 '>
                <div className='max-w-[1240px] m-auto'>
                    <div>
                        <h1 className='text-5xl text-center font-bold text-main-color m-4'>Lorem, ipsum dolor.</h1>
                        <h3 className='text-3xl text-center font-bold text-gray-500'>Lorem, ipsum dolor.</h3>
                    </div>
                    <div className='m-5 p-2'>

                        <img src="https://cdn.pixabay.com/photo/2014/03/12/18/45/boys-286245_960_720.jpg" alt="/" className='border-8 rounded border-main-color w-[500px] mx-auto mb-5' />

                        <p className='mb-5 indent-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus quia ut laborum praesentium sunt tempora voluptatibus consectetur unde maiores eum tenetur, mollitia distinctio, repellat, inventore alias odio. Necessitatibus enim nam quis aperiam. Inventore totam, omnis quis quidem recusandae nisi, hic cum magnam sequi dolore provident perspiciatis. Tenetur harum nostrum, deserunt ducimus commodi non, laboriosam aliquid iusto tempora voluptates possimus unde enim temporibus! Magni odit, cum vero mollitia corrupti ea et voluptatibus atque nihil quas unde.</p>

                        <p className='mb-5 indent-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus quia ut laborum praesentium sunt tempora voluptatibus consectetur unde maiores eum tenetur, mollitia distinctio, repellat, inventore alias odio. Necessitatibus enim nam quis aperiam. Inventore totam, omnis quis quidem recusandae nisi, hic cum magnam sequi dolore provident perspiciatis. Tenetur harum nostrum, deserunt ducimus commodi non, laboriosam aliquid iusto tempora voluptates possimus unde enim temporibus! Magni odit, cum vero mollitia corrupti ea et voluptatibus atque nihil quas unde.</p>

                        <p className='mb-5 indent-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus quia ut laborum praesentium sunt tempora voluptatibus consectetur unde maiores eum tenetur, mollitia distinctio, repellat, inventore alias odio. Necessitatibus enim nam quis aperiam. Inventore totam, omnis quis quidem recusandae nisi, hic cum magnam sequi dolore provident perspiciatis. Tenetur harum nostrum, deserunt ducimus commodi non, laboriosam aliquid iusto tempora voluptates possimus unde enim temporibus! Magni odit, cum vero mollitia corrupti ea et voluptatibus atque nihil quas unde.</p>

                        <p className='mb-5 indent-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus quia ut laborum praesentium sunt tempora voluptatibus consectetur unde maiores eum tenetur, mollitia distinctio, repellat, inventore alias odio. Necessitatibus enim nam quis aperiam. Inventore totam, omnis quis quidem recusandae nisi, hic cum magnam sequi dolore provident perspiciatis. Tenetur harum nostrum, deserunt ducimus commodi non, laboriosam aliquid iusto tempora voluptates possimus unde enim temporibus! Magni odit, cum vero mollitia corrupti ea et voluptatibus atque nihil quas unde.</p>
                    </div>
                </div>

            </div>
            <Footer />

        </>
    )
}

export default Post