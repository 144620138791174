import { useEffect } from "react"
import { useSelector } from "react-redux"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import { isDesktop, isPhone } from "../utils/PlatformHelper"

const UmRegistration = () => {
    const { screenSize } = useSelector(state => state.global)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <div
        className={
            'w-full bg-second-color ' +
            (isDesktop(screenSize.width) ? " flex justify-center pt-[50px] " : "") +
            (isPhone(screenSize.width) ? " pt-[30px] pb-[80px] " : "")
        }
        >
            <Navbar />
            <div className="my-20">
                <img src="/images/tutor_um_reg.png"/>
            </div>

        </div>
        <Footer />
        </>
    )
}

export default UmRegistration