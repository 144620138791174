import React, { useState } from 'react'
import { XIcon } from '@heroicons/react/outline';

const AdsBanner = () => {
    const [flag, setFlag] = useState(true);
    return (
        <div 
            className="w-full mt-[75px] z-10 bg-blue-700 fixed drop-shadow-lg hidden md:flex"
            style={{ width: "1260px" }}
        >
            <div className={`text-white py-2 w-full ${flag ? "flex" : "hidden"}`}>
                <h3 className='flex-2 p-2 cursor-pointer text-medium underline mx-auto justify-right'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde, eos magnam. Ex sequi veniam quisquam voluptatum mollitia possimus ullam? Quos.</h3>
                <XIcon className='w-15 h-15 md:w-7 md:h-7 md:mr-4 md:mt-1 cursor-pointer mx-auto' onClick={() => setFlag(!flag)} />
            </div>
        </div>
    )
}

export default AdsBanner