import React from 'react'
import PaketProgramTab from '../../components/Tabs/PaketProgramTab';

const ProgramBimbel = () => {
    const dataPaket = [
        {
            idPaketCategory: "idprocat1",
            paketCategoryName: "SMP",
            paketProgramList: [
                {
                    idPaketProgram: "idPaketProgram1",
                    namaPaketProgram: "Gold",
                    hargaAwalPaketProgram: "10.000.000",
                    hargaFinalPaketProgram: "1.999.999",
                    keteranganPaketProgram: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet autem exercitationem libero?",
                    benefitPaketProgram: [
                        "Lorem ipsum dolor sit.",
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
                    ],
                    isFavourite: false,
                },
                {
                    idPaketProgram: "idPaketProgram2",
                    namaPaketProgram: "Silver",
                    hargaAwalPaketProgram: "5.000.000",
                    hargaFinalPaketProgram: "499.999",
                    keteranganPaketProgram: "sit amet consectetur adipisicing elit. Amet autem exercitationem libero?",
                    benefitPaketProgram: [
                        "Lorem ipsum dolor sit.",
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
                    ],
                    isFavourite: true
                }
            ]
        },
        {
            idPaketCategory: "idprocat2",
            paketCategoryName: "SMA",
            paketProgramList: [
                {
                    idPaketProgram: "idPaketProgram3",
                    namaPaketProgram: "Silver",
                    hargaAwalPaketProgram: "5.000.000",
                    hargaFinalPaketProgram: "499.999",
                    keteranganPaketProgram: "sit amet consectetur adipisicing elit. Amet autem exercitationem libero?",
                    benefitPaketProgram: [
                        "Lorem ipsum dolor sit.",
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae, soluta?",
                    ],
                    isFavourite: false
                }
            ]
        }
    ];

    return (
        <>
            <PaketProgramTab dataPaket={dataPaket} />
        </>
    )
}

export default ProgramBimbel