import React from 'react'
import PaketProgramTab from '../../components/Tabs/PaketProgramTab'

const ProgramUTBK = () => {

    const dataPaket = [
        {
            idPaketCategory: "idprocat3",
            paketCategoryName: "UTBK",
            paketProgramList: [
                {
                    idPaketProgram: "idPaketProgram3",
                    namaPaketProgram: "Gold",
                    hargaAwalPaketProgram: "20.000.000",
                    hargaFinalPaketProgram: "299.999",
                    keteranganPaketProgram: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Omnis officia quae laudantium?",
                    benefitPaketProgram: [
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam, fugiat. Asperiores a velit quasi.",
                        "Lorem, ipsum.",
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae impedit corrupti doloribus?",
                        "Lorem ipsum dolor sit."
                    ],
                    isFavourite: false
                },
                {
                    idPaketProgram: "idPaketProgram4",
                    namaPaketProgram: "Silver",
                    hargaAwalPaketProgram: "15.000.000",
                    hargaFinalPaketProgram: "499.999",
                    keteranganPaketProgram: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore saepe officiis nobis qui.",
                    benefitPaketProgram: [
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                        "Lorem ipsum dolor sit amet consectetur.",
                        "Lorem ipsum dolor sit.",
                        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates?"
                    ],
                    isFavourite: true
                }
            ]
        },

    ]

    return (
        <>
            <PaketProgramTab dataPaket={dataPaket} />
        </>
    )
}

export default ProgramUTBK