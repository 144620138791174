import axios from "axios"

export const getPublicProgram = async () => {
    const finalUrl =
        window._HOST + "public/program"

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const getPublicPaketBimbel = async (program) => {
    const finalUrl =
        window._HOST + "public/paket-bimbel" + (program ? "?program=" + program : "")

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const getProgramDetail = async (idProgram) => {
    const finalUrl =
        window._HOST + "public/program-detail/"+idProgram

    const response =
        await axios.get(finalUrl)

    return response.data
}

export const getDetailPublicPaketBimbel = async (idPaketBimbel) => {
    const finalUrl =
        window._HOST + "public/paket-bimbel-detail/" + idPaketBimbel

    const response =
        await axios.get(finalUrl)

    return response.data
}

